import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { withTheme } from '@material-ui/core/styles'
import { Container, DatePickerContainer, DateContainerNative, NativeInput } from './styles'
import * as palette from '../../styles/palette'

const DatePicker = ({
  notSure,
  onHandleNativeFromDateInputChange,
  onHandleNativeFromDateInputBlur,
  theme,
  dateInputFromValue,
  errorText,
  hasActiveValidation,
}) => {
  return (
    <Container className={''}>
      {
        <DatePickerContainer
          lightColor={theme.palette.primary.light}
          primaryColor={theme.palette.primary.main}
          style={{ display: notSure ? 'none' : 'block' }}
        >
          <DateContainerNative>
            <NativeInput
              id='start-date'
              name='start-date'
              placeholder='dd.mm.yyyy'
              onChange={onHandleNativeFromDateInputChange}
              onBlur={onHandleNativeFromDateInputBlur}
              type='date'
              value={dateInputFromValue}
              className={hasActiveValidation && !dateInputFromValue ? 'error' : null}
              lightColor={theme.palette.primary.light}
              mainColor={theme.palette.text.primary}
              inactiveColor={theme.palette.text.secondary}
              borderColor={palette.primaryInputBorder}
              focusColor={theme.palette.primary.main}
              errorColor={theme.palette.error.main}
            />
          </DateContainerNative>
        </DatePickerContainer>
      }
    </Container>
  )
}

DatePicker.propTypes = {
  dateInputFromValue: PropTypes.any,
  notSure: PropTypes.any,
  errorText: PropTypes.string.isRequired
}

export default withTheme(translate('translations')(DatePicker))
