import React, { Component } from 'react'
import { withTheme } from '@material-ui/core'
import { Icon } from '@iconify/react'
import barcodeIcon from '@iconify/icons-mdi/barcode'
import {
  StyledFavoriteBorder,
  StyledAccessibility,
  StyledChat,
  StyledHowToReg,
  StyledPhone,
  StyledInvertColors,
  Title,
  OverviewPosition,
  StyledSpan,
  StyledActionSpan,
  ChargennummerSpan,
  StyledOverview,
  LinkContainer,
  PrivacySection,
  Impressum,
  ClickableDiv
} from './styles'
import { StyledTitle, StyledHeader } from '../styles'
import {
  ButtonItemNext,
  StepWrapper,
  MainContainer,
  FlexButtons,
  ProgressContainer
} from '../../MainPage/styles'
import Container from 'lib/elements/Container'
import PropTypes from 'prop-types'
import { StyledCheckCircle } from '../success'

const hrStyle = {
  border: '0.6px #d2cdcd solid'
}

const svgStyle = {
  verticalAlign: 'middle',
  marginLeft: '-3px'
}

const textStyle = {
  fontFamily: 'Nunito, sans-serif',
  fontSize: '1.6rem',
  lineHeight: '1.8rem',
  boxSizing: 'border-box',
  textAlign: 'center'
}

const firstTitleStyle = {
  textAlign: 'center',
  fontSize: '1.9rem',
  fontWeight: 'bold',
  lineHeight: '22px'
}

const ButtonContainerStyle = {
  paddingTop: '0.5rem'
}

const secondTitleStyle = {
  textAlign: 'center',
  fontWeight: 'bold'
}

const StyledLink = {
  color: 'rgba(0,0,0,0.65)'
}

export class Overview extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }

  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  };

  render () {
    const { dosageQuestion, indicationQuestion, batchNumberQuestion, effectQuestion, customQuestions, drugActionQuestion, contactDetailsQuestion, firstQuestion } = this.props
    return (
      <Container>
        <MainContainer>
          <StyledHeader>
            <StyledTitle>Der Arzneimittelhersteller hat folgende wichtige Rückfragen zu Ihrer Meldung:</StyledTitle>
          </StyledHeader>
          <ProgressContainer>
            <StepWrapper>
              <OverviewPosition>
                { dosageQuestion &&
                  <React.Fragment>
                    { (firstQuestion === 2) && <ClickableDiv onClick={this.continue}>
                      <StyledInvertColors />
                      <StyledSpan>Dosierung</StyledSpan>
                    </ClickableDiv>}
                    { !(firstQuestion === 2) && <Title>
                      <StyledInvertColors />
                      <StyledSpan>Dosierung</StyledSpan>
                    </Title>}
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                { indicationQuestion &&
                  <React.Fragment>
                    { (firstQuestion === 3) && <ClickableDiv onClick={this.continue}>
                      <StyledFavoriteBorder />
                      <StyledSpan>Grund der Einnahme</StyledSpan>
                    </ClickableDiv>}
                    { !(firstQuestion === 3) && <Title>
                      <StyledFavoriteBorder />
                      <StyledSpan>Grund der Einnahme</StyledSpan>
                    </Title>}
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                { batchNumberQuestion &&
                  <React.Fragment>
                    { (firstQuestion === 4) && <ClickableDiv onClick={this.continue}>
                      <Icon style={svgStyle} icon={barcodeIcon} width='2em' height='2em' />
                      <ChargennummerSpan>Chargennummer</ChargennummerSpan>
                    </ClickableDiv>}
                    { !(firstQuestion === 4) && <Title>
                      <Icon style={svgStyle} icon={barcodeIcon} width='2em' height='2em' />
                      <ChargennummerSpan>Chargennummer</ChargennummerSpan>
                    </Title>}
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                { effectQuestion &&
                  <React.Fragment>
                    { (firstQuestion === 5) && <ClickableDiv onClick={this.continue}>
                      <StyledAccessibility />
                      <StyledSpan>Ausgang der Nebenwirkung</StyledSpan>
                    </ClickableDiv>}
                    { !(firstQuestion === 5) && <Title>
                      <StyledAccessibility />
                      <StyledSpan>Ausgang der Nebenwirkung</StyledSpan>
                    </Title>}
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                { customQuestions &&
                  <React.Fragment>
                    { (firstQuestion === 6) && <ClickableDiv onClick={this.continue}>
                      <StyledChat />
                      <StyledSpan>Sonstige Rückfrage(n)</StyledSpan>
                    </ClickableDiv>}
                    { !(firstQuestion === 6) && <Title>
                      <StyledChat />
                      <StyledSpan>Sonstige Rückfrage(n)</StyledSpan>
                    </Title>}
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                { drugActionQuestion &&
                  <React.Fragment>
                    { (firstQuestion === 7) && <ClickableDiv onClick={this.continue}>
                      <StyledHowToReg />
                      <StyledActionSpan>Getroffene Maßnahmen</StyledActionSpan>
                    </ClickableDiv>}
                    { !(firstQuestion === 7) && <Title>
                      <StyledHowToReg />
                      <StyledActionSpan>Getroffene Maßnahmen</StyledActionSpan>
                    </Title>}
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                { contactDetailsQuestion &&
                  <React.Fragment>
                    { (firstQuestion === 8) && <ClickableDiv onClick={this.continue}>
                      <StyledPhone />
                      <StyledActionSpan>Kontaktaufnahme</StyledActionSpan>
                    </ClickableDiv>}
                    { !(firstQuestion === 8) && <Title>
                      <StyledPhone />
                      <StyledActionSpan>Kontaktaufnahme</StyledActionSpan>
                    </Title>}
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                { (dosageQuestion || indicationQuestion || batchNumberQuestion || effectQuestion || customQuestions || drugActionQuestion || contactDetailsQuestion) &&
                  <React.Fragment>
                    <Title color='primary'>
                      Durch Ihre Ergänzungen tragen Sie aktiv dazu bei, die Arzneimittelsicherheit zu verbessern.
                      Die Beantwortung dauert nur wenige Sekunden.
                  </Title>
                  </React.Fragment>
                }
                { (!dosageQuestion && !indicationQuestion && !batchNumberQuestion && !effectQuestion && !customQuestions && !drugActionQuestion && !contactDetailsQuestion) &&
                  <StyledOverview>
                    <StyledOverview style={firstTitleStyle} color='primary'><StyledCheckCircle /></StyledOverview>

                    <StyledOverview style={firstTitleStyle} color='primary'>Vielen Dank für Ihren wertvollen Beitrag! 🤗</StyledOverview>

                    <p style={textStyle}> Sie haben alle Rückfragen erfolgreich beantwortet.
                    Sollte es weitere Rückfragen geben, erhalten Sie diese per E-Mail.
                  </p>

                    <StyledOverview style={secondTitleStyle} color='primary'>Gesunde Grüße,<br /> Ihr Team von nebenwirkungen.de 💝</StyledOverview>
                  </StyledOverview>
                }
              </OverviewPosition>
            </StepWrapper>
            {(dosageQuestion || indicationQuestion || batchNumberQuestion || effectQuestion || customQuestions || drugActionQuestion || contactDetailsQuestion) &&
              <FlexButtons style={ButtonContainerStyle}>
                <ButtonItemNext
                  color='primary'
                  id='submitForm'
                  step={4}
                  themeColor={this.props.theme.palette.primary.main}
                  variant='contained'
                  width='100%'
                  onClick={this.continue}
                >
                  Jetzt schnell beantworten &gt;&gt;
              </ButtonItemNext>
              </FlexButtons>
            }
            <LinkContainer>
              <PrivacySection><a href={'https://www.nebenwirkungen.de/impressum'} target='_blank' style={StyledLink}>Impressum</a></PrivacySection>
              <Impressum><a href={'https://www.nebenwirkungen.de/datenschutz'} target='_blank' style={StyledLink}>Datenschutz</a></Impressum>
            </LinkContainer>
          </ProgressContainer></MainContainer>
      </Container>
    )
  }
}

Overview.propTypes = {
  nextStep: PropTypes.any,
  theme: PropTypes.any,
  dosageQuestion: PropTypes.object,
  indicationQuestion: PropTypes.object,
  batchNumberQuestion: PropTypes.object,
  effectQuestion: PropTypes.object,
  contactDetailsQuestion: PropTypes.object
}

export default withTheme(Overview)
