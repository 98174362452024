import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { translate, Trans } from 'react-i18next'
import i18next from 'i18next'

import SideEffectInput from 'components/SideEffectInput'
import NativePicker from 'components/NativePicker'
import MedButton from 'lib/elements/Button'
import OverviewButton from 'lib/elements/OverviewButton'
import AddMoreButton from 'lib/elements/AddMoreButton'
import { withTheme } from '@material-ui/core/styles'
import { trackGoogleAnalyticsEvent } from 'service/tracker'

import { getStringParamFromURL } from 'lib/functions'

import { isDateInputSupported } from 'lib/functions/index'

import { getSideEffects } from 'service/index'
import { get as gv, debounce } from 'lodash'

import {validationRegex} from '../../../constants'

import {
  BottomBtnGroup,
  ButtonItem,
  FlexButtons,
  BackAnchor,
  ItemsList,
  ButtonSpacer,
  StepWrapper
} from '../styles'

import {
  defaultNonOption
} from 'constants.js'

const timeElNameDefault = defaultNonOption

class SideEffectsStep extends React.Component {
  constructor (props) {
    super(props)

    let defaultSideEffect = ''
    const sideEffectParameter = getStringParamFromURL(window.location.search, 'defaultEffect')
    if (sideEffectParameter) {
      defaultSideEffect = sideEffectParameter
      trackGoogleAnalyticsEvent('SideEffectsStep AutoComplete')
    }

    this.state = {
      isBackToEdit: props.isBackToEdit ? props.isBackToEdit : '',
      sideEffect: defaultSideEffect,
      defaultSideEffect: defaultSideEffect,
      sideEffectLLTCode: '',
      sideEffectPTCode: '',
      timeEl: null,
      timeElName: defaultNonOption,
      fromDateMoment: null,
      toDateMoment: null,
      notSure: false,
      isSetSideEffect: false,
      isSetFromDate: false,
      sideEffects: [],
      effects: gv(props.formData, 'effects', []),
      currentSubstepIndex: gv(props.formData, 'effects.length', 0),
      timePeriodInputValue: '',
      dateInputFromValue: '',
      dateInputToValue: '',
      minToDate: '1900-01-01',
      isInputValid: true,
      hasTimeConflictError: false
    }
    this.handleOnLeavePage = this.handleOnLeavePage.bind(this);
  }

  handleOnSearchEffect = debounce(effect => {
    getSideEffects(effect)
      .then((response) => {
        if (response.data && response.data.length >= 0) {
          let sideEffectLLTCode = ''
          let sideEffectPTCode = ''

          const sideEffects = response.data.map((item, index) => {
            const label = gv(item, 'name')
            const llt_code = gv(item, 'llt_code')
            const pt_code =  gv(item, 'pt_code')
            const is_serious = gv(item, 'isSerious')

            if (label === effect) {
              sideEffectLLTCode = llt_code
              sideEffectPTCode = pt_code
            }

            return {
              id: String(index),
              label: gv(item, 'name'),
              llt_code,
              pt_code,
              is_serious
            }
          })
          this.setState({ sideEffects, sideEffectLLTCode, sideEffectPTCode })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, 200)


  handleAddSearchItem = (event) => {
    const sideEffect = event.currentTarget.value
    this.setState({ sideEffect, sideEffectLLTCode: '', sideEffectPTCode: '' }, () => {
      if (!sideEffect) {
        // clear autocomplete
        this.setState({ sideEffects: [] })
      } else {
        trackGoogleAnalyticsEvent('SideEffectsStep AutoComplete', true)
        this.handleOnSearchEffect(sideEffect)
      }
    })
  }


  handleSelectItem = (value, item) => {
    const sideEffectLLTCode = gv(item, 'llt_code', '')
    const sideEffectPTCode = gv(item, 'pt_code', '')
    const isSideEffectSerious = gv(item, 'is_serious', '')

    this.setState({
      sideEffect: value,
      sideEffectLLTCode,
      sideEffectPTCode,
      isSideEffectSerious
    }, () => {

      if (!this.state.isSetSideEffect) {
        this.setState({ isSetSideEffect: true })
      }
    })

  }

  handleClearSearchItem = () => {
    this.setState({ sideEffect: '', isSetSideEffect: false })
  }

  handleFromDate = fromDateMoment => {
    this.setState({ fromDateMoment, isSetFromDate: true })
  }

  handleToDate = toDateMoment => {
    this.setState({ toDateMoment })
  }

  handleCheckBox = () => {

    this.setState({
      notSure: !this.state.notSure,
      fromDateMoment: null,
      toDateMoment: null,
      dateInputFromValue: '',
      dateInputToValue: '',
      timePeriodInputValue: '',
      timeElName: timeElNameDefault,
      color: this.props.theme.palette.primaryGrey
    })
  }

  validate = (newSideEffect) => {

    this.setState({
      hasActiveValidation: true
    })

    const {
      fromDateMoment,
      toDateMoment,
      sideEffect,
      effects,
      currentSubstepIndex,
      timePeriodInputValue,
      sideEffectLLTCode
    } = this.state

    let success = true

    if (fromDateMoment !== null) {
      let flooredDayMoment = moment(fromDateMoment.format('YYYY-MM-DD'))
      if (moment() < flooredDayMoment) {
        trackGoogleAnalyticsEvent('ErrorFutureDate - SideEffect', true)
        this.setState({
          hasTimeConflictError: true
        })
        success = false
      } else if (toDateMoment !== null) {
        if (moment(toDateMoment) < flooredDayMoment){
          trackGoogleAnalyticsEvent('ErrorWrongDateOrder - SideEffect', true)
          this.setState({
            hasTimeConflictError: true
          })
          success = false
        }
      }
    }
    
    if (success) {
      this.setState({
        hasTimeConflictError: false
      })
    }

    if (sideEffect === '') {
      if (effects.length > 0 && !newSideEffect) {
        // It is possible to click on next if you already have added one side effect and are leaving the form empty for a second one.
        this.setState({
          hasActiveValidation: false
        })
        return true
      } else {
        trackGoogleAnalyticsEvent('ErrorEmpty - SideEffect', true)
        success = false
      }
    }

    if (sideEffectLLTCode === '') {
      let validRegex = validationRegex.stringWithoutCommaSeparators
      let isRegexValid = validRegex.test(String(sideEffect))
      if (!isRegexValid) {
        this.setState({
          isInputValid: false
        })
        return false
      } else {
          this.setState({
            isInputValid: true
          })
        }
    } else {
      this.setState({
        isInputValid: true
      })
    }

    if (!(
        this.state.notSure &&
        timePeriodInputValue !== '' &&
        this.state.timeElName !== defaultNonOption
      ) &&
      !moment(fromDateMoment, 'YYYY-MM-DD').isValid()
    ) {
      trackGoogleAnalyticsEvent('ErrorMissingDate - SideEffect', true)
      success = false
    }

    if (success) {
      this.setState({
        hasActiveValidation: false
      })
    }
    return success
  }

  handleCreateNewSideEffect = () => {
    trackGoogleAnalyticsEvent('AddButton - SideEffect', true)
    if (!this.validate(true)) {
      return
    }

    if (!this.handleIsScreenValid()) {
      return
    }

    const {
      sideEffect,
      fromDateMoment,
      toDateMoment,
      notSure,
      effects,
      timePeriodInputValue,
      timeElName,
      currentSubstepIndex,
      sideEffectLLTCode,
      sideEffectPTCode,
      isSideEffectSerious
    } = this.state

    const formData = {
      name: !sideEffect ? '' : sideEffect,
      codes: {
        meddra_llt: sideEffectLLTCode,
        meddra_pt: sideEffectPTCode
      },
      startDate: fromDateMoment && fromDateMoment.isValid() ? fromDateMoment.format('YYYY-MM-DD') : false,
      endDate: toDateMoment && toDateMoment.isValid() ? toDateMoment.format('YYYY-MM-DD') : false,
      notSure: notSure,
      timePeriodInputValue: timePeriodInputValue,
      timeElName: timeElName,
      isSerious: isSideEffectSerious
    }

    this.setState({
      currentSubstepIndex: currentSubstepIndex + 1,
      effects: [...effects, formData],
      fromDateMoment: null,
      toDateMoment: null,
      timePeriodInputValue: '',
      dateInputToValue: '',
      dateInputFromValue: '',
      timeElName: defaultNonOption,
      sideEffect: '',
      sideEffectLLTCode: '',
      sideEffectPTCode: '',
      notSure: false,
      sideEffects: []
    })
    trackGoogleAnalyticsEvent('Add - SideEffect', true)

  }

  handleNext = () => {
    if (!this.validate(false)) {
      return
    }

    let {
      sideEffect,
      sideEffectLLTCode,
      sideEffectPTCode,
      fromDateMoment,
      toDateMoment,
      notSure,
      effects,
      currentSubstepIndex,
      timePeriodInputValue,
      timeElName,
      isSideEffectSerious
    } = this.state

    let screenIsValid = this.handleIsScreenValid()
    let buffer = this.props.formData ? this.state : {}
    let formData = {
      name: !sideEffect ? '' : sideEffect,
      codes: {
        meddra_llt: sideEffectLLTCode,
        meddra_pt: sideEffectPTCode
      },
      startDate: fromDateMoment && fromDateMoment.isValid() ? fromDateMoment.format('YYYY-MM-DD') : false,
      endDate: toDateMoment && toDateMoment.isValid() ? toDateMoment.format('YYYY-MM-DD') : false,
      notSure: notSure,
      timePeriodInputValue: timePeriodInputValue,
      timeElName: timeElName,
      isSerious: isSideEffectSerious
    }

    // cycle through substep items
    if (typeof buffer.effects !== 'undefined' && currentSubstepIndex < buffer.effects.length - 1) {
      currentSubstepIndex += 1

      // @ToDo: reafctor using lodash _.get
      let startDateMoment = moment(buffer.effects[currentSubstepIndex]['startDate'], 'YYYY-MM-DD').isValid() ? moment(buffer.effects[currentSubstepIndex]['startDate'], 'YYYY-MM-DD').format('YYYY-MM-DD') : false
      let endDateMoment = moment(buffer.effects[currentSubstepIndex]['endDate'], 'YYYY-MM-DD').isValid() ? moment(buffer.effects[currentSubstepIndex]['endDate'], 'YYYY-MM-DD').format('YYYY-MM-DD') : false
      let name = buffer.effects[currentSubstepIndex]['name']
      let notSure = buffer.effects[currentSubstepIndex]['notSure']
      let timePeriodInputValue = buffer.effects[currentSubstepIndex]['timePeriodInputValue']
      let timeElName = buffer.effects[currentSubstepIndex]['timeElName']
      const meddra_llt = gv(buffer, `effects.${currentSubstepIndex}.codes.meddra_llt`, '')
      const meddra_pt = gv(buffer, `effects.${currentSubstepIndex}.codes.meddra_pt`, '')

      if (screenIsValid) { effects[currentSubstepIndex - 1] = formData }

      this.setState({
        currentSubstepIndex: currentSubstepIndex,
        toDateMoment: endDateMoment === false ? null : moment(endDateMoment, 'YYYY-MM-DD'),
        fromDateMoment: startDateMoment === false ? null : moment(startDateMoment, 'YYYY-MM-DD'),
        sideEffect: name,
        sideEffectLLTCode: meddra_llt,
        sideEffectPTCode: meddra_pt,
        notSure: notSure,
        timePeriodInputValue: timePeriodInputValue,
        timeElName: timeElName,
        effects
      })
    } else {
      // go to next big step
      if (screenIsValid) { 
        effects[currentSubstepIndex] = formData 
        trackGoogleAnalyticsEvent('Add - SideEffect', true)
      }

      this.setState({
        effects
      }, () => {
        trackGoogleAnalyticsEvent('Submit - SideEffect', true)
        this.props.onSetNextStep(this.state.effects)
      })
    }

    // clear state in case you came back from edit
    this.props.isBackToEditReset()
  }

  handleBack = () => {
    let buffer = this.props.formData ? this.state : {}
    let currentSubstepIndex = this.state.currentSubstepIndex - 1
    let screenIsValid = ((this.state.notSure !== false || this.state.fromDateMoment !== null) && this.state.sideEffect.length > 0)
    let {
      sideEffect,
      sideEffectLLTCode,
      sideEffectPTCode,
      fromDateMoment,
      toDateMoment,
      notSure,
      effects,
      timePeriodInputValue,
      timeElName
    } = this.state

    // verfica daca sunt substeps si daca sunt ...
    if (currentSubstepIndex > -1) {
      // set vales for current screen from the props
      let endDate = moment(buffer.effects[currentSubstepIndex]['endDate'], 'YYYY-MM-DD').isValid() ? moment(buffer.effects[currentSubstepIndex]['endDate']).format('YYYY-MM-DD') : false
      let startDate = moment(buffer.effects[currentSubstepIndex]['startDate'], 'YYYY-MM-DD').isValid() ? moment(buffer.effects[currentSubstepIndex]['startDate']).format('YYYY-MM-DD') : false
      let name = buffer.effects[currentSubstepIndex]['name']
      let notSure = buffer.effects[currentSubstepIndex]['notSure']
      let timePeriodInputValue = buffer.effects[currentSubstepIndex]['timePeriodInputValue']
      let timeElName = buffer.effects[currentSubstepIndex]['timeElName']
      const meddra_llt = gv(buffer, `effects.${currentSubstepIndex}.codes.meddra_llt`, '')
      const meddra_pt = gv(buffer, `effects.${currentSubstepIndex}.codes.meddra_pt`, '')

      // backup state from the previous screen
      let previousScreenState = {
        name: this.state.sideEffect,
        codes: { meddra_llt: sideEffectLLTCode, meddra_pt: sideEffectPTCode },
        startDate: fromDateMoment && fromDateMoment.isValid() ? fromDateMoment.format('YYYY-MM-DD') : false,
        endDate: toDateMoment && toDateMoment.isValid() ? toDateMoment.format('YYYY-MM-DD') : false,
        notSure: this.state.notSure,
        timePeriodInputValue: this.state.timePeriodInputValue,
        timeElName: this.state.timeElName
      }

      if (screenIsValid) { effects[currentSubstepIndex + 1] = previousScreenState }

      this.setState({
        currentSubstepIndex: currentSubstepIndex,
        toDateMoment: endDate ? moment(endDate, 'YYYY-MM-DD') : null,
        fromDateMoment: startDate ? moment(startDate, 'YYYY-MM-DD') : null,
        sideEffect: name,
        sideEffectLLTCode: meddra_llt,
        sideEffectPTCode: meddra_pt,
        notSure: notSure,
        timePeriodInputValue: timePeriodInputValue,
        dateInputToValue: endDate,
        dateInputFromValue: startDate,
        timeElName: timeElName,
        effects
      })
    } else {
      this.props.onSetBackStep(effects)
    }

    // clear state in case you came back from edit
    this.props.isBackToEditReset()
  }

  handleBackToOverview = () => {
    if (!this.validate(false)) {
      return
    }

    this.props.onSetCurrentStep(1)
    this.handleNext()
    this.props.onSetCurrentStep(8)
    this.props.onSetBackToEdit()
  }

  handleIsScreenValid = () => {
    const {
      sideEffect,
      fromDateMoment,
      notSure,
      timeElName,
      timePeriodInputValue
    } = this.state

    return ((notSure !== false && timeElName !== defaultNonOption && timePeriodInputValue !== null && fromDateMoment !== null|| fromDateMoment !== null) && sideEffect.length > 0)
  }

  handleOnLeavePage (event) {
    if ( this.state.effects.length) {
      event.preventDefault()
      event.returnValue = ''
    }
  }

  componentDidMount () {
    trackGoogleAnalyticsEvent('PageView - SideEffect', true)
    window.addEventListener('beforeunload', this.handleOnLeavePage)
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.handleOnLeavePage)
  }

  handleSimpleTimeUnitOpen = event => {
    this.setState({ timeEl: event.currentTarget })
  }

  handleSimpleTimeUnitClose = item => {
    this.setState({ timeEl: null, timeElName: item !== 'close' ? item : this.state.timeElName }, function () {
      if (this.state.timePeriodInputValue) {
        this.handleTimeMultiplicator()
      }
    })
  }

  handleTimeMultiplicator = () => {
    const timeElName = this.state.timeElName
    const timePeriodInputValue = this.state.timePeriodInputValue

    let multiplicator = 1

    if (timeElName) {
      switch(timeElName) {
        case 'Tag(en)':
          multiplicator = 1
          break
        case 'Woche(n)':
          multiplicator = 7
          break
        case 'Monat(en)':
          multiplicator = 30
          break
        case 'Jahr(en)':
          multiplicator = 365
          break
        default:
          this.setState({ fromDateMoment: null, toDateMoment: null })
          return
      }
    }

    let inputValueComputed = timePeriodInputValue ? moment(new Date(), 'YYYY-MM-DD').add(-multiplicator * parseInt(timePeriodInputValue, 10), 'day') : null
    this.setState({ fromDateMoment: inputValueComputed, toDateMoment: null })
  }

  handleSimpleTimeUnitBlur = event => {
    this.setState({ timePeriodInputValue: event.currentTarget.value }, () => {
      if (this.state.timePeriodInputValue) {
        this.handleTimeMultiplicator()
      }
    })
  }

  handleNativeToDateInputChange = event => {
    this.setState({ dateInputToValue: event.currentTarget.value, toDateMoment: isDateInputSupported() ? moment(event.currentTarget.value, 'YYYY-MM-DD') : moment(event.currentTarget.value, 'DD.MM.YYYY') })
  }

  handleNativeFromDateInputChange = event => {
    this.setState({ dateInputFromValue: event.currentTarget.value, fromDateMoment: isDateInputSupported() ? moment(event.currentTarget.value, 'YYYY-MM-DD') : moment(event.currentTarget.value, 'DD.MM.YYYY') })
  }

  handleNativeFromDateInputBlur = event => {
    this.setState({ minToDate: event.currentTarget.value })
  }

  handleSimpleTimeUnitChange = event => {
    const pattern = /^(0?[1-9]|[1-9][0-9])$/;
      if(event.currentTarget.value === '' || pattern.test(event.currentTarget.value) ){
        this.setState({ timePeriodInputValue: event.currentTarget.value }, ()=> {
          this.handleTimeMultiplicator()
      })
    }
  }


  render () {
    const {
      timeEl,
      timeElName,
      fromDateMoment,
      toDateMoment,
      notSure,
      sideEffect,
      defaultSideEffect,
      sideEffects,
      effects,
      isBackToEdit,
      currentSubstepIndex,
      timePeriodInputValue,
      dateInputFromValue,
      dateInputToValue,
      minToDate,
      hasActiveValidation,
      isInputValid,
      hasTimeConflictError
    } = this.state


    let numberOfEffectsToList = 3
    let effectsSliced = effects.slice(0, numberOfEffectsToList);
    return (
      <div>
        <StepWrapper>
          {
            effects.length > 0 &&
            <ItemsList
              color={this.props.theme.palette.primary.main}
              textColor={this.props.theme.palette.text.primary}>
              <p>Bisher eingetragen</p>
              <div>{
                <ul>
                  {
                    effectsSliced.map((el, index) => {
                      return <li key={index}>{el.name} : {el.startDate ? el.startDate : '?'}  - {el.endDate ? el.endDate : 'k.A.'}</li>
                    })
                  }
                  {
                    effects.length > numberOfEffectsToList &&
                    <li style={{fontStyle: "italic"}}>Anzahl weitere Einträge: {effects.length - numberOfEffectsToList}</li>
                  }
                </ul>
              }</div>
            </ItemsList>
          }
          <SideEffectInput
            sideEffects={sideEffects}
            onClear={this.handleClearSearchItem}
            onSearch={this.handleOnSearchEffect}
            onSelect={this.handleSelectItem}
            placeholder={'z. B. Erbrechen'}
            inputId={'sideEffect'}
            title={i18next.t('What side effect do you have?')}
            value={sideEffect}
            defaultValue={defaultSideEffect}
            hasActiveValidation={hasActiveValidation}
            tooltipId='sideEffect'
            tooltipText={'Eine Nebenwirkung nach Möglichkeit aus der Liste auswählen'}
            errorText={ sideEffect != '' ? ( validationRegex.stringWithoutCommaSeparators.test(String(sideEffect)) ? '' : 'Please enter only one side effect you are experiencing. You can add more side effects by pressing the plus below.' ) : 'Please enter the side effect you are experiencing.'}
            isInputValid={isInputValid}
          />
          <NativePicker
            fromDateMoment={fromDateMoment}
            notSure={notSure}
            onCheckBox={this.handleCheckBox}
            onSimpleTimeUnitClose={this.handleSimpleTimeUnitClose}
            onSimpleTimeUnitOpen={this.handleSimpleTimeUnitOpen}
            onSimpleTimeUnitBlur={this.handleSimpleTimeUnitBlur}
            color={timeElName === timeElNameDefault ? this.props.theme.palette.primaryGrey : this.props.theme.palette.primary.main }
            onSimpleTimeUnitChange={this.handleSimpleTimeUnitChange}
            onHandleNativeFromDateInputChange={this.handleNativeFromDateInputChange}
            onHandleNativeFromDateInputBlur={this.handleNativeFromDateInputBlur}
            onHandleNativeToDateInputChange={this.handleNativeToDateInputChange}
            onSetFromDate={this.handleFromDate}
            onSetToDate={this.handleToDate}
            title={ i18next.t('In which period?') }
            fromCaption={ 'Erstmalig aufgetreten' }
            toCaption={ 'Vorbei gewesen am (optional)' }
            timeframeCaption="Seit ca."
            toDateMoment={toDateMoment}
            timeEl={timeEl}
            timeElName={timeElName}
            timePeriodInputValue={timePeriodInputValue}
            dateInputFromValue={dateInputFromValue}
            dateInputToValue={dateInputToValue}
            minToDate={minToDate}
            tooltipId="sideEffectRange"
            tooltipFromText={ 'Wählen Sie den Tag aus, an dem Ihre Nebenwirkung das erste Mal aufgetreten ist.' }
            tooltipToText={'Falls Ihre Nebenwirkung nicht mehr auftritt, geben Sie bitte den letzten Tag der Erscheinung an.'}
            errorText={ effects.length > 0 ? '' : 'Tag an dem die Nebenwirkung das erste Mal aufgetreten ist.' }
            hasTimeConflictError = {hasTimeConflictError}         
            timeConflictErrorText={(      
              fromDateMoment !== null
              ) ?
                ((toDateMoment !== null && fromDateMoment > toDateMoment) ? ('Das Startdatum muss vor dem Enddatum liegen.')
                :
                ((moment() < fromDateMoment) ? ('Das Datum kann nicht in der Zukunft liegen') : '')): ''
              }
            hasActiveValidation={hasActiveValidation}
          />
          <div onClick={this.handleCreateNewSideEffect} style={{padding: '0.5rem 0 1rem', marginTop: '0'}}>
            <MedButton
              step={1}
              variant="contained"
              themeColor={this.props.theme.palette.primary.main}
            ></MedButton>
            <AddMoreButton
              item="Weitere Nebenwirkungen hinzufügen"
              themeColor={this.props.theme.palette.text.primary}
              ></AddMoreButton>
          </div>
          {
            isBackToEdit !== '' &&
            <OverviewButton
              onClick={this.handleBackToOverview}
              step={1}
              themeColor={this.props.theme.palette.primary}
              variant="contained"
            >
              Zurück zur Übersicht
            </OverviewButton>
          }
        </StepWrapper>
        <BottomBtnGroup>
          {
            (effects.length > 0 && currentSubstepIndex > 0) &&
            <FlexButtons>
              <BackAnchor
                onClick={this.handleBack}
                title='back'
                variant='contained'
                themecolor={this.props.theme.palette.primary.main}
              >
              ◄ Zurück
              </BackAnchor>            
              <ButtonItem
              color="primary"
              id='next'
              onClick={this.handleNext}
              title='next'
              variant='contained'
            >
              Weiter
            </ButtonItem>
          </FlexButtons>
          }
          {
            (effects.length === 0 || currentSubstepIndex < 1) &&
            <FlexButtons>
              <ButtonItem
                color="primary"
                id='next'
                onClick={this.handleNext}
                title='next'
                variant='contained'
                style={{ width: '100%', margin: 0, padding: '16px 0' }}
              >
                Weiter
              </ButtonItem>
            </FlexButtons>
          }
        </BottomBtnGroup>
      </div>
    )
  }
}

SideEffectsStep.propTypes = {
  onSetBackStep: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired
}

export default withTheme(translate('translations')(SideEffectsStep))
