import styled from 'styled-components'
import * as palette from 'styles/palette'

import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'

export const Container = styled.div`
`

export const SearchIconItem = styled(SearchIcon)`
  &&& {
    position: absolute;
    right: .4rem;
    top: .5rem;
    font-size: 3rem;
    color: ${props => props.mainColor};
    fill: ${props => props.mainColor};
  }
`

export const CloseIconItem = styled(CloseIcon)`
  &&& {
    font-size: 3rem;
    color: ${props => props.mainColor};
    fill: ${props => props.mainColor};
    padding: .4rem;
    border: 1px solid ${props => props.mainColor};
    border-radius: .5rem;
    margin-left: 1rem;
    background-color: ${palette.superLightGreen};
  }
`

export const SearchContainer = styled.div`
  &&& {
    position: relative;
    display: flex;
    height: 4rem;

    &.small {
      max-width: 200px;
    }

    & > div {
      z-index: 1000 !important;
    }

    & > div > div {
      z-index: 1;
      background: rgba(255, 255, 255, 1) !important;
      position: unset !important;
      max-height: 1000px !important;
      min-width: 300px !important;
      z-index: 100;
      & > div {
        max-width: 345px;
        padding: 1.7rem 0 1.7rem 1.7rem;
        border-bottom: 1px solid ${palette.searchBorderColor};
        &:last-child {
          border: none;
        }
      }
    }
    
    & > div {
      width: 100%;
      & > input {
        width: 100%;
        height: 4rem;
        border-radius: .5rem;
        padding: 1rem;
        font-size: 1.5rem !important;
        line-height: 2rem !important;
        box-sizing: border-box;
        background: transparent;
        border: 1px solid ${props => props.borderColor};
        color: ${props => props.mainColor};
        ::placeholder {
          color: ${props => props.placeholderColor};
        }
        &:focus, &:active {
          outline: none;
          color: ${props => props.mainColor};
          border: 2px solid ${props => props.focusColor};
          padding: calc(1rem - 1px);
        }
      }
    }

    &.error > div {
      & > input {
        border: 2px solid ${props => props.errorColor};
        padding: calc(1rem - 1px);
      }
    }
  }
`
