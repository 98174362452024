import React, { Component } from 'react'
import lodash from 'lodash'
import axios from 'axios'
import * as Sentry from '@sentry/browser'
import Overview from './overview'
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'
import Step5 from './step5'
import Step6 from './step6'
import StepContactDetails from './stepContactDetails'
import Confirm from './confirm'
import Success from './success'
import config from '../../config'
import { getStringParamFromURL } from 'lib/functions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Spinner } from './styles'
import { MainContainer } from '../MainPage/styles'
import Container from 'lib/elements/Container'
import {
  questionDrugDosage,
  questionDrugIndication,
  questionDrugBatchNumber,
  questionDrugEffectStatus,
  questionCustom,
  questionTemplate,
  questionDrugAction,
  questionContactDetails
} from 'constants.js'

import { withTheme } from '@material-ui/core'

const OVERVIEW_STEP = 1
const DOSAGE_STEP = 2
const INDICATION_STEP = 3
const BATCH_NUMBER_STEP = 4
const EFFECTS_STEP = 5
const CUSTOM_STEP = 6
const DRUG_ACTION_STEP = 7
const CONTACT_DETAILS_STEP = 8
const CONFIRM_STEP = 9
const SUCCESS_STEP = 10

export class Index extends Component {
  state = {
    step: 0,
    answerData: {},
    report: {},
    metaData: {},
    questions: [],
    multipleChoicesOptions: [],
    radioButtonOptions: [],
    pharma: {},
    steps: [],
    customAnswerData: {}
  };

  constructor(props) {
    super(props)

    console.info(`Pipeline id: ${process.env.REACT_APP_CI_PIPELINE_ID}`);

    // Initialize Sentry
    if (config.sentryDSN) {
      Sentry.init({
        dsn: config.sentryDSN,
        environment: process.env.NODE_ENV
      })
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  // setup phase
  componentDidMount() {
    let token = getStringParamFromURL(window.location.search, 'token')
    let headers = {
      'X-API-Version': '3.0.0',
      'X-Event-App-Created': 'med-app-widget',
      'X-Event-Role': 'patient',
      'Authorization': `Bearer ${token}`
    }
    const reportId = /^\/follow-ups\/report\/([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9Aa-f]{3}-[0-9a-f]{12})/.exec(window.location.pathname)[1]
    axios.get(`${config.EVENTSTORE_BASE_URL}/patient/report/${reportId}`, { headers: headers })
      .then(res => {
        let report = res.data
        let questions = report.metaData.questions.fromPharma.toPatient
          .filter(question => !question.answer)

        let multipleChoicesOptions = questions
          .filter(item => item.type === 'template')
          .filter((question) => {
            if (question.format === 'multiple-choice') {
              return question
            }
          })
          .map(question => question.allowedOptions)

        let radioButtonOptions = questions
          .filter(item => item.type === 'template')
          .filter((question) => {
            if (question.format === 'single-choice') {
              return question
            }
          })
          .map(question => question.allowedOptions)

        let steps = [OVERVIEW_STEP]

        let dosageQuestion = questions.filter(item => item.type === questionDrugDosage).pop()
        if (dosageQuestion) {
          steps.push(DOSAGE_STEP)
        }
        let indicationQuestion = questions.filter(item => item.type === questionDrugIndication).pop()
        if (indicationQuestion) {
          steps.push(INDICATION_STEP)
        }

        let batchNumberQuestion = questions.filter(item => item.type === questionDrugBatchNumber).pop()
        if (batchNumberQuestion) {
          steps.push(BATCH_NUMBER_STEP)
        }

        let effectQuestion = questions.filter(item => item.type === questionDrugEffectStatus).pop()
        if (effectQuestion) {
          steps.push(EFFECTS_STEP)
        }

        let customQuestions = questions.filter(item => item.type === questionCustom || item.type === questionTemplate)
        if (customQuestions.length) {
          steps.push(CUSTOM_STEP)
        } else {
          customQuestions = ''
        }

        let drugActionQuestion = questions.filter(item => item.type === questionDrugAction).pop()
        if (drugActionQuestion) {
          steps.push(DRUG_ACTION_STEP)
        }

        let contactDetailsQuestion = questions.filter(item => item.type === questionContactDetails).pop()
        if (contactDetailsQuestion) {
          steps.push(CONTACT_DETAILS_STEP)
        }

        steps.push(CONFIRM_STEP, SUCCESS_STEP)

        this.setState({ // TODO: Don't use setState inside this life cycle method because it causes reendering && performance issues
          steps,
          report,
          token,
          reportId,
          dosageQuestion,
          indicationQuestion,
          batchNumberQuestion,
          effectQuestion,
          customQuestions,
          drugActionQuestion,
          questions,
          multipleChoicesOptions,
          radioButtonOptions,
          contactDetailsQuestion
        })
      })
  }

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state
    this.setState({
      step: step + 1
    })
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state
    this.setState({
      step: step - 1
    })
  };

  // handlers of step1 : drugs.dose.amount, Unit, Interval
  handleAddDoseAmount = (index, amount) => {
    this.setState((prevState) => {
      let answerData = lodash.cloneDeep(prevState.answerData)
      lodash.set(answerData, `drugs[${index}].dose.amount`, parseInt(amount, 10))
      lodash.set(answerData, `drugs[${index}]._id`, lodash.get(prevState.report, `drugs[${index}]._id`))
      return { answerData }
    })
  }

  // handlers of step1 : drugs.dose.unit
  handleAddDoseUnit = (index, unit) => {
    this.setState((prevState) => {
      let answerData = lodash.cloneDeep(prevState.answerData)
      lodash.set(answerData, `drugs[${index}].dose.unit`, unit)
      lodash.set(answerData, `drugs[${index}]._id`, lodash.get(prevState.report, `drugs[${index}]._id`))
      return { answerData }
    })
  }

  // handlers of step1 : drugs.dose.interval
  handleAddDoseInterval = (index, interval) => {
    this.setState((prevState) => {
      let answerData = lodash.cloneDeep(prevState.answerData)
      lodash.set(answerData, `drugs[${index}].dose.interval`, interval)
      lodash.set(answerData, `drugs[${index}]._id`, lodash.get(prevState.report, `drugs[${index}]._id`))
      return { answerData }
    }
    )
  }

  // handlers for step2 : indications.name
  handleAddText = (index, name) => {
    this.setState((prevState) => {
      let answerData = lodash.cloneDeep(prevState.answerData)
      lodash.set(answerData, `drugs[${index}].indications`, [{ name: name }])
      lodash.set(answerData, `drugs[${index}]._id`, lodash.get(prevState.report, `drugs[${index}]._id`))
      return { answerData }
    })
  }

  // handlers for step3 : batchNumber
  handleAddTextMedikament = (index, batchNumber) => {
    this.setState((prevState) => {
      let answerData = lodash.cloneDeep(prevState.answerData)
      lodash.set(answerData, `drugs[${index}].codes.batchNumber`, batchNumber)
      lodash.set(answerData, `drugs[${index}]._id`, lodash.get(prevState.report, `drugs[${index}]._id`))
      return { answerData }
    })
  }

  // handlers for step4 : effects.status
  handleSideEffect = (index, effects) => {
    this.setState((prevState) => {
      let answerData = lodash.cloneDeep(prevState.answerData)
      lodash.set(answerData, `effects[${index}].status`, effects)
      lodash.set(answerData, `effects[${index}]._id`, lodash.get(prevState.report, `effects[${index}]._id`))
      return { answerData }
    })
  }

  // handler for step 5: customAnswerData
  handleCustomAnswer = (questionId, answerText) => {
    this.setState((prevState) => {
      let customAnswerData = lodash.cloneDeep(prevState.customAnswerData)
      customAnswerData[questionId] = answerText
      return { customAnswerData }
    })
  }

  handleFileAnswer = (questionId, answerFile) => {
    this.setState((prevState) => {
      let customAnswerData = lodash.cloneDeep(prevState.customAnswerData)
      customAnswerData[questionId] = answerFile
      return { customAnswerData }
    })
  }

  // Handler for checkboxes
  handleCheckboxes = (questionId, answerCheckbox) => {
    this.setState((prevState) => {
      let customAnswerData = lodash.cloneDeep(prevState.customAnswerData)
      customAnswerData[questionId] = answerCheckbox.join(', ')
      return { customAnswerData }
    })
  }

  // Handler for Radio buttons
  handleRadioButton = (questionId, answerRadioButton) => {
    this.setState((prevState) => {
      let customAnswerData = lodash.cloneDeep(prevState.customAnswerData)
      customAnswerData[questionId] = answerRadioButton
      return { customAnswerData }
    })
  }

  // handler for the date picker
  handleDateAnswer = (questionId, answerDate) => {
    this.setState((prevState) => {
      let customAnswerData = lodash.cloneDeep(prevState.customAnswerData)
      customAnswerData[questionId] = answerDate
      return { customAnswerData }
    })
  }

  // Action Drug
  handleActionDrugAnswer = (index, action) => {
    this.setState((prevState) => {
      let answerData = lodash.cloneDeep(prevState.answerData)
      lodash.set(answerData, `drugs[${index}].action`, action)
      lodash.set(answerData, `drugs[${index}]._id`, lodash.get(prevState.report, `drugs[${index}]._id`))
      return { answerData }
    })
  }

  // Contact details handler
  handleAddContactDetails = ( answer ) => {
    this.setState((prevState) => {
      let answerData = lodash.cloneDeep(prevState.answerData)
      lodash.set(answerData, 'contact', answer)
      return { answerData }
    })
  }

  // rendering
  render() {
    const { step } = this.state

    switch (this.state.steps[step]) {
      case OVERVIEW_STEP:
        return (
          <Overview
            batchNumberQuestion={this.state.batchNumberQuestion}
            dosageQuestion={this.state.dosageQuestion}
            effectQuestion={this.state.effectQuestion}
            indicationQuestion={this.state.indicationQuestion}
            customQuestions={this.state.customQuestions}
            drugActionQuestion={this.state.drugActionQuestion}
            contactDetailsQuestion={this.state.contactDetailsQuestion}
            nextStep={this.nextStep}
            firstQuestion={this.state.steps[1]}
          />
        )
      case DOSAGE_STEP:
        return (
          <Step1
            answerDrugs={this.state.answerData.drugs}
            drugs={this.state.report}
            handleAddDoseAmount={this.handleAddDoseAmount}
            handleAddDoseInterval={this.handleAddDoseInterval}
            handleAddDoseUnit={this.handleAddDoseUnit}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
          />
        )
      case INDICATION_STEP:
        return (
          <Step2
            answerDrugs={this.state.answerData.drugs}
            drugs={this.state.report.drugs}
            handleAddText={this.handleAddText}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
          />
        )
      case BATCH_NUMBER_STEP:
        return (
          <Step3
            answerDrugs={this.state.answerData.drugs}
            drugs={this.state.report.drugs}
            handleAddTextMedikament={this.handleAddTextMedikament}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
          />
        )
      case EFFECTS_STEP:
        return (
          <Step4
            answerEffects={this.state.answerData.effects}
            effects={this.state.report.effects}
            color={this.state.color}
            onHandleSideEffect={this.handleSideEffect}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
          />
        )
      case CUSTOM_STEP:
        return (
          <Step5
            onHandleCustomAnswer={this.handleCustomAnswer}
            onHandlecheckboxes={this.handleCheckboxes}
            onHandleRadioButton={this.handleRadioButton}
            onHandleDateAnswer={this.handleDateAnswer}
            onHandleFileAnswer={this.handleFileAnswer}
            questions={this.state.questions}
            options={this.state.options}
            radioButtonOptions={this.state.radioOptions}
            customAnswerData={this.state.customAnswerData}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            reportId={this.state.reportId}
          />
        )
      case DRUG_ACTION_STEP:
        return (
          <Step6
            answerDrugs={this.state.answerData.drugs}
            onHandleActionAnswer={this.handleActionDrugAnswer}
            drugs={this.state.report.drugs}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
          />
        )
      case CONFIRM_STEP:
        return (
          <Confirm
            answerData={this.state.answerData}
            customQuestions={this.state.customQuestions}
            drugActionQuestion={this.state.drugActionQuestion}
            contactDetailsQuestion={this.state.contactDetailsQuestion}
            customAnswerData={this.state.customAnswerData}
            batchNumberQuestion={this.state.batchNumberQuestion}
            data={this.state.report}
            dosageQuestion={this.state.dosageQuestion}
            effectQuestion={this.state.effectQuestion}
            effects={this.state.report.effects}
            indicationQuestion={this.state.indicationQuestion}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            token={this.state.token}
            reportId={this.state.reportId}
          />
        )
      case SUCCESS_STEP:
        return <Success />

      case CONTACT_DETAILS_STEP:
        return (
          <StepContactDetails
            answerContact={this.state.answerData.contact}
            handleAddContactDetails={this.handleAddContactDetails}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
          />
        )

      default:
        return <Container><MainContainer><Spinner aria-label={'Circular progress spinner'}><CircularProgress /></Spinner></MainContainer></Container>
    }
  }
}

export default withTheme(Index)
