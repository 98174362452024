import axios from 'axios'
import uuid from 'uuid-v4'
import config from 'config'

import { getStringParamFromURL } from 'lib/functions'

const getSideEffects = (adverse) => {
  return axios.get(`${config.AUTOCOMPLETE_BASE_URL}/effect/${adverse}?size=${config.AUTOCOMPLETE_SEARCH_LIST_SIZE}`)
}

const getMedicines = (name, countryCode = 'de') => {
  return axios.get(`${config.AUTOCOMPLETE_BASE_URL}/medicine/${countryCode}/${name}?size=${config.AUTOCOMPLETE_SEARCH_LIST_SIZE}`)
}

const getDoctors = (name) => {
  if (name) {
    return axios.get(`${config.DOCTOR_AUTOCOMPLETE_BASE_URL}/public/de-DE/search/hcps/${name}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-Version': '1.0.0',
        'X-App': 'med-app-widget'
      },
      data: {} // need to send empty data due to https://github.com/axios/axios/issues/1083
    })
  } else return false
}

const createReport = (report) => {
  const reportId = uuid()
  const appId = getStringParamFromURL(window.location.search, 'appId')
  const sourceDomain = getStringParamFromURL(window.location.search, 'sourceDomain')
  let headers = {
    'Content-Type': 'application/json',
    'X-API-Version': '3.0.0',
    'X-Event-Version': '3.0.0',
    'X-Event-App-Created': appId.length ? 'med-app-widget:' + appId : 'med-app-widget',
    'X-Event-Role': 'patient'
  }
  if (sourceDomain.length) { headers['X-Event-Referrer'] = sourceDomain }

  const createReportPromise = axios.post(`${config.EVENTSTORE_BASE_URL}/report/${reportId}`, report, { headers: headers })
  // TODO: we temporarily re-enabled the process command, since right now it's not possible to add an HCP.
  // once we re-enable that feature, we should disable this again. Then the following applies again:
  // The "process" is added automatically after 1 hour of inactivity on the report
  // should be changed later so that the doctor can be added later and we can still process it immediately
  createReportPromise.then((response) => {
    return axios.put(`${config.EVENTSTORE_BASE_URL}/streams/report/${reportId}/process`, { reportId }, { headers: headers })
  })
  return createReportPromise
}

const updateReport = (reportId, dataToPatch) => {
  const appId = getStringParamFromURL(window.location.search, 'appId')
  const sourceDomain = getStringParamFromURL(window.location.search, 'sourceDomain')
  let headers = {
    'Content-Type': 'application/json',
    'X-API-Version': '3.0.0',
    'X-Event-Version': '3.0.0',
    'X-Event-App-Created': appId.length ? 'med-app-widget:' + appId : 'med-app-widget',
    'X-Event-Role': 'patient'
  }
  if (sourceDomain.length) { headers['X-Event-Referrer'] = sourceDomain }

  return axios.patch(`${config.EVENTSTORE_BASE_URL}/streams/report/${reportId}/update-report-data`, dataToPatch, { headers: headers })
    .then(() => {
      // once the HCP is added, the report cannot be edited anymore and can be processed immediately
      return axios.put(`${config.EVENTSTORE_BASE_URL}/streams/report/${reportId}/process`, { reportId }, { headers: headers })
    })
}

const getHeaders = (withAuthHeader = false) => {
  const appId = getStringParamFromURL(window.location.search, 'appId')
  const sourceDomain = getStringParamFromURL(window.location.search, 'sourceDomain')
  let headers = {
    'Content-Type': 'application/json',
    'X-API-Version': '3.0.0',
    'X-Event-App-Created': appId.length ? 'med-app-widget:' + appId : 'med-app-widget',
    'X-Event-Role': 'patient'
  }
  if (sourceDomain.length) { headers['X-Event-Referrer'] = sourceDomain }

  if (withAuthHeader) {
    const token = getStringParamFromURL(window.location.search, 'token')
    headers['Authorization'] = `Bearer ${token}`
  }

  return headers
}

const downloadQuestionFile = (fileId, templateId, reportId) => {
  return axios.get(`${config.IDENTITY_BASE_URL}/patient/file/${fileId}`,
    {
      responseType: 'blob',
      headers: getHeaders(true),
      params: { reportId: reportId, templateId: templateId }
    }
  )
}

const uploadAnswerFile = (file, reportId, templateId, uploadProgressCb) => {
  const headers = getHeaders(true)
  headers['Content-Type'] = 'multipart/form-data'

  return axios.post(`${config.IDENTITY_BASE_URL}/patient/file`, file,
    {
      headers: headers,
      params: { reportId: reportId, templateId: templateId },
      onUploadProgress: uploadProgressCb
    }
  )
}

const deleteAnswerFile = (fileId, reportId, templateId) => {
  return axios.delete(`${config.IDENTITY_BASE_URL}/patient/file/${fileId}`, { headers: getHeaders(true), params: { reportId: reportId, templateId: templateId } })
}

export {
  getSideEffects,
  getMedicines,
  getDoctors,
  createReport,
  updateReport,
  downloadQuestionFile,
  uploadAnswerFile,
  deleteAnswerFile
}
