import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { translate, Trans } from 'react-i18next'
import { debounce, get as gv } from 'lodash'

import MedicationsInput from 'components/MedicationsInput'
import NativePicker from 'components/NativePicker'
import MedButton from 'lib/elements/Button'
import OverviewButton from 'lib/elements/OverviewButton'
import AddMoreButton from 'lib/elements/AddMoreButton'
import { withTheme } from '@material-ui/core/styles'

import HowOften from 'components/HowOften'

import { getMedicines } from 'service/index'
import { trackGoogleAnalyticsEvent } from 'service/tracker'

import { validationRegex } from '../../../constants'

import {
  BottomBtnGroup,
  ButtonItem,
  FlexButtons,
  BackAnchor,
  ItemsList,
  StepWrapper
} from '../styles'

import {
  defaultNonOption,
  toolList,
  timeUnitList
} from '../../../constants'
import { isDateInputSupported } from 'lib/functions'

const convertUnitGermanToEn = (str) => {
  switch (str) {
    case 'Tablette(n)':
      return 'tablet'
    case 'Kapsel(n)':
      return 'capsule'
    case 'Tropfe(n)':
      return 'drop'
    case 'Stück':
      return 'piece'
    case 'Spritze(n)':
      return 'injection'
    case 'mg':
      return 'mg'
    case 'ml':
      return 'ml'
    case 'Zäpfche(n)':
      return 'suppository'
    case 'Einheit':
      return 'unit'
    case 'Hübe/Sprühstöße':
      return 'puff'
    case 'Mikrogramm':
    return 'ug'
    case defaultNonOption:
      return 'Please choose'
    default:
      return 'unknown'
  }
}

const convertIntervalGermanToEn = (str) => {
  switch (str) {
    case 'stündlich':
      return 'hourly'
    case 'täglich':
      return 'daily'
    case 'wöchentlich':
      return 'weekly'
    case 'monatlich':
      return 'monthly'
    case 'ein einziges Mal':
      return 'once'
    case 'Intervall':
      return 'interval'
    case 'bei Bedarf':
      return 'when_needed'
    case defaultNonOption:
      return 'Please choose'
    default:
      return 'unknown'
  }
}

const convertUnitEnToGerman = (str) => {
  switch (str) {
    case 'tablet':
      return 'Tablette(n)'
    case 'capsule':
      return 'Kapsel(n)'
    case 'drop':
      return 'Tropfe(n)'
    case 'piece':
      return 'Stück'
    case 'injection':
      return 'Spritze(n)'
    case 'mg':
      return 'mg'
    case 'ml':
      return 'ml'
    case 'suppository':
      return 'Zäpfche(n)'
    case 'unit':
      return 'Einheit'
    case 'Please choose':
      return defaultNonOption
    default:
      return 'unknown'
  }
}

const convertIntervalEnToGerman = (str) => {
  switch (str) {
    case 'hourly':
      return 'stündlich'
    case 'daily':
      return 'täglich'
    case 'weekly':
      return 'wöchentlich'
    case 'monthly':
      return 'monatlich'
    case 'once':
      return 'ein einziges Mal'
    case 'when_needed':
      return 'bei Bedarf'
    case 'interval':
      return 'Intervall'
    case 'Please choose':
      return defaultNonOption
    default:
      return 'unknown'
  }
}

class MedicationsStep extends React.Component {
  constructor (props) {
    super(props)

    let firstEffectStartDate = props.formData.effects.reduce(
      (accumulator, effect) => Math.min(accumulator, moment(effect.startDate)),
      moment()
    )

    this.state = {
      isBackToEdit: props.isBackToEdit ? props.isBackToEdit : '',
      // TODO: Uhh, this variable should be refactored to be named 'medicine' or similar... :)
      sideEffect: '',
      selectedMedicinePZN: '',
      selectedMedicineATC: '',
      selectedMedicineManufacturer: '',
      fromDateMoment: null,
      toDateMoment: null,
      notSure: false,
      isSetSideEffect: false,
      isSetFromDate: false,
      timeEl: null,
      timeElName: defaultNonOption,
      toolEL: null,
      toolName: defaultNonOption,
      timeUnitEL: null,
      timeUnitName: defaultNonOption,
      unitCount: '',
      medicines: [],
      drugs: gv(props.formData, 'drugs', []),
      currentSubstepIndex: gv(props.formData, 'drugs.length', 0),
      timePeriodInputValue: '',
      dateInputFromValue: '',
      dateInputToValue: '',
      title: '',
      minToDate: '1900-01-01',
      firstEffectStartDate: firstEffectStartDate,
      isInputValid: true,
      countrySelected: 'de',
      hasTimeConflictError: false
    }
  }

  componentWillMount() {
    let title;
    if (this.state.drugs.length < 1) {
      title = 'Welches Medikament verdächtigen Sie?'
    }
    else {
      title = 'Welches Medikament nehmen Sie ein?'
    }
    this.setState({
      title: title
    })
  }

  componentDidMount () {
    trackGoogleAnalyticsEvent('PageView - Medication', true)
  }

  handleAutocompleteDebounced = debounce((drugName) => {
    getMedicines(drugName, this.state.countrySelected)
      .then((response) => {
        if (response.data && response.data.items.length >= 0) {
          let selectedMedicinePZN = ''
          let selectedMedicineATC = ''
          let selectedMedicineManufacturer = ''

          const medicines = response.data.items
            .map((item, index) => {
              const label = gv(item, 'medicine')
              const pzn = gv(item, 'pzn', '')
              const atc = gv(item, 'atc_international', '')
              const manufacturer = gv(item, 'firmenname', '')

              if (label === drugName) {
                selectedMedicinePZN = pzn
                selectedMedicineATC = atc
                selectedMedicineManufacturer = manufacturer
              }

              return {
                id: String(index),
                label,
                atc,
                manufacturer,
                pzn
              }
            })

          this.setState({ medicines, selectedMedicinePZN, selectedMedicineATC, selectedMedicineManufacturer })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, 200)

  handleAddSearchItem = value => {
    const drugName = value
    this.setState({ sideEffect: drugName, selectedMedicinePZN: '', selectedMedicineATC: '', selectedMedicineManufacturer: '' }, () => {
      if (!drugName) {
        this.setState({ medicines: [] })
      } else {
        this.handleAutocompleteDebounced(drugName)
      }
    })
  }

  handleCountryChange = value => {
    if(value){
      this.setState({countrySelected: value}, () => {
        if(this.state.sideEffect){
          this.handleAutocompleteDebounced(this.state.sideEffect)
        }
      })
    }
  }

  handleSelectItem = (value, item) => {
    this.setState({
      sideEffect: value,
      selectedMedicinePZN: gv(item, 'pzn', ''),
      selectedMedicineATC: gv(item, 'atc', ''),
      selectedMedicineManufacturer: gv(item, 'manufacturer', '')
    }, () => {
      if (!this.state.isSetSideEffect) {
        this.setState({ isSetSideEffect: true })
      }
    })
  }

  handleClearSearchItem = () => {
    this.setState({ sideEffect: '', isSetSideEffect: false })
  }

  handleFromDate = fromDateMoment => {
    this.setState({ fromDateMoment, isSetFromDate: true })
  }

  handleToDate = toDateMoment => {
    this.setState({ toDateMoment })
  }

  handleCheckBox = () => {
    this.setState({
      notSure: !this.state.notSure,
      fromDateMoment: null,
      toDateMoment: null,
      dateInputFromValue: '',
      dateInputToValue: '',
      timePeriodInputValue: '',
      timeElName: defaultNonOption
    })
  }

  handleToolClose = item => {
    this.setState({ toolEL: null, toolName: item !== 'close' ? item : this.state.toolName })
  }

  handleToolOpen = event => {
    this.setState({ toolEL: event.currentTarget })
  }

  handleTimeUnitClose = item => {
    this.setState({ timeUnitEL: null, timeUnitName: item !== 'close' ? item : this.state.timeUnitName })
  }

  handleTimeUnitOpen = event => {
    this.setState({ timeUnitEL: event.currentTarget })
  }

  handleChangeUnitCount = event => {
    const pattern= /^[0-9]{0,4}$/;
    if(event.currentTarget.value === '' || pattern.test(event.currentTarget.value) ){
      this.setState({ unitCount: event.currentTarget.value })
    }
  }

  handleIsScreenValid = () => {
    let {
      sideEffect,
      fromDateMoment,
      notSure,
      timePeriodInputValue,
      timeElName
    } = this.state

    return ((notSure !== false && timeElName !== defaultNonOption && timePeriodInputValue !== null && fromDateMoment !== null || fromDateMoment !== null) && sideEffect.length > 0)
  }

  validate = (newMedication) => {
    this.setState({
      hasActiveValidation: true
    })

    const {
      fromDateMoment,
      toDateMoment,
      sideEffect,
      drugs,
      timePeriodInputValue,
      firstEffectStartDate,
      selectedMedicinePZN
    } = this.state

    let success = true


    if (fromDateMoment !== null) {
      let flooredDayMoment = moment(fromDateMoment.format('YYYY-MM-DD'))
      if (moment() < flooredDayMoment) {
        trackGoogleAnalyticsEvent('ErrorFutureDate - Medication', true)
        this.setState({
          hasTimeConflictError: true
        })
        success = false
      } else if (toDateMoment !== null && moment(toDateMoment) < flooredDayMoment) {
        trackGoogleAnalyticsEvent('ErrorWrongDateOrder - Medication', true)
        this.setState({
          hasTimeConflictError: true
        })
        success = false
      } else if (drugs.length === 0 && firstEffectStartDate < flooredDayMoment) {
        trackGoogleAnalyticsEvent('ErrorWrongDate - Medication', true)
        this.setState({
          hasTimeConflictError: true
        })
        success = false
      }
    }

    if (success) {
      this.setState({
        hasTimeConflictError: false
      })
    }

    if (sideEffect === '') {
      if (drugs.length > 0 && !newMedication) {
        // It is possible to click on next if you already have added one medication and are leaving the form empty for a second one.
        this.setState({
          hasActiveValidation: false
        })
        return true
      } else {
        trackGoogleAnalyticsEvent('ErrorEmpty - Medication', true)
        success = false
      }
    }
    
    if (selectedMedicinePZN === '') {
      let validRegex = validationRegex.stringWithoutCommaSeparators
      let isRegexValid = validRegex.test(String(sideEffect))
      if (!isRegexValid) {
        this.setState({
          isInputValid: false
        })
        return false
      } else {
        this.setState({
          isInputValid: true
        })
      }
  } else {
    this.setState({
      isInputValid: true
    })
  }

    if (!(
        this.state.notSure && 
        timePeriodInputValue !== '' && 
        this.state.timeElName !== defaultNonOption 
        ) &&
        !moment(fromDateMoment, 'YYYY-MM-DD').isValid()
      ) {
      trackGoogleAnalyticsEvent('ErrorMissingDate - Medication', true)
      success = false
    }

    if (success) {
      this.setState({
        hasActiveValidation: false
      })
    }

    return success
  }
    

  handleCreateNewSideEffect = () => {
    trackGoogleAnalyticsEvent('AddButton - Medication', true)
    if (!this.validate(true)) {
      return
    }

    if (!this.handleIsScreenValid()) {
      return
    }

    const {
      sideEffect,
      fromDateMoment,
      toDateMoment,
      unitCount,
      toolName,
      timeUnitName,
      notSure,
      drugs,
      timePeriodInputValue,
      timeElName,
      selectedMedicinePZN,
      selectedMedicineATC,
      selectedMedicineManufacturer
    } = this.state

    let currentSubstepIndex = this.state.currentSubstepIndex + 1

    this.setState({ currentSubstepIndex: currentSubstepIndex })

    let formData = {
      name: !sideEffect ? '' : sideEffect,
      codes: {
        pzn: selectedMedicinePZN,
        atc: selectedMedicineATC
      },
      manufacturer: selectedMedicineManufacturer,
      dose: {
        unit: convertUnitGermanToEn(toolName),
        amount: !isNaN(parseInt(unitCount, 10)) ? parseInt(unitCount, 10) : unitCount,
        interval: convertIntervalGermanToEn(timeUnitName)
      },
      startDate: fromDateMoment && fromDateMoment.isValid() ? fromDateMoment.format('YYYY-MM-DD') : false,
      endDate: toDateMoment && toDateMoment.isValid() ? toDateMoment.format('YYYY-MM-DD') : false,
      notSure: notSure,
      timePeriodInputValue: timePeriodInputValue,
      timeElName: timeElName
    }

    this.state.drugs.push(formData)
    this.setState({
      drugs,
      fromDateMoment: null,
      toDateMoment: null,
      sideEffect: '',
      selectedMedicinePZN: '',
      selectedMedicineATC: '',
      selectedMedicineManufacturer: '',
      notSure: false,
      unitCount: '',
      timeUnitName: defaultNonOption,
      toolName: defaultNonOption,
      timePeriodInputValue: '',
      dateInputToValue: '',
      dateInputFromValue: '',
      timeElName: defaultNonOption,
      title: 'Welches Medikament nehmen Sie ein?'
    })

    trackGoogleAnalyticsEvent('Add - Medication', true)

  }

  handleNext = () => {
    if (!this.validate(false)) {
      return
    }

    let {
      sideEffect,
      fromDateMoment,
      toDateMoment,
      notSure,
      drugs,
      currentSubstepIndex,
      timePeriodInputValue,
      timeElName,
      unitCount,
      toolName,
      timeUnitName,
      selectedMedicinePZN,
      selectedMedicineATC,
      selectedMedicineManufacturer,
      countrySelected
    } = this.state

    let screenIsValid = this.handleIsScreenValid()
    let buffer = this.props.formData ? this.state : {}
    let formData = {
      name: !sideEffect ? '' : sideEffect,
      dose: {
        unit: convertUnitGermanToEn(toolName),
        amount: !isNaN(parseInt(unitCount, 10)) ? parseInt(unitCount, 10) : unitCount,
        interval: convertIntervalGermanToEn(timeUnitName)
      },
      codes: {
        pzn: selectedMedicinePZN,
        atc: selectedMedicineATC
      },
      manufacturer: selectedMedicineManufacturer,
      startDate: fromDateMoment && fromDateMoment.isValid() ? fromDateMoment.format('YYYY-MM-DD') : false,
      endDate: toDateMoment && toDateMoment.isValid() ? toDateMoment.format('YYYY-MM-DD') : false,
      notSure: notSure,
      timePeriodInputValue: timePeriodInputValue,
      timeElName: timeElName
    }

    // cycle through substep items
    if (typeof buffer.drugs !== 'undefined' && currentSubstepIndex < buffer.drugs.length - 1) {
      currentSubstepIndex += 1

      // @ToDo: reafctor using lodash _.get
      let startDateMoment = moment(buffer.drugs[currentSubstepIndex]['startDate'], 'YYYY-MM-DD').isValid() ? moment(buffer.drugs[currentSubstepIndex]['startDate'], 'YYYY-MM-DD').format('YYYY-MM-DD') : false
      let endDateMoment = moment(buffer.drugs[currentSubstepIndex]['endDate'], 'YYYY-MM-DD').isValid() ? moment(buffer.drugs[currentSubstepIndex]['endDate'], 'YYYY-MM-DD').format('YYYY-MM-DD') : false
      let name = buffer.drugs[currentSubstepIndex]['name']
      let notSure = buffer.drugs[currentSubstepIndex]['notSure']
      let timePeriodInputValue = buffer.drugs[currentSubstepIndex]['timePeriodInputValue']
      let timeElName = buffer.drugs[currentSubstepIndex]['timeElName']
      let unitCount = buffer.drugs[currentSubstepIndex]['dose']['amount']
      let toolName = buffer.drugs[currentSubstepIndex]['dose']['unit']
      let timeUnitName = buffer.drugs[currentSubstepIndex]['dose']['interval']
      const pzn = gv(buffer, `drugs.${currentSubstepIndex}.codes.pzn`, '')
      const atc = gv(buffer, `drugs.${currentSubstepIndex}.codes.atc`, '')
      const manufacturer = gv(buffer, `drugs.${currentSubstepIndex}.manufacturer`, '')

      if (screenIsValid) { drugs[currentSubstepIndex - 1] = formData }

      this.setState({
        currentSubstepIndex: currentSubstepIndex,
        toDateMoment: endDateMoment === false ? null : moment(endDateMoment, 'YYYY-MM-DD'),
        fromDateMoment: startDateMoment === false ? null : moment(startDateMoment, 'YYYY-MM-DD'),
        sideEffect: name,
        selectedMedicinePZN: pzn,
        selectedMedicineATC: atc,
        selectedMedicineManufacturer: manufacturer,
        notSure: notSure,
        timePeriodInputValue: timePeriodInputValue,
        timeElName: timeElName,
        unitCount: unitCount,
        toolName: toolName,
        timeUnitName: timeUnitName,
        drugs
      })
    } else {
      // go to next big step
      if (screenIsValid) {
        drugs[currentSubstepIndex] = formData
        trackGoogleAnalyticsEvent('Add - Medication', true)
      }

      this.setState({ drugs }, () => {
        trackGoogleAnalyticsEvent('Submit - Medication', true)
        this.props.onSetNextStep({drugs: this.state.drugs, country: countrySelected})
      })
    }

    // clear state in case you came back from edit
    this.props.isBackToEditReset()
  }

  handleBack = () => {
    let buffer = this.props.formData ? this.state : {}
    let currentSubstepIndex = this.state.currentSubstepIndex - 1
    let screenIsValid = ((this.state.notSure !== false || this.state.fromDateMoment !== null) && this.state.sideEffect.length > 0)

    let { sideEffect, fromDateMoment, toDateMoment, notSure, drugs, selectedMedicinePZN, selectedMedicineATC, selectedMedicineManufacturer } = this.state

    // verfica daca sunt substeps si daca sunt ...
    if (currentSubstepIndex > -1) {
      // set vales for current screen from the props
      let endDate = moment(buffer.drugs[currentSubstepIndex]['endDate'], 'YYYY-MM-DD').isValid() ? moment(buffer.drugs[currentSubstepIndex]['endDate'], 'YYYY-MM-DD').format('YYYY-MM-DD') : false
      let startDate = moment(buffer.drugs[currentSubstepIndex]['startDate'], 'YYYY-MM-DD').isValid() ? moment(buffer.drugs[currentSubstepIndex]['startDate'], 'YYYY-MM-DD').format('YYYY-MM-DD') : false
      let name = buffer.drugs[currentSubstepIndex]['name']
      let notSure = buffer.drugs[currentSubstepIndex]['notSure']
      let unitCount = buffer.drugs[currentSubstepIndex]['dose']['amount']
      let timeUnitName = buffer.drugs[currentSubstepIndex]['dose']['interval']
      let toolName = buffer.drugs[currentSubstepIndex]['dose']['unit']
      let timePeriodInputValue = buffer.drugs[currentSubstepIndex]['timePeriodInputValue']
      let timeElName = buffer.drugs[currentSubstepIndex]['timeElName']
      const pzn = gv(buffer, `drugs.${currentSubstepIndex}.codes.pzn`)
      const atc = gv(buffer, `drugs.${currentSubstepIndex}.codes.atc`, '')
      const manufacturer = gv(buffer, `drugs.${currentSubstepIndex}.manufacturer`, '')

      // update state from the previous screen
      let previousScreenState = {
        name: this.state.sideEffect,
        codes: {
          pzn: selectedMedicinePZN,
          atc: selectedMedicineATC
        },
        manufacturer: selectedMedicineManufacturer,
        startDate: fromDateMoment && fromDateMoment.isValid() ? fromDateMoment.format('YYYY-MM-DD') : false,
        endDate: toDateMoment && toDateMoment.isValid() ? toDateMoment.format('YYYY-MM-DD') : false,
        notSure: this.state.notSure,
        dose: {
          amount: this.state.unitCount,
          interval: convertIntervalGermanToEn(this.state.timeUnitName),
          unit: convertUnitGermanToEn(this.state.toolName)
        },
        timePeriodInputValue: this.state.timePeriodInputValue,
        timeElName: this.state.timeElName

      }

      if (screenIsValid) { drugs[currentSubstepIndex + 1] = previousScreenState }

      this.setState({
        currentSubstepIndex: currentSubstepIndex,
        toDateMoment: endDate ? moment(endDate, 'YYYY-MM-DD') : null,
        fromDateMoment: startDate ? moment(startDate, 'YYYY-MM-DD') : null,
        sideEffect: name,
        selectedMedicinePZN: pzn,
        selectedMedicineATC: atc,
        selectedMedicineManufacturer: manufacturer,
        notSure: notSure,
        unitCount: buffer.drugs[currentSubstepIndex]['dose']['amount'],
        timeUnitName: convertIntervalEnToGerman(buffer.drugs[currentSubstepIndex]['dose']['interval']),
        toolName: convertUnitEnToGerman(buffer.drugs[currentSubstepIndex]['dose']['unit']),
        timePeriodInputValue: timePeriodInputValue,
        dateInputToValue: endDate,
        dateInputFromValue: startDate,
        timeElName: timeElName,
        drugs
      })
    } else {
      this.props.onSetBackStep(drugs)
    }

    // clear state in case you came back from edit
    this.props.isBackToEditReset()
  }

  handleBackToOverview = () => {
    if (!this.validate(false)) {
      return
    }

    this.props.onSetCurrentStep(1)
    this.handleNext()
    this.props.onSetCurrentStep(8)
    this.props.onSetBackToEdit()
  }

  handleSimpleTimeUnitOpen = event => {
    this.setState({ timeEl: event.currentTarget })
  }

  handleSimpleTimeUnitClose = item => {
    this.setState({ timeEl: null, timeElName: item !== 'close' ? item : this.state.timeElName }, function () {
      if (this.state.timePeriodInputValue) {
        this.handleTimeMultiplicator()
      }
    })
  }

  handleTimeMultiplicator = () => {
    const timeElName = this.state.timeElName
    const timePeriodInputValue = this.state.timePeriodInputValue

    let multiplicator = 1

    if (timeElName) {
      switch(timeElName) {
        case 'Tag(en)':
          multiplicator = 1
          break
        case 'Woche(n)':
          multiplicator = 7
          break
        case 'Monat(en)':
          multiplicator = 30
          break
        case 'Jahr(en)':
          multiplicator = 365
          break
        default:
          this.setState({ fromDateMoment: null, toDateMoment: null })
          return
      }
    }

    let inputValueComputed = timePeriodInputValue ? moment(new Date(), 'YYYY-MM-DD').add(-multiplicator * parseInt(timePeriodInputValue, 10), 'day') : null
    this.setState({ fromDateMoment: inputValueComputed, toDateMoment: null })
  }

  handleSimpleTimeUnitBlur = event => {
    this.setState({ timePeriodInputValue: event.currentTarget.value }, function () {
      if (this.state.timePeriodInputValue) {
        this.handleTimeMultiplicator()
      }
    })
  }

  handleNativeToDateInputChange = event => {
    this.setState({ dateInputToValue: event.currentTarget.value, toDateMoment: isDateInputSupported() ? moment(event.currentTarget.value, 'YYYY-MM-DD') : moment(event.currentTarget.value, 'DD.MM.YYYY') })
  }

  handleNativeFromDateInputChange = event => {
    this.setState({ dateInputFromValue: event.currentTarget.value, fromDateMoment: isDateInputSupported() ? moment(event.currentTarget.value, 'YYYY-MM-DD') : moment(event.currentTarget.value, 'DD.MM.YYYY') })
  }

  handleNativeFromDateInputBlur = event => {
    this.setState({ minToDate: event.currentTarget.value })
  }

  handleSimpleTimeUnitChange = event => {
    const pattern = /^(0?[1-9]|[1-9][0-9])$/;
    if (event.currentTarget.value === '' || pattern.test(event.currentTarget.value)) {
      this.setState({ timePeriodInputValue: event.currentTarget.value }, () => {
        this.handleTimeMultiplicator()
      })
    }
  }

  render () {
    const {
      fromDateMoment,
      toDateMoment,
      notSure,
      sideEffect,
      toolEL,
      timeUnitEL,
      toolName,
      timeUnitName,
      unitCount,
      medicines,
      drugs,
      isBackToEdit,
      currentSubstepIndex,
      timeEl,
      timeElName,
      timePeriodInputValue,
      dateInputFromValue,
      dateInputToValue,
      minToDate,
      hasActiveValidation,
      firstEffectStartDate,
      isInputValid,
      hasTimeConflictError
    } = this.state
    

    let numberOfDrugsToList = 3
    let drugsSliced = drugs.slice(0, numberOfDrugsToList)
    return (
      <div>
        <StepWrapper>
          {
            drugs.length > 0 &&
            <ItemsList
              color={this.props.theme.palette.primary.main}
              textColor={this.props.theme.palette.text.primary}>
              <p>Bisher eingetragen</p>
              <div>{
                <ul>
                  {drugsSliced.map((el, index) => {
                    return <li key={index}>{el.name} {el.startDate ? el.startDate : '?'} - {el.endDate ? el.endDate : 'k.A.'}</li>
                  })}
                  {
                    drugs.length > numberOfDrugsToList &&
                    <li style={{fontStyle: "italic"}}>Anzahl weitere Einträge: {drugs.length - numberOfDrugsToList}</li>
                  }
                </ul>
              }</div>
            </ItemsList>
          }
          <MedicationsInput
            items={medicines}
            addedMedicines={drugs}
            onClear={this.handleClearSearchItem}
            onSearch={this.handleAddSearchItem}
            onSelect={this.handleSelectItem}
            onCountryChange={this.handleCountryChange}
            title={this.state.title}
            placeholder={'Geben Sie den Namen des Medikamentes ein'}
            value={sideEffect}
            maxLength="200"
            autoHighlight={false}
            searchId={'medikament'}
            hasActiveValidation={hasActiveValidation}
            tooltipId="medication"
            tooltipText={'Bitte geben Sie als erstes das Medikament ein, welches Sie für Ihre Nebenwirkung verdächtigen. Wenn Sie weitere Medikamente nehmen, klicken Sie bitte auf “+ Ich nehme weitere Medikamente” und tragen Sie diese zusätzlich ein.'}
            errorText={sideEffect != '' ? ( validationRegex.stringWithoutCommaSeparators.test(String(sideEffect)) ? '' : 'Please enter the name of your medication. You can add more side effects by pressing the plus below.' ) : 'Please enter the name of your medication.'}
            isInputValid={isInputValid}
          />
          <NativePicker
            fromDateMoment={fromDateMoment}
            notSure={notSure}
            onCheckBox={this.handleCheckBox}
            onSimpleTimeUnitClose={this.handleSimpleTimeUnitClose}
            onSimpleTimeUnitOpen={this.handleSimpleTimeUnitOpen}
            onSimpleTimeUnitBlur={this.handleSimpleTimeUnitBlur}
            color={timeElName === defaultNonOption ? this.props.theme.palette.primaryGrey : this.props.theme.palette.primary.main}
            onSimpleTimeUnitChange={this.handleSimpleTimeUnitChange}
            onHandleNativeFromDateInputChange={this.handleNativeFromDateInputChange}
            onHandleNativeFromDateInputBlur={this.handleNativeFromDateInputBlur}
            onHandleNativeToDateInputChange={this.handleNativeToDateInputChange}
            onSetFromDate={this.handleFromDate}
            onSetToDate={this.handleToDate}
            title={<Trans>How long have you been taking this?</Trans>}
            fromCaption={ 'Erstmalig eingenommen am' }
            toCaption={ 'Abgesetzt am (optional)' }
            timeframeCaption="Einnahme seit ca."
            toDateMoment={toDateMoment}
            timeEl={timeEl}
            timeElName={timeElName}
            timePeriodInputValue={timePeriodInputValue}
            dateInputFromValue={dateInputFromValue}
            dateInputToValue={dateInputToValue}
            minToDate={minToDate}
            errorText={ drugs.length > 0 ? '' : 'Tag der ersten Einname des Medikamentes.' }  
            hasTimeConflictError = {hasTimeConflictError}         
            timeConflictErrorText={(      
              fromDateMoment !== null
              ) ? (
                (
                  drugs.length === 0 &&
                  firstEffectStartDate < fromDateMoment 
                ) ? ('Der Einnahmezeitraum des verdächtigen Medikaments muss vor dem Auftreten der Nebenwirkungen liegen.') 
                : 
                ((toDateMoment !== null && fromDateMoment > toDateMoment) ? ('Das Startdatum muss vor dem Enddatum liegen.')
                :
                ((moment() < fromDateMoment) ? ('Das Datum kann nicht in der Zukunft liegen') : ''))) : ''
              }
            hasActiveValidation={hasActiveValidation}
            tooltipId="medicationRange"
            tooltipFromText={ 'Wählen Sie den ersten Tag aus, an dem Sie mit der Einnahme begonnen haben.' }
            tooltipToText={'Falls Sie das Medikament immer noch einnehmen, lassen Sie das Feld bitte frei.'}
          />
          {<HowOften
            onChangeUnitCount={this.handleChangeUnitCount}
            onTimeUnitClose={this.handleTimeUnitClose}
            onTimeUnitOpen={this.handleTimeUnitOpen}
            onToolClose={this.handleToolClose}
            onToolOpen={this.handleToolOpen}
            timeUnitEL={timeUnitEL}
            timeUnitList={timeUnitList}
            timeUnitName={timeUnitName}
            toolEL={toolEL}
            toolList={toolList}
            toolName={toolName}
            unitCount={unitCount}
            howOftenId={'how-often-id'}
        /> }
          <div onClick={this.handleCreateNewSideEffect} style={{padding: '0.5rem 0 1rem', marginTop: '0'}}>
            <MedButton
              step={2}
              variant="contained"
              themeColor={this.props.theme.palette.primary.main}
            ></MedButton>
            <AddMoreButton
              item="Ich nehme weitere Medikamente"
              themeColor={this.props.theme.palette.primary.main}
              className={ '' }
            ></AddMoreButton>
          </div>
          {
            isBackToEdit !== '' &&
            <OverviewButton
              onClick={this.handleBackToOverview}
              step={2}
              themeColor={this.props.theme.palette.primary}
              variant="contained"
            >
              Zurück zur Übersicht
            </OverviewButton>
          }
        </StepWrapper>
        <BottomBtnGroup>
          <FlexButtons>
            <BackAnchor
              onClick={this.handleBack}
              title='back'
              variant='contained'
              themecolor={this.props.theme.palette.primary.main}
            >
              ◄ Zurück
            </BackAnchor>
            <ButtonItem
              color="primary"
              onClick={this.handleNext}
              title='next'
              variant='contained'
              id='next' >
              Weiter
            </ButtonItem>
          </FlexButtons>
        </BottomBtnGroup>
      </div>
    )
  }
}

MedicationsStep.propTypes = {
  onSetBackStep: PropTypes.func.isRequired,
  onSetNextStep: PropTypes.func.isRequired
}

export default withTheme(MedicationsStep)
