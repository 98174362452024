import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import { MedTextInputContainerFollowup } from './styles'
import * as palette from '../../styles/palette'

const MedTextInputFollowup = ({ opinionTxt, placeholder, onSetText, textareaId, theme }) => {
  return (
    <MedTextInputContainerFollowup
      borderColor={palette.primaryInputBorder}
      focusColor={palette.primaryGrey}
      id={textareaId}
      lightColor={theme.palette.primary.light}
      mainColor={theme.palette.primary.main}
      onChange={onSetText}
      placeholder={placeholder}
      value={opinionTxt}
      maxLength="20"
    />
  )
}

MedTextInputFollowup.propTypes = {
  onSetText: PropTypes.func.isRequired,
  opinionTxt: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  textareaId: PropTypes.any,
  theme: PropTypes.any
}

export default withTheme(MedTextInputFollowup)
