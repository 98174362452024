import styled from 'styled-components'
import CheckIcon from '@material-ui/core/SvgIcon/SvgIcon'
import * as palette from '../../../styles/palette'
import Typography from '@material-ui/core/Typography'

export const Container = styled.div`
  text-align: center;
  padding-top: 6.7rem;
  position: relative;
`

export const CheckIconItem = styled(CheckIcon)`
  background-color: ${props => props.primaryColor};
  color: ${palette.white};
  fill: ${props => props.primaryColor};
  border-radius: 50%;
  padding: 1.2rem;
  font-size: 40px !important;
`

export const ManyThanks = styled(Typography)`
  > span {
    color: ${props => props.primaryColor};
    font-size: 2.6rem;
    line-height: 1.8rem;
    font-weight: 800;
    margin: 3rem 0;
    display: inline-block;
  }
`

export const Description = styled(Typography)`
  &&& {
    color: ${palette.darkGrey};
    font-size: 1.8rem;
    line-height: 2.6rem;
    text-align: center;
  }
`

export const ThankYouWishes = styled(Typography)`
  span {
    color: ${props => props.primaryColor};
    font-size: 1.6rem;
    font-weight: bold;
  }
`
