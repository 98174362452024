import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { withTheme } from '@material-ui/core/styles'
import { StyledSelect, StyledText } from './styles'
import * as palette from '../../styles/palette'
// TODO: 232 merge this with the other dropdown because it's simpler and have better structure & we had a problem with the index while trying to render our dropdown component in the followup app
const DropDownMenu = ({
  dropdownList,
  onChange,
  selectedValue,
  color,
  width,
  theme,
  index
}) => {
  let themingColor = {
    lightColor: theme.palette.primary.light,
    mainColor: theme.palette.primary.main,
    borderColor: palette.primaryInputBorder,
    focusColor: palette.primaryGrey,
    greyColor: palette.primaryGrey
  }
  return (
    <div>
      <StyledSelect
        key={index}
        onChange={(event) => onChange(event.target.value)}
        style={{ 'minWidth': width || '125px', color: color }}
        value={selectedValue}
        {...themingColor}
      >
        {dropdownList.map((item) => <MenuItem value={item.value}><StyledText>{item.label }</StyledText></MenuItem>)}
      </StyledSelect>
    </div>
  )
}

DropDownMenu.propTypes = {
  onChange: PropTypes.func.isRequired,
  dropdownList: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  color: PropTypes.any
}

export default withTheme(DropDownMenu)
