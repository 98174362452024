/**
 * Push event to GTM data layer.
 *
 * @param {string} event
 * @param {object} eventData
 */

import React from 'react'
import { getStringParamFromURL } from 'lib/functions'
import config from '../config'

const isTrackingActivated = getStringParamFromURL(window.location.search, 'trackingEnabled')

class Hotjar extends React.Component {

  componentDidMount() {

    if (isTrackingActivated === 'true' && typeof config.HOTJAR_ID !== 'undefined' && typeof config.HOTJAR_SV !== 'undefined') {
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:config.HOTJAR_ID,hjsv:config.HOTJAR_SV};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }

  }

  render () { return null; }

}

export { Hotjar }
