import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { withTheme } from '@material-ui/core/styles'

import {
  MenuList,
  ArrowDropDownIconItem,
  Item,
  ItemTitle
} from './styles'

import MenuBtn from 'lib/elements/MenuBtn'
import * as palette from '../../styles/palette'

const DropDownMenu = ({
                        anchorEl,
                        dropdownList,
                        onHandleClose,
                        menuId,
                        buttonId,
                        defaultName,
                        itemName,
                        onOpen,
                        isJson,
                        selectedIndex,
                        marginRight,
                        color,
                        width,
                        hasError,
                        theme
                      }) => {
  let elWidth = {
    'width': `${document.getElementById(buttonId) && document.getElementById(buttonId).offsetWidth}px`,
    'color': theme.palette.text.primary
  };
  return (
    <div style={{ marginRight: marginRight || '0', maxWidth: '100%' }}>
      <MenuBtn
        aria-haspopup="true"
        aria-owns={anchorEl ? menuId : null}
        id={buttonId}
        isdefault={itemName === defaultName ? itemName : null}
        onClick={onOpen}
        style={{ minWidth: '125px', color:color}} 
        bordercolor={palette.primaryInputBorder}
        focuscolor={theme.palette.primary.main}
        errorcolor={theme.palette.error.main}
        className={hasError ? 'error' : null}
      >
        <span>{itemName}</span>
        <ArrowDropDownIconItem color={palette.darkGray} />
      </MenuBtn>
      <MenuList
        anchorEl={anchorEl}
        id={menuId}
        onClose={() => onHandleClose('close')}
        open={Boolean(anchorEl)}
      >
        {
          !isJson
            ? dropdownList.map((item, index) => <MenuItem 
              style={elWidth}
              selected={index === selectedIndex} 
              key={index} 
              onClick={() => onHandleClose(item)}>{item}</MenuItem>)
            : dropdownList.map((item, idx) => (
              <Item key={idx}>
                <ItemTitle>{item.title}</ItemTitle>
                {item.items.map((subItem, subItemIdx) => <MenuItem 
                style={elWidth}
                selected={idx === selectedIndex} 
                key={subItemIdx} 
                onClick={() => onHandleClose(subItem)}>{subItem}</MenuItem>)}
              </Item>
            ))
        }
      </MenuList>
    </div>
  )
}

DropDownMenu.propTypes = {
  anchorEl: PropTypes.object,
  dropdownList: PropTypes.array.isRequired,
  onHandleClose: PropTypes.func.isRequired,
  menuId: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  defaultName: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
  isJson: PropTypes.bool.isRequired,
  color: PropTypes.any
}

export default withTheme(DropDownMenu)
