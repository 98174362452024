import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    width: '2.33333em',
    height: '1.5em',
    verticalAlign: 'middle'
  }
}

function CountryFlag ({classes, code}) {
  if (code === 'DE') {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.2 2.4" className={classes.root}><defs/><path d="M0 0h3.2v2.4H0z"/><path fill="#d00" d="M0 .8h3.2v1.6H0z"/><path fill="#ffce00" d="M0 1.6h3.2v.8H0z"/></svg>
  } else if (code === 'AT') {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" className={classes.root}><defs/><path fill="#ed2939" d="M0 0h640v480H0z"/><path fill="#fff" d="M0 160h640v160H0z"/></svg>
  } else if (code === 'CH') {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" className={classes.root}><defs/><path fill="#d52b1e" d="M0 0h640v480H0z"/><path fill="#fff" d="M170 195h300v90H170z"/><path fill="#fff" d="M275 90h90v300h-90z"/></svg>
  } else {
    return <span></span>
  }
}

CountryFlag.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired
}

export default withStyles(styles)(CountryFlag)
