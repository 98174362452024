import styled from 'styled-components'
import * as palette from 'styles/palette'

export const Secrecy = styled.span`
  font-size: 12px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`

export const Data = styled.span`
  font-size: 12px;
  text-decoration: underline;
  color: ${palette.primaryGrey};
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`