import React, { Component } from 'react'
import * as lodash from 'lodash'
import { withTheme } from '@material-ui/core'
import {
  BackAnchor,
  BottomBtnGroup,
  ButtonItemNext,
  FlexButtons,
  MainContainer,
  ProgressContainer,
  StepWrapper
} from '../../MainPage/styles'
import { StyledHeader, StyledTitle, StyledWord } from '../styles'
import { Info, Chargennummer } from './styles'
import Title from 'lib/elements/Title'
import Container from 'lib/elements/Container'
import MedTextInputFollowup from '../../../components/MedTextInputFollowup'

const backButtonStyle = {
  marginTop: '4.5rem'
}

export class Step3 extends Component {

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  };

  back = e => {
    e.preventDefault()
    this.props.prevStep()
  };

  // Handlers
  handleSetMessageMedikament = (index) => {
    return (event) => {
      this.props.handleAddTextMedikament(index, event.target.value)
    }
  }
  // end of Handlers

  render () {
    const { drugs, answerDrugs } = this.props
    return (
      <Container>
        <MainContainer>
          <StyledHeader><StyledTitle>Der Arzneimittelhersteller hat folgende wichtige Rückfragen zu Ihrer Meldung:</StyledTitle></StyledHeader>
          <ProgressContainer>
            <div>
              <StepWrapper>
                <Title><h3> Chargennummer</h3></Title>
                <Info>Die Chargennummer befindet sich auf der Rückseite der Packung und hat das  Format Ch.-B. 12345ABCD</Info>
                <Title> Wie lautet die Chargennummer ...</Title>
                {
                  <Chargennummer>
                    {drugs && drugs.map((drug, index) => {
                      let textInput = lodash.get(answerDrugs, `[${index}].codes.batchNumber`, lodash.get(drugs, `[${index}].codes.batchNumber`, ''))
                      return [
                        <React.Fragment>
                          <Title>...von <StyledWord>{drug.name}</StyledWord>?</Title>
                          <MedTextInputFollowup
                            onSetText={this.handleSetMessageMedikament(index)}
                            opinionTxt={textInput}
                            placeholder={'z. B. 12345ABCD'}
                            textareaId={'extraComment'}
                          />
                        </React.Fragment>
                      ]
                    })}
                  </Chargennummer>
                }
              </StepWrapper>
              <BottomBtnGroup>
                <FlexButtons>
                  <BackAnchor
                    style={backButtonStyle}
                    onClick={this.back}
                    title='back'
                    variant='contained'
                    themecolor={this.props.theme.palette.primary.main}
                  >◄ Zurück
                  </BackAnchor>
                  <ButtonItemNext
                    color="primary"
                    id='next'
                    onClick={this.continue}
                    title='next'
                    variant='contained'
                  >
                    Weiter
                  </ButtonItemNext>
                </FlexButtons>
              </BottomBtnGroup>
            </div>
          </ProgressContainer>
        </MainContainer>
      </Container>
    )
  }
}

export default withTheme(Step3)
