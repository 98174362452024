import React, { Component } from 'react'
import {
  BackAnchor,
  BottomBtnGroup,
  ButtonItemNext,
  FlexButtons,
  MainContainer,
  StepWrapper
} from '../../MainPage/styles'
import { ProgressContainer, StyledHeader, StyledTitle, StyledWord } from '../styles'
import Container from 'lib/elements/Container'
import Title from 'lib/elements/Title'
import * as lodash from 'lodash'
import { SideEffectStatusElements } from '../../../constants'
import { withTheme } from '@material-ui/core'
import DropDownMenuNew from '../../../components/DropDownMenu-new'
import PropTypes from 'prop-types'

const divStyle = {
  marginLeft: '-42px'
}

const backButtonStyle = {
  marginTop: '4.5rem'
}

export class Step4 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tallEl: null
    }
  };

  componentDidMount () {
    window.scrollTo(0, 0)
  };

  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  };

  back = e => {
    e.preventDefault()
    this.props.prevStep()
  };

  handleEffectChange = (index) => {
    return (item) => {
      this.props.onHandleSideEffect(index, item)
    }
  };

  // render
  render () {
    const { effects, answerEffects } = this.props
    return (
      <Container>
        <MainContainer>
          <StyledHeader><StyledTitle>Der Arzneimittelhersteller hat folgende wichtige Rückfragen zu Ihrer Meldung:</StyledTitle></StyledHeader>
          <ProgressContainer>
            <div>
              <StepWrapper>
                <Title><h3> Ausgang der Nebenwirkung </h3></Title>
                <p> Wie geht es Ihnen heute?</p>
                <div>{
                  <ul style={divStyle}>
                    {effects && effects.map((effect, index) => {
                      let defaultValue = 'unknown'
                      let value = lodash.get(answerEffects, `[${index}].status`, effect.status || 'unknown')
                      return [
                        <React.Fragment key={index}>
                          <Title>...hinsichtlich <StyledWord>{effect.name}</StyledWord>?</Title>
                          <DropDownMenuNew
                            dropdownList={SideEffectStatusElements}
                            onChange={this.handleEffectChange(index)}
                            selectedValue={value}
                            color={value === defaultValue ? this.props.theme.palette.primaryGrey : this.props.theme.palette.primary.main}
                            key={index}
                          />
                        </React.Fragment>
                      ]
                    })}
                  </ul>
                }</div>
              </StepWrapper>
              <BottomBtnGroup>
                <FlexButtons>
                  <BackAnchor
                    style={backButtonStyle}
                    onClick={this.back}
                    title='back'
                    variant='contained'
                    themecolor={this.props.theme.palette.primary.main}>
              ◄ Zurück
                  </BackAnchor>
                  <ButtonItemNext
                    color='primary'
                    id='next'
                    onClick={this.continue}
                    title='next'
                    variant='contained'
                  >
                Weiter
                  </ButtonItemNext>
                </FlexButtons>
              </BottomBtnGroup>
            </div></ProgressContainer></MainContainer>
      </Container>
    )
  }
}

Step4.propTypes = {
  effects: PropTypes.any,
  answerEffects: PropTypes.any,
  onHandleSideEffect: PropTypes.any
}

export default withTheme(Step4)
