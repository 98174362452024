import React from 'react'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import * as palette from 'styles/palette'
import { withTheme } from '@material-ui/core/styles'

import styled from 'styled-components'

const EN = 'en'
const DE = 'de'

const LangButton = styled.button`
  background-color: ${props => props.lang ? props.themeColor : palette.primaryGrey};
  width: 5rem;
  height: 2.5rem;
  color: ${palette.white};
  border-radius: 3px;
  outline: none;
`

const ChangeLanguage = ({ i18n, theme }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <LangButton
        lang={i18n.language === DE}
        onClick={() => i18n.changeLanguage('de')}
        style={{ marginRight: 10 }}
        themeColor={theme.palette.primary.main}
      >
        de
      </LangButton>
      <LangButton
        lang={i18n.language === EN}
        onClick={() => i18n.changeLanguage('en')}
        themeColor={theme.palette.primary.main}
      >
        en
      </LangButton>
    </div>
  )
}

ChangeLanguage.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired
}

// extended main view with translate hoc
export default withTheme(translate('translations')(ChangeLanguage))
