import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'

import DropDownMenu from 'components/DropDownMenu'
import MedTextArea from 'components/MedTextArea'
import Title from 'lib/elements/Title'
import MedButton from 'lib/elements/Button'
import AddMoreButton from 'lib/elements/AddMoreButton'

import _ from 'lodash'

import { trackGoogleAnalyticsEvent } from 'service/tracker'

import {
  BottomBtnGroup,
  ButtonItem,
  FlexButtons,
  BackAnchor,
  ItemsList,
  StepWrapper
} from '../styles'

import {
  defaultNonOption,
  gewohnheitenList,
  gewohnheitenMap,
  gewohnheitAddMore,
  gewohnheitQuestion,
  RisikofaktorenPlaceholder
} from '../../../constants'
import * as palette from '../../../styles/palette'

const isJson = false

class RiskfactorsStep extends React.Component {
  constructor (props) {
    super(props)
    let storedHabits = _.get(props.formData, 'habits.others', [])
    this.state = {
      habitsEL: null,
      habitsName: defaultNonOption,
      opinionTxt: '',
      habits: _.get(props.formData, 'habits.others', []),
      isSetHabits: false,
      currentSubstepIndex: storedHabits.length ? storedHabits.length: 0
    }
  }

  handleHabitsClose = item => {
    this.setState({
      habitsEL: null,
      habitsName: item !== 'close' ? item : this.state.habitsName,
      isSetHabits: item !== 'close' && item !== defaultNonOption
    })
    for (var key in gewohnheitenMap) {
      if (gewohnheitenMap[key] === item) {
        this.setState({ class: key })
      }
    }
  }

  handleHabitsOpen = event => {
    this.setState({ habitsEL: event.currentTarget })
  }

  handleSetOpinionTxt = event => {
    this.setState({ opinionTxt: event.target.value })
  }

  handleCreateOtherHabit = () => {
    trackGoogleAnalyticsEvent('AddButton - Riskfactorstep', true)
    this.setState({ currentSubstepIndex: this.state.currentSubstepIndex + 1, color: '' })
    const { habits, habitsName, opinionTxt} = this.state
    console.log('optionTxt *** description', opinionTxt)
    const newHabit = {
      name: habitsName,
      description: opinionTxt,
      class: this.state.class
    }
    if( newHabit.name !== defaultNonOption){
      habits.push(newHabit)
    }

    if( habits !== defaultNonOption){
      this.setState({ opinionTxt: '', habitsName: defaultNonOption, isSetHabits: false, habits: habits })
    }

  }

  handleNext = () => {
    const { habits, isSetHabits, habitsName, opinionTxt } = this.state
    console.log('optionTxt *** description', opinionTxt)
    const newHabit = {
      name: habitsName,
      description: opinionTxt,
      class: this.state.class
    }

    if (isSetHabits && newHabit.name !== defaultNonOption) { habits.push(newHabit) }

    this.setState({ habits: habits, isSetHabits: false }, () => {
      trackGoogleAnalyticsEvent('Submit - RiskfactorsStep', true)
      this.props.onSetNextStep({ 'others': habits })
    })
  }

  handleSkip = () => {
    trackGoogleAnalyticsEvent('Submit - RiskfactorsStep', true)
    this.props.onSetNextStep(this.state.habits)
  }

  handleBack = () => {
    this.props.isBackToEditReset()
    this.props.onSetBackStep()
  }

  componentDidMount () {
    trackGoogleAnalyticsEvent('PageView - RiskfactorsStep', true)
  }

  render () {
    const {
      habitsEL,
      habitsName,
      opinionTxt,
      isSetHabits,
      habits,
      currentSubstepIndex
    } = this.state

    let disabled=!((habits && habits.length === 0 || habits.length === currentSubstepIndex ) && isSetHabits)
    return (
      <div>
        <StepWrapper>
          {
            habits && habits.length > 0 &&
            <ItemsList
              color={this.props.theme.palette.primary.main}
              textColor={this.props.theme.palette.text.primary}>
              <p>Bisher eingetragen</p>
              <div>{
                <ul>
                  {habits.map(function(el, index){
                    return <li key={index}>{el.name}</li>;
                  })}
                </ul>
              }</div>
            </ItemsList>
          }
          <div>
            <Title>{gewohnheitAddMore}</Title>
            <DropDownMenu
              anchorEl={habitsEL}
              buttonId='habitsEL-btn'
              defaultName={defaultNonOption}
              dropdownList={gewohnheitenList}
              isJson={isJson}
              itemName={habitsName}
              menuId='habitsEL-menu'
              onHandleClose={this.handleHabitsClose}
              onOpen={this.handleHabitsOpen}
            />
          </div>
          <div>
            <div>
              <div>
                <Title isTitleTxt={habitsName !== ''}>{gewohnheitQuestion}</Title>
                <MedTextArea
                  onSetText={this.handleSetOpinionTxt}
                  opinionTxt={opinionTxt}
                  placeholder={RisikofaktorenPlaceholder}
                  textareaId={'habitsQuestion'}
                />
              </div>
              <div onClick={this.handleCreateOtherHabit}>
                <MedButton
                  color="darkGreen"
                  step={4}
                  variant='contained'
                  themeColor={this.props.theme.palette.primary.main}
                  disabled={disabled}
                ></MedButton>
                <AddMoreButton
                  item="Ich habe weitere Risikofaktoren"
                  themeColor={this.props.theme.palette.primary.main}
                  disabled={disabled}
                ></AddMoreButton>
              </div>
            </div>
          </div>
        </StepWrapper>
        <BottomBtnGroup>
          <FlexButtons>
            <BackAnchor
              onClick={this.handleBack}
              title='back'
              variant='contained'
              themecolor={this.props.theme.palette.primary.main}
            >
              ◄ Zurück
            </BackAnchor>
            {!isSetHabits && habits.length === 0 ? <ButtonItem
              color="primary"
              onClick={this.handleSkip}
              title='skip'
              variant='contained'
              id='skip'
            >
              Überspringen
            </ButtonItem> : <ButtonItem
              color="primary"
              onClick={this.handleNext}
              title='next'
              variant='contained'
              id="next"
            >
              Weiter
            </ButtonItem>}
          </FlexButtons>
        </BottomBtnGroup>
      </div>
    )
  }
}

RiskfactorsStep.propTypes = {
  onSetBackStep: PropTypes.func.isRequired,
  onSetNextStep: PropTypes.func.isRequired
}

export default withTheme(RiskfactorsStep)
