import styled from 'styled-components'
import * as palette from 'styles/palette'

export const ProTxt = styled.button`
  border: none;
  background: none;
  opacity: 1;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: ${props => props.primaryColor} !important;
  cursor: pointer;
  outline: none;
  font-family: Nunito, sans-serif;
  &:disabled {
    opacity: 0.25;
  }
`
