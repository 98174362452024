import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import { translate, Trans } from 'react-i18next'

import Title from 'lib/elements/Title'
import DoctorInput from 'components/DoctorInput'
import MedTextArea from 'components/MedTextArea'
import lodash, { debounce } from 'lodash'
import _ from 'lodash'

import { trackGoogleAnalyticsEvent } from 'service/tracker'

import {
  errorMessages,
  validationRegex
} from '../../../constants'

import {
  BottomBtnGroup,
  ButtonItem,
  FlexButtons,
  BackAnchor,
  StepWrapper,
  EmailText,
  IdentText

} from '../styles'

import {
  HelpIcon, 
  ReactTooltipStyled
} from '../../../components/DoctorInput/styles'

import {
  InputContainer
} from './styles'

const hasPriority = true

class EmailStep extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activePriority: '',
      emailTxt: _.get(props.formData, 'contacts.patient.identity.email', ''),
      currentStep: 0,
      oldEL: null,
      oldEmailTxt: _.get(props.formData, 'contacts.reporter.identity.email', ''),
      isChecked: _.get(props.formData, 'extras.isChecked', false),
      isGotoNextBigStep: false,
      hasOldEmailError: false,
      hasEmailError: false,
      loading: false
    }

    if (this.props.isBackToEdit) {
      this.state.isEditSteps = true
      this.props.isBackToEditReset()
    }
  }

  handleChangeEmail = emailTxt => {
    this.setState({ emailTxt }, () => {
      if (this.state.hasEmailError) {
        this.handleChangeEmailBlur(emailTxt)
      }
    })
  }

  handleChangeEmailBlur = emailTxt => {
    if (emailTxt === 'hasPriority') {
      this.setState({ activePriority: '' })
      return
    }
    let emailRegex = validationRegex.email
    this.setState({ hasEmailError: !emailRegex.test(String(emailTxt).toLowerCase()) })
  }

  handleChangeOldEmail = oldEmailTxt => {
    this.setState({ oldEmailTxt }, () => {
      if (this.state.hasOldEmailError) {
        this.handleChangeOldEmailBlur(oldEmailTxt)
      }
    })
  }

  handleChangeOldEmailBlur = oldEmailTxt => {
    if (oldEmailTxt === 'hasPriority') {
      this.setState({ activePriority: '' })
      return
    }
    let emailRegex = validationRegex.email
    this.setState({ activePriority: '', hasOldEmailError: !emailRegex.test(String(oldEmailTxt).toLowerCase()) })
  }

  // This function will be depricated
  componentWillReceiveProps(newProps) {
    if (!newProps.currentHandleState) {
      this.setState({ activePriority: '' })
    }
  }

  /* global */

  handleActivePriority = (e, activePriority) => {
    e.stopPropagation()
    if (this.state.activePriority === '') {
      this.setState({ activePriority })
    } else {
      this.setState({ activePriority: '' })
    }
  }

  handleBlur = () => {
    this.setState({ activePriority: '' })
  }

  validate = () => {
    this.setState({
      hasActiveValidation: true
    })

    let success = true

    if (this.state.oldEmailTxt.length === 0) {
      trackGoogleAnalyticsEvent('ErrorEmpty - EmailStep', true)
      success = false
    }

    if (this.state.hasOldEmailError) {
      trackGoogleAnalyticsEvent('ErrorWrongEmail - EmailStep', true)
      success = false
    }
    
    if (success) {
      this.setState({
        hasActiveValidation: false
      })
    }
    return success
  }

  handleNext = () => {
    if (!this.validate()) {
      return
    }

    trackGoogleAnalyticsEvent('Submit - EmailStep', true)
    this.props.onSetNextStep({
      'isNoDoctor': {
        'reporter': {
          'identity': {
            'email': this.state.oldEmailTxt
          }
        }
      },
      'patient': {
        'identity': {
          'email': this.state.oldEmailTxt
        }
      }
    })
  }

  handleBack = () => {
    this.props.isBackToEditReset()
    this.props.onSetBackStep()
  }

  componentDidMount () {
    trackGoogleAnalyticsEvent('PageView - EmailStep', true)
  }

  render() {
    const {
      activePriority,
      oldEmailTxt,
      hasOldEmailError,
      hasActiveValidation
    } = this.state

    return (
      <div>
        <StepWrapper>
          <InputContainer>
            <Title>An welche E-Mail-Adresse soll Ihre Meldebestätigung geschickt werden?
            <a data-tip data-for='emailTooltip'><HelpIcon /></a>
            <ReactTooltipStyled
              id='emailTooltip'
              type='dark'
              place='top'
              effect='solid'
              scrollHide={false}
            >
              <span>An diese E-Mail-Adresse wird die Eingangsbestätigung Ihrer Meldung versendet. Sofern es wichtige Rückfragen zur Bewertung Ihrer Meldung gibt, erhalten Sie diese automatisch per E-Mail.</span>
            </ReactTooltipStyled>
            </Title>
            <DoctorInput
              activeItem={activePriority}
              themeColor={this.props.theme.palette.primary.main}
              hasPriority={hasPriority}
              itemName={'oldemail'}
              onActivePriority={this.handleActivePriority}
              onBlur={this.handleChangeOldEmailBlur}
              onChangeTxt={this.handleChangeOldEmail}
              placeholderTxt={'beispiel@beispiel.de'}
              errorText={errorMessages.validEmail}
              hasError={hasOldEmailError || oldEmailTxt.length === 0}
              opinionTxt={oldEmailTxt}
              inputId={'emailAddress'}
              type={'email'}
              hasActiveValidation={hasActiveValidation}
            />
            <EmailText color={this.props.theme.palette.text.primary}>
              <span className="icon">🛡</span> Umfassender Datenschutz <span className="icon">🛡</span>
            </EmailText>
            <IdentText color={this.props.theme.palette.text.primary}>
              Damit Ihre Meldung für <strong>zukünftige Beipackzettel</strong> zählt: 
              Die E-Mail wird zur Validierung und bei wichtigen Rückfragen benötigt. 
              Sie wird nicht weitergegeben und <strong>nach der Fallbearbeitung vollständig gelöscht</strong>.
            </IdentText>
          </InputContainer>
        </StepWrapper>
        <BottomBtnGroup>
          <FlexButtons>
            <BackAnchor
              onClick={this.handleBack}
              title='back'
              variant='contained'
              themecolor={this.props.theme.palette.primary.main}
            >
              ◄ Zurück
            </BackAnchor>
            <ButtonItem
              color="primary"
              onClick={this.handleNext}
              title='next'
              variant='contained'
              id="next"
            >
              Weiter
            </ButtonItem>
          </FlexButtons>
        </BottomBtnGroup>
      </div>
    )
  }
}

EmailStep.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onSetBackStep: PropTypes.func.isRequired,
  onSetNextStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSetCurrentStep: PropTypes.func.isRequired
}

export default withTheme(translate('translations')(EmailStep))
