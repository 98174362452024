module.exports = {
  // Primary colors
  darkGreen: '#117982',
  primaryGreen: '#BBD8DB',
  lightGreen: '#12A5A9',
  superLightGreen: '#F3F8F9',
  
  primaryDarkRed: '#EE834F',

  darkGrey: '#7b7b79',
  primaryLightGrey: '#DADADA',
  primaryGrey: 'rgba(0,0,0,0.65)',
  placeHolderTextColor: 'rgba(0,0,0,0.37)',

  primaryInputBorder: '#7b7b79',
  white: '#FFFFFF',
  borderColor: '#dadada',

  //
  stepBorderColor: '#E7F1F2',
  searchBorderColor: '#E0E0E0',
  searchTxtColor: '#4F4F4F',
  dateColor: '#828282',

  // error fields
  errorField: '#E84C4D',

  // secondary
  DarkBlueGreen: '#367780'
}
