import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from 'components/DialogTitle'

import {
  Secrecy, Data
} from './styles'

class SubmitConsentDialog extends React.Component {
  state = {
    open: false
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render () {
    return (
      <React.Fragment >
        <Dialog onClose={this.handleClose} open={this.state.open} onRequestClose={this.handleClose}>
          <DialogTitle theme={this.props.theme} onClose={this.handleClose}>Einwilligungs​erklärung</DialogTitle>
          <DialogContent style={{ color: this.props.theme.palette.text.primary }} >
          <p>
          Ich bin damit einverstanden, dass meine arzneimittelbezogenen Daten, zu welchen auch Informationen über meinen Gesundheitszustand gehören, von Nebenwirkungen.de 
          vertreten durch XO Life GmbH,  Agnes-Pockels-Bogen 1, 80992 München ("XO Life") pseudonymisiert, das heißt unter Angabe einer willkürlich zugewiesenen 
          Fallnummer, an den Arzneimittelhersteller übermittelt, gespeichert und zum Zweck der Meldung von Nebenwirkungen verarbeitet werden. Ferner bin ich damit einverstanden, 
          dass XO Life mich für Status-Aktualisierungen zu meiner Meldung und Rückfragen des Arzneimittelherstellers kontaktieren darf. Ich bin darüber informiert, 
          dass ich jederzeit Einsicht oder schriftlich Auskunft über meine Daten erhalten und selbst entscheiden kann, welche gegebenenfalls gelöscht werden sollen. 
          Die Einwilligung erfolgt freiwillig und kann von mir jederzeit ohne Angabe von Gründen unter der o.g. Adresse oder per E-Mail 
          an <a style={{ color : this.props.theme.palette.primary.main}} href="mailto:datenschutz@nebenwirkungen.de">datenschutz@nebenwirkungen.de</a> widerrufen werden.</p>
          </DialogContent>
        </Dialog>
          <p style={{ fontSize: '12px', color: this.props.theme.palette.text.primary }}>
            Ihre Informationen werden streng nach den Vorgaben der DSGVO verarbeitet. 
            Weitere Informationen zur Verarbeitung Ihrer Daten können Sie unserer <Data as='a' href="https://www.nebenwirkungen.de/datenschutz" target="_blank" rel="noopener">Datenschutzerklärung</Data> entnehmen.
            Mit Ihrem Klick auf "Bestätigen und Meldung abschicken" stimmen Sie der <Secrecy onClick={this.handleOpen}>Einwilligungserklärung</Secrecy> zu. 
            Ihre persönlichen Informationen werden vertraulich behandelt und nicht an den Hersteller weitergegeben.
          </p>
      </React.Fragment>
    )
  }
}

export default SubmitConsentDialog
