import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'
import Paper from '@material-ui/core/Paper'
import * as palette from 'styles/palette'

const ProgressBarContainer = styled.div`
  display: flex;
  flex-flow: justify-content;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  padding: 0 5rem;

  @media (max-width: 600px) {
    box-shadow: none;
  }
`

const Icon = styled.div`
  border: 1px solid ${palette.primaryInputBorder};
  border-radius: 50%;
  box-shadow: none;
  box-sizing: border-box;
  padding-top: 4px;
  width: 30px;
  height: 30px;
  text-align: center;

  svg {
    width: 20px;
    height: 20px;
    
    .st {
      fill: ${palette.primaryInputBorder};
    }
  }


  &.active {
    border-color: ${props => props.theme.palette.primary.main};
    background: ${props => props.theme.palette.primary.main};

    svg {
    
      .st {
        fill: ${props => props.theme.palette.primary.contrastText};
      }
    }
  }
  
`

const Line = styled.div`
  position: relative;
  width: calc((100% - 120px) / 3);
  box-shadow: none;
  height: 7px;

  div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    background: ${props => props.theme.palette.primary.main};
    transition: all 1s;
  }

  &:before {
    display: block;
    content: "";

    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    height: 1px;
    background: ${palette.primaryInputBorder};
  }
`
const ProgressBar = ({ theme, steps }) => {
  return (
    <ProgressBarContainer>
      <Icon theme={theme} className={steps > 1 ? 'active' : null}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 16"><defs/><path class="st" fill-rule="evenodd" d="M3.154.5H.166v9h2.988v-9zm0 12H.166v3h2.988v-3z" clip-rule="evenodd"/></svg>
      </Icon>
      <Line theme={theme}><div style={{ width: steps > 1 ? '100%' : null}} ></div></Line>
      <Icon theme={theme} className={steps > 2 ? 'active' : null}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><defs/><path class="st" d="M2.431 9.29l7.042-7.07A5.975 5.975 0 0113.701.465c1.585 0 3.106.631 4.228 1.755 2.33 2.34 2.33 6.14 0 8.49l-7.042 7.07a5.97 5.97 0 01-8.456 0 6.023 6.023 0 01-1.748-4.245c0-1.592.629-3.118 1.748-4.245zm1.415 1.42a3.98 3.98 0 00-1.036 3.86l5.966-5.98 4.223 4.24 3.516-3.54a3.98 3.98 0 001.172-2.825 3.991 3.991 0 00-1.172-2.825A3.961 3.961 0 0013.7 2.463a3.948 3.948 0 00-2.814 1.177l-7.041 7.07z"/></svg>
      </Icon>
      <Line theme={theme}><div style={{ width: steps > 2 ? '100%' : null}} ></div></Line>
      <Icon theme={theme} className={steps > 3 ? 'active' : null}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21"><defs/><path class="st" fill-rule="evenodd" d="M12.28 2.207a2.07 2.07 0 01-2.06 2.069 2.07 2.07 0 01-2.062-2.07A2.07 2.07 0 0110.22.139a2.07 2.07 0 012.06 2.069zM10.22 5.31c2.915 0 6.068-.31 8.757-1.034l.515 2.069c-1.916.517-4.121.858-6.182 1.034v13.449h-2.06V14.62H9.188v6.207h-2.06V7.379C5.066 7.203 2.861 6.862.945 6.345l.515-2.07C4.151 5 7.303 5.31 10.22 5.31z" clip-rule="evenodd"/></svg>
      </Icon>
      <Line theme={theme}><div style={{ width: steps > 1 ? ((steps - 3) * 20) + '%' : null}} ></div></Line>
      <Icon theme={theme} className={steps > 8 ? 'active' : null}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 19"><defs/><path class="st" fill-rule="evenodd" d="M13.319 5.086a4.129 4.129 0 01-4.121 4.138 4.129 4.129 0 01-4.122-4.138A4.129 4.129 0 019.198.948a4.129 4.129 0 014.121 4.138zm-2.06 0a2.07 2.07 0 00-2.061-2.069 2.07 2.07 0 00-2.061 2.07 2.07 2.07 0 002.06 2.068 2.07 2.07 0 002.061-2.069zM3.015 15.431c.206-.652 2.647-1.738 5.11-2.007l2.102-2.069a9.58 9.58 0 00-1.03-.062c-2.751 0-8.243 1.386-8.243 4.138V17.5h9.273l-2.06-2.069H3.015zm10.787-.341L19.09 9.74 20.53 11.2l-6.728 6.817-3.575-3.62 1.442-1.46 2.133 2.153z" clip-rule="evenodd"/></svg>
      </Icon>
    </ProgressBarContainer>
  )
  }

ProgressBar.propTypes = {
  theme: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired
}

export default withTheme(ProgressBar)
