import styled from 'styled-components'
import * as palette from 'styles/palette'

const MedButton = styled.button`
  text-transform: none;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  border: none;
  margin: 0px;
  padding: 0px;
  color: ${palette.white};
  background-color: ${props => props.themeColor};
  margin: 0;
  margin-top:  ${props => props.step === 1 ? '0.5rem' : props.step === 2 ? '2rem' : props.step === 3 ? '23.5rem' : props.step === 3.5 ? '2rem' : props.step === 4 ? '0.8rem' : '1rem'};
  margin-right: auto;
  &:hover{
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.25;
    color: #fff;
    background-color: ${props => props.themeColor};
  }

  &::after {
    content: "+";
    font-size: 20px;
    font-weight: bold;
    outline: none
  }
`

export default MedButton
