import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
// todo 232 merge this with the other dropdown because it's simpler and have better structure & we had a problem with the index while trying to render our dropdown component in the followup app
import {
  Container,
  HowOftenContainer,
  UnitInput,
  ProTxt
} from './styles'

import DropDownMenuNew from '../DropDownMenu-new'
import * as palette from '../../styles/palette'

const HowOftenFollowup = ({

  doseUnitList,
  doseTimeList,
  doseUnitName,
  doseUnitNameColor,
  onDoseUnitChange,
  howOftenId,
  onDoseTimeChange,
  doseTimeName,
  doseTimeNameColor,
  unitCount,
  onChangeUnitCount,
  theme
}) => {
  return (
    <Container>
      <HowOftenContainer>
        <UnitInput
          id={howOftenId}
          onChange={onChangeUnitCount}
          placeholder=''
          type='number'
          value={unitCount}
          lightColor={theme.palette.primary.light}
          mainColor={theme.palette.text.primary}
          borderColor={palette.primaryInputBorder}
          focusColor={theme.palette.primary.main}
          min='1'
          max='9999'
          step='1'
        />
        <DropDownMenuNew
          dropdownList={doseUnitList}
          onChange={onDoseUnitChange}
          selectedValue={doseUnitName}
          color={doseUnitNameColor}
        />
        <DropDownMenuNew
          dropdownList={doseTimeList}
          onChange={onDoseTimeChange}
          selectedValue={doseTimeName}
          color={doseTimeNameColor}
        />

      </HowOftenContainer>
    </Container>
  )
}

HowOftenFollowup.propTypes = {
  onChangeUnitCount: PropTypes.func.isRequired,
  doseUnitName: PropTypes.string,
  unitCount: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  doseUnitList: PropTypes.string.isRequired,
  doseTimeList: PropTypes.string.isRequired,
  onDoseUnitChange: PropTypes.string.isRequired,
  howOftenId: PropTypes.string.isRequired,
  onDoseTimeChange: PropTypes.string.isRequired,
  doseTimeName: PropTypes.string.isRequired
}

HowOftenFollowup.defaultProps = {
  doseUnitName: '',
  timeUnitList: [],
  timeUnitName: ''
}

export default withTheme(HowOftenFollowup)
