import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.scss'
import 'styles/global'

import React from 'react'
import { render } from 'react-dom'
import Root from './components/Root'

const root = <Root />

render(root, document.getElementById('root'))
