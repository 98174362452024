import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'

import { MedTextAreaContainerFollowup } from './styles'
import * as palette from '../../styles/palette'

const MedTextAreaFollowup = ({ opinionTxt, placeholder, onSetText, textareaId, theme }) => {
  return (
    <MedTextAreaContainerFollowup
      borderColor={palette.primaryInputBorder}
      focusColor={palette.primaryGrey}
      id={textareaId}
      lightColor={theme.palette.primary.light}
      mainColor={theme.palette.primary.main}
      onChange={onSetText}
      placeholder={placeholder}
      value={opinionTxt}
      maxLength="200"
    />
  )
}

MedTextAreaFollowup.propTypes = {
  onSetText: PropTypes.func.isRequired,
  opinionTxt: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  textareaId: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired
}

export default withTheme(MedTextAreaFollowup)
