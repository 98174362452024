import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import { trackGoogleAnalyticsEvent } from 'service/tracker'

import {
  Container,
  LogoContainer,
  StatusImage,
  Title,
  Description,
  Content,
  ButtonGroup,
  ButtonItem,    
  BottomBtnGroup,
  BackAnchor,
  FlexButtons
} from './styles'

const SeverityMapping = {
    serious: {
        titleText: '– Ihre Nebenwirkungsmeldung',
        statusText: ['ist ',<strong>schwerwiegend!</strong>],
        descriptionText: ['Wenden Sie sich ',<strong>bitte schnellstmöglich</strong>,' an Ihren ',<strong>Arzt oder Apotheker.</strong>],
        image: 'serious_600.jpg',
        subText: ['Wussten Sie, dass ',<strong>weniger als 1%</strong>,' aller Nebenwirkungen gemeldet werden? ',<strong>Tragen Sie jetzt selbst zu sicheren Medikamenten bei!</strong>],
        buttonText: 'Jetzt Nebenwirkung melden!',
    },
    notSerious: {
        titleText: '– Ihre Nebenwirkungsmeldung',
        statusText: ['ist ',<strong>nicht schwerwiegend!</strong>],
        descriptionText: 'Klären Sie Ihre Symptome am besten mit Ihrem Arzt oder Apotheker ab.',
        image: 'not-serious_600.jpg',
        subText: ['Wussten Sie, dass ',<strong>weniger als 1%</strong>,' aller Nebenwirkungen gemeldet werden? ',<strong>Tragen Sie jetzt selbst zu sicheren Medikamenten bei!</strong>],
        buttonText: 'Jetzt Nebenwirkung melden!'
    },
    notKnown: {
        titleText: ['– Ihre ',<strong>Nebenwirkungsmeldung</strong>],
        statusText: ['konnte noch ',<strong>nicht automatisch</strong>,' überprüft werden.'],
        descriptionText: '',
        image: 'no-results-auto_600.jpg',
        subText: 'Um diese bearbeiten zu können, bitten wir Sie diese vertraulich zu melden. Wir informieren Sie anschließend über das Ergebnis.',
        buttonText: 'Jetzt Nebenwirkung melden!'
    }
}

const getReportSeverity = (effects) => {
    let seriousCount = 0;
    let unknownCount = 0;

    effects.map((effect) => {
        if(effect.isSerious === true) {
            seriousCount++;
        }
        if(effect.isSerious === '') {
            unknownCount++;
        }
    })

    // At least one side effect entered is serious then the whole report is serious
    if(seriousCount) {
        return 'serious'
    }
    // No entered side effect is serious and no freetext was entered → not serious
    if(seriousCount===0 && unknownCount===0){
        return 'notSerious'
    }
    // Free text was entered and no additional entered side effect is serious → need evaluation
    if(seriousCount===0 && unknownCount > 0){
        return 'notKnown'
    }
}

const SeverityStep = (props) => {
    const severity = getReportSeverity(props.formData.effects)
    let isUnknown = severity === 'notKnown'
    useEffect(() => {
        trackGoogleAnalyticsEvent('PageView - Severity', true)
        trackGoogleAnalyticsEvent('ReportStatus - '+ severity +' - Severity', true)
    }, []);

    const handleBtnClick = () => {
        trackGoogleAnalyticsEvent('Submit - Severity', true)
        props.onSetNextStep({'feedbackOnSeriousnessRequested' : isUnknown})
    }
    
    return (
        <Container>
            <LogoContainer>
                <img
                alt='logo'
                src={require('../../../assets/img/corporate/teal-logo-text.png')}
                className='patient-service__logo'
                />
            </LogoContainer>
            <Content>
                <Title color={'#000'}>{SeverityMapping[severity].titleText} {SeverityMapping[severity].statusText}</Title>
                <Description color={'#000'}>
                {SeverityMapping[severity].descriptionText}
                </Description>
                <StatusImage>
                    <img
                    alt='severity'
                    src={require(`../../../assets/img/patientService/${SeverityMapping[severity].image}`)}
                    className='patient-service__status-image'
                    />
                </StatusImage>
                <Description color={'#4E4A4A'}>
                {SeverityMapping[severity].subText}
                </Description>
            </Content>
            <ButtonGroup>
                <ButtonItem
                    width='100%'
                    color="primary"
                    onClick={handleBtnClick}
                    title='agree'
                    variant='contained'
                    id='agree' >
                    {SeverityMapping[severity].buttonText}
                </ButtonItem>
            </ButtonGroup>
            <BottomBtnGroup>
                <FlexButtons>
                    <BackAnchor
                        onClick={props.handleBack}
                        title='back'
                        variant='contained'                        
                    >
                    ◄ Zurück
                    </BackAnchor>
                </FlexButtons>
            </BottomBtnGroup>
        </Container>
    );    
}

SeverityStep.propTypes = {
    onSetNextStep: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired
}

export default withTheme(SeverityStep)
