import React, { Component } from 'react'
import { withTheme } from '@material-ui/core'
import Title from 'lib/elements/Title'
import Container from 'lib/elements/Container'
import {
  BottomBtnGroup,
  ButtonItemNext,
  FlexButtons,
  BackAnchor,
  StepWrapper,
  MainContainer,
  ProgressContainer
} from '../../MainPage/styles'
import { StyledHeader, StyledTitle } from '../styles'
import MedTextAreaFollowup from '../../../components/MedTextAreaFollowup'
import PropTypes from 'prop-types'
import { InputContainer, Step5List, CheckboxContainer, RadioContainer, QuestionContainer, QuestionTitle } from './styles'
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio'
import DatePicker from 'components/DatePickerFollowups'
import moment from 'moment'
import FileTemplate from 'components/FileTemplate'
import FileDownload from 'components/FileDownload'

import {
  questionCustom,
  questionTemplate
} from 'constants.js'

const spanStyle = {
  textAlign: 'right',
  marginBottom: '1rem',
  display: 'block',
  fontSize: '1.5rem',
  color: '#6d706e'
}

const backButtonStyle = {
  marginTop: '4.5rem'
}

export class Step5 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCheckboxes: {},
      selectedRadioboxes: {},
      value: ''
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  };

  back = e => {
    e.preventDefault()
    this.props.prevStep()
  };

  handleSetMessageTxt = (questionId) => {
    return (event) => {
      this.props.onHandleCustomAnswer(questionId, event.target.value)
    }
  }

  // radio box
  handleRadioButtonChange = async (label, questionId) => {
    await this.setState({
      selectedRadioboxes: {
        ...this.state.selectedRadioboxes,
        [questionId]: label
      }
    })
    this.props.onHandleRadioButton(questionId, this.state.selectedRadioboxes[questionId])
  }

  createRadioButton = (label, questionId) => (
    <div style={{ cursor: 'pointer' }}>
      <label>
        <RadioContainer>
          <Radio
            type='radio'
            id={label}
            value={label}
            name={questionId}
            checked={this.state.selectedRadioboxes[questionId] === label}
            onChange={() => this.handleRadioButtonChange(label, questionId)}
          />
          {label}
        </RadioContainer>
      </label>
    </div>
  )

  createRadioButtons = (questionId, allowedOptions) => (
    allowedOptions.map((label) => this.createRadioButton(label, questionId))
  )

  // checkboxes
  toggleCheckboxChange = async (label, questionId) => {
    let questionAnswers = this.state.selectedCheckboxes[questionId] || []
    if (questionAnswers && questionAnswers.includes(label)) {
      questionAnswers.splice(questionAnswers.indexOf(label), 1)
      await this.setState({
        selectedCheckboxes: {
          ...this.state.selectedCheckboxes,
          [questionId]: questionAnswers
        }
      })
    } else {
      questionAnswers.push(label)
      await this.setState({
        selectedCheckboxes: {
          ...this.state.selectedCheckboxes,
          [questionId]: questionAnswers
        }
      })
    }
    this.props.onHandlecheckboxes(questionId, this.state.selectedCheckboxes[questionId])
  }

  createCheckbox = (label, questionId) => (
    <div style={{ cursor: 'pointer' }}>
      <label>
        <CheckboxContainer>
          <Checkbox
            type='checkbox'
            value={label}
            onChange={() => this.toggleCheckboxChange(label, questionId)}
          />
          {label}
        </CheckboxContainer>
      </label>
    </div>
  )

  createCheckboxes = (questionId, allowedOptions) => (
    allowedOptions.map((label) => this.createCheckbox(label, questionId))
  )

  handleDatePicker = (event, questionId) => {
    const patternOne = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/ // YYYY-MM-DD
    const patternTwo = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/ // DD-MM-YYYY
    let inputDateOne = patternOne.test(event.currentTarget.value)
    let inputDateTwo = patternTwo.test(event.currentTarget.value)
    if (inputDateTwo === true) {
      let momentDate = moment(event.currentTarget.value, 'DD.MM.YYYY').format('DD.MM.YYYY')
      this.props.onHandleDateAnswer(questionId, momentDate)
    } else
      if (inputDateOne === true) {
        let momentDate = moment(event.currentTarget.value).format('DD.MM.YYYY')
        this.props.onHandleDateAnswer(questionId, momentDate)
      }
  }

  render() {
    const { customAnswerData, questions, reportId } = this.props

    return (
      <Container>
        <MainContainer>
          <StyledHeader><StyledTitle>Der Arzneimittelhersteller hat folgende wichtige Rückfragen zu Ihrer Meldung:</StyledTitle></StyledHeader>
          <ProgressContainer>
            <div>
              <StepWrapper>
                <Title><h3> Sonstige Rückfrage(n) </h3></Title>
                {
                  <Step5List>
                    {questions && questions.filter(item => item.type === questionCustom || item.type === questionTemplate).map((question) => {
                      let textInput = customAnswerData[question._id] || ''
                      let charactersLeft = 200 - textInput.length

                      if (question.format === 'free-text') {
                        return [
                          <React.Fragment>
                            <QuestionContainer>
                              <InputContainer>
                                <Title><QuestionTitle>{question.text}</QuestionTitle></Title>
                                {(question.fileId !== undefined && question.fileId !== '') &&
                                  <FileDownload
                                    fileId={question.fileId}
                                    templateId={question.templateId}
                                    reportId={reportId}
                                  />
                                }
                                <span style={spanStyle}> {charactersLeft} Zeichen übrig </span>
                                <MedTextAreaFollowup
                                  onSetText={this.handleSetMessageTxt(question._id)}
                                  opinionTxt={textInput}
                                  placeholder={'Hier können Sie Ihre Antwort eingeben (200 Zeichen stehen dafür zur Verfügung)'}
                                  textareaId={'extraComment'}
                                />
                              </InputContainer>
                            </QuestionContainer>
                          </React.Fragment>
                        ]
                      } else if (question.format === 'date') {
                        return [
                          <React.Fragment>
                            <QuestionContainer>
                              <InputContainer>
                                <Title><QuestionTitle>{question.text}</QuestionTitle></Title>
                                {(question.fileId !== undefined && question.fileId !== '') &&
                                  <FileDownload
                                    fileId={question.fileId}
                                    templateId={question.templateId}
                                    reportId={reportId}
                                  />
                                }
                                <form noValidate>
                                  <DatePicker
                                    id='date'
                                    placeholder='dd.mm.yyyy'
                                    onHandleNativeFromDateInputChange={(e) => this.handleDatePicker(e, question._id)}
                                    dateInputFromValue={this.state['value_' + question._id]}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </form>
                              </InputContainer>
                            </QuestionContainer>
                          </React.Fragment>
                        ]
                      } else if (question.format === 'single-choice') {
                        return [
                          <React.Fragment>
                            <QuestionContainer>
                              <InputContainer>
                                <Title><QuestionTitle>{question.text}</QuestionTitle></Title>
                                {(question.fileId !== undefined && question.fileId !== '') &&
                                  <FileDownload
                                    fileId={question.fileId}
                                    templateId={question.templateId}
                                    reportId={reportId}
                                  />
                                }
                                <form >
                                  {this.createRadioButtons(question._id, question.allowedOptions)}
                                </form>
                              </InputContainer>
                            </QuestionContainer>
                          </React.Fragment>
                        ]
                      } else if (question.format === 'multiple-choice') {
                        return [
                          <React.Fragment>
                            <QuestionContainer>
                              <InputContainer>
                                <Title><QuestionTitle>{question.text}</QuestionTitle></Title>
                                {(question.fileId !== undefined && question.fileId !== '') &&
                                  <FileDownload
                                    fileId={question.fileId}
                                    templateId={question.templateId}
                                    reportId={reportId}
                                  />
                                }
                                <form >
                                  {this.createCheckboxes(question._id, question.allowedOptions)}
                                </form>
                              </InputContainer>
                            </QuestionContainer>
                          </React.Fragment>
                        ]
                      } else if (question.format === 'file') {
                        return (
                          <QuestionContainer>
                            <Title><QuestionTitle>{question.text}</QuestionTitle></Title>
                            <FileTemplate
                              questionId={question._id}
                              fileId={question.fileId}
                              templateId={question.templateId}
                              reportId={reportId}
                              onHandleFileAnswer={this.props.onHandleFileAnswer}
                              answerData={customAnswerData[question._id] || ''}
                            />
                          </QuestionContainer>
                        )
                      }
                    })
                    }
                  </Step5List>
                }
              </StepWrapper>
              <BottomBtnGroup>
                <FlexButtons>
                  <BackAnchor
                    style={backButtonStyle}
                    onClick={this.back}
                    title='back'
                    variant='contained'
                    themecolor={this.props.theme.palette.primary.main}
                  >
                    ◄ Zurück
                  </BackAnchor>
                  <ButtonItemNext
                    color='primary'
                    id='next'
                    onClick={this.continue}
                    title='next'
                    variant='contained'
                  >
                    Weiter
                  </ButtonItemNext>
                </FlexButtons>
              </BottomBtnGroup>
            </div>
          </ProgressContainer>
        </MainContainer>
      </Container>
    )
  }
}

Step5.propTypes = {
  drugs: PropTypes.bool.isRequired,
  answerDrugs: PropTypes.bool.isRequired
}

export default withTheme(Step5)
