import React from 'react'
import PropTypes from 'prop-types'
import ReactAutocomplete from 'react-autocomplete'
import { withTheme } from '@material-ui/core/styles'
import lodash from 'lodash'
import ErrorMessage from '../ErrorMessage'
import { Trans } from 'react-i18next'
import { getBooleanParamFromURL } from 'lib/functions'

import {
  Container,
  SearchContainer,
  SearchIconItem,
  CloseIconItem
} from './styles'

import {
  HelpIcon,
  ReactTooltipStyled
} from '../DoctorInput/styles'

import Title from 'lib/elements/Title'
import * as palette from '../../styles/palette'

class MedSearch extends React.Component {
  constructor(props) {
    super(props);

    this.autcompleteRef = React.createRef();
  }
  state = {
    isAutoCompleteOpen: true
  }

  debounceBlur = lodash.debounce(() => {
    this.setState({ isAutoCompleteOpen: false })
    this.autcompleteRef.current.blur()
  }, 100)

  // This is the culprit https://github.com/reactjs/react-autocomplete/issues/377
  // Issue is that, On phone when page is scrolled, the blur event for autocomplete input is not fired
  handleContainerBlur = (event) => {
    // At container blur handler check if list is open
    if (this.state.isAutoCompleteOpen) {
      // If yes, then at 100 ms delay explicitly fire blur
      // Delay is required because normally the blur would fire automatically.
      this.debounceBlur()
    }
  }

  onVisibilityChange = (isOpen) => this.setState({ isAutoCompleteOpen: isOpen })

  handleInputOnFocus = () => {
    this.setState({ isAutoCompleteOpen: true })
  }

  handleInputOnBlur = (e) => {
    // In normal conditions blur for input will be triggered automatically, so stop propagating that above.
    // In special cases like https://github.com/reactjs/react-autocomplete/issues/377, When it isn't fired, blur at
    // container will take care.
    e.stopPropagation()
  }

  handleSearch = (event) => {
    if (!this.state.isAutoCompleteOpen) {
      this.setState({ isAutoCompleteOpen: true })
    }
    this.props.onSearch(event)
  }

  handleSelect = (value, item) => {
    this.props.onSelect(value, item)
  }

  render () {
    let { value, items, onSearch, onSelect, onClear, placeholder, title, theme, searchId, errorText, hasActiveValidation, tooltipId, tooltipText, autoHighlight } = this.props
    return (
      <Container onBlur={this.handleContainerBlur}>
        <Title color="textPrimary">
          {title}
          {tooltipId && tooltipText && <a data-tip data-for={tooltipId + 'Tooltip'}><HelpIcon /></a> }
          {tooltipId && tooltipText && <ReactTooltipStyled
            id={tooltipId + 'Tooltip'}
            type='dark'
            place='top'
            effect='solid'
            scrollHide={false}
          >
            <span>{tooltipText}</span>
          </ReactTooltipStyled> }
        </Title>
        <SearchContainer
          active={value === '' ? null : value}
          lightColor={theme.palette.primary.main}
          mainColor={theme.palette.text.primary}
          borderColor={palette.primaryInputBorder}
          focusColor={theme.palette.primary.main}
          placeholderColor={theme.palette.text.secondary}
          errorColor={theme.palette.error.main}
          className={hasActiveValidation && value === '' ? 'error' : null}
        >
          {value === '' && <SearchIconItem />}
          <ReactAutocomplete
            ref={this.autcompleteRef}
            getItemValue={item => item.label}
            inputProps={{ placeholder: placeholder, id: searchId, onFocus: this.handleInputOnFocus, onBlur: this.handleInputOnBlur }}
            onMenuVisibilityChange={this.onVisibilityChange}
            open={this.state.isAutoCompleteOpen}
            items={items}
            onChange={this.handleSearch}
            onSelect={this.handleSelect}
            autoHighlight={autoHighlight}
            renderItem={(item, highlighted) =>
              <div key={item.id} style={{
                  cursor: 'pointer',
                  fontSize: '1.4rem',
                  color: highlighted ? theme.palette.primary.contrastText : theme.palette.text.primary,
                  backgroundColor: highlighted ? theme.palette.primary.main : 'transparent'
                }} >
                {item.label}
                <div style={{fontSize: '12px'}}>
                  {item.sec}</div>
                <div style={{fontSize: '12px'}}>
                  {item.addressCountry}  {item.postalCode}
                </div>
              </div>}
            value={value}
          />
          {value !== '' && <CloseIconItem
            lightColor={theme.palette.primary.light}
            mainColor={theme.palette.primary.main} onClick={onClear}
          />}
        </SearchContainer>
        { hasActiveValidation && value === '' && errorText !== '' && <ErrorMessage><Trans>{ errorText }</Trans></ErrorMessage> }
      </Container>
    )
  }

  focus () {
    this.autcompleteRef.current.focus();
  }

  componentDidMount () {
    const isAutoFocusEnabled = getBooleanParamFromURL(window.location.search, 'autoFocusSearch')
    if (isAutoFocusEnabled) {
      this.focus()
    }
  }
  componentWillUnmount () {
    // cancel the debouncer for blur
    this.debounceBlur.cancel()
  }
}

MedSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
}

export default withTheme(MedSearch)
