import React from 'react'
import PropTypes from 'prop-types'
import { translate, Trans } from 'react-i18next'

import Checkbox from '@material-ui/core/Checkbox'

import { withTheme } from '@material-ui/core/styles'

import { isSafari, isMobile } from 'react-device-detect'

import { getStringParamFromURL, getBooleanParamFromURL, isDateInputSupported } from 'lib/functions/index'

import DropDownMenu from '../DropDownMenu'
import ErrorMessage from '../ErrorMessage'

import {
  defaultNonOption,
  timeList
} from 'constants.js'

import moment from 'moment'

import {
  Container,
  DatePickerContainer,
  DatePickerBlock,
  MedWidgetDatePicker,
  CalendarIcon,
  FromToText,
  DateCheckboxContainer,
  DateCustomInputContainer,
  SingleDateInput,
  DateContainerNative,
  NativeInput,
  Label,
  Spacer,
  MobileInput,
  DropDownLabel,
  UnitLabel,
  InfoContainer
} from './styles'

import {
  HelpIcon,
  ReactTooltipStyled
} from '../DoctorInput/styles'

import Title from 'lib/elements/Title'
import * as palette from '../../styles/palette'

// set datepicker language other than en
let lang = getStringParamFromURL(window.location.search, 'lang')
if (lang.length && lang !== 'en') {
  moment.locale(lang)
}

const DateCustomInput = ({ onClick, value, primaryColor, darkColor, lightColor, timingId }) => {
  return (
    <DateCustomInputContainer
      className="example-custom-input"
      darkColor={darkColor}
      id={timingId}
      lightColor={lightColor}
      onClick={onClick}
      primaryColor={primaryColor}
      value={value}
    >
      <span>{value}</span>
      <CalendarIcon lightColor={lightColor} />
    </DateCustomInputContainer>
  )
}

DateCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
}

const NativePicker = ({
  timeEl,
  timeElName,
  fromDateMoment,
  toDateMoment,
  notSure,
  onSimpleTimeUnitOpen,
  onSimpleTimeUnitClose,
  onSimpleTimeUnitBlur,
  onSimpleTimeUnitChange,
  onHandleNativeFromDateInputChange,
  onHandleNativeFromDateInputBlur,
  onHandleNativeToDateInputChange,
  onCheckBox,
  onSetFromDate,
  onSetToDate,
  title,
  theme,
  timePeriodInputValue,
  dateInputFromValue,
  dateInputToValue,
  minToDate,
  color,
  tooltipId,
  tooltipFromText,
  tooltipToText,
  errorText,
  hasTimeConflictError,
  timeConflictErrorText,
  fromCaption,
  toCaption,
  timeframeCaption,
  hasActiveValidation
}) => {

  return (

    <Container className={ '' }>
      {<Title color="textPrimary">{title}</Title> }
      {<label style={{ display: 'contents', cursor: 'pointer', color: theme.palette.text.primary }}>
        <DateCheckboxContainer
          lightColor={theme.palette.primary.light}
          primaryColor={theme.palette.primary.main}
          style={{ marginBottom: '0.5rem' }}
        >
          <Checkbox
            id={'toggle_checkbox'}
            checked={notSure}
            onChange={onCheckBox}
            value="notSure"
          />
          <Trans for="toggle_checkbox">I do not know the exact dates</Trans>
        </DateCheckboxContainer>
      </label> }
      {
        <DatePickerContainer
          lightColor={theme.palette.primary.light}
          primaryColor={theme.palette.primary.main}
          style={{ display: notSure ? 'none' : 'block' }}
        >
          <DateContainerNative>
            <MobileInput>
              <FromToText color={theme.palette.text.primary} type="from" className={ '' }>
                {fromCaption}
                {tooltipId && tooltipFromText && <a data-tip data-for={tooltipId + 'FromTooltip'}><HelpIcon /></a> }
                {tooltipId && tooltipFromText && <ReactTooltipStyled
                  id={tooltipId + 'FromTooltip'}
                  type='dark'
                  place='top'
                  effect='solid'
                  scrollHide={false}
                >
                  <span>{tooltipFromText}</span>
                </ReactTooltipStyled> }
              </FromToText>
            </MobileInput>
            <NativeInput
              id="start-date"
              name="start-date"
              onChange={onHandleNativeFromDateInputChange}
              onBlur={onHandleNativeFromDateInputBlur && (e => {
                  if (e.target.value === "" && (isSafari || isMobile)) {
                    e.target.placeholder = "dd.mm.yyyy";
                  }
                })}
              onFocus={e => (e.target.placeholder = "")}
              type="date"
              value={dateInputFromValue}
              min="1900-01-01"
              max="2999-01-01"
              placeholder={isSafari || isMobile ? 'dd.mm.yyyy' : ''}
              className={hasActiveValidation && !dateInputFromValue ? 'error' : null}
              lightColor={theme.palette.primary.light}
              mainColor={theme.palette.text.primary}
              inactiveColor={theme.palette.text.secondary}
              borderColor={palette.primaryInputBorder}
              focusColor={theme.palette.primary.main}
              errorColor={theme.palette.error.main}
              required
            />
            { hasActiveValidation && hasTimeConflictError && timeConflictErrorText && <ErrorMessage>{timeConflictErrorText}</ErrorMessage> }
            { hasActiveValidation && !hasTimeConflictError && !dateInputFromValue && errorText !== '' && <ErrorMessage>{errorText}</ErrorMessage> }
            <Spacer />
            <MobileInput>
              <FromToText color={theme.palette.text.primary} type="to" className={ '' }>
                {toCaption}
                {tooltipId && tooltipToText && <a data-tip data-for={tooltipId + 'ToTooltip'}><HelpIcon /></a> }
                {tooltipId && tooltipToText && <ReactTooltipStyled
                  id={tooltipId + 'ToTooltip'}
                  type='dark'
                  place='top'
                  effect='solid'
                  scrollHide={false}
                >
                  <span>{tooltipToText}</span>
                </ReactTooltipStyled> }
              </FromToText>
            </MobileInput>
            <NativeInput
              id="end-date"
              name="end-date"
              onChange={onHandleNativeToDateInputChange}
              onBlur={(e => {
                if (e.target.value === "" && (isSafari || isMobile)) {
                  e.target.placeholder = "dd.mm.yyyy";
                }
              })}
              onFocus={e => (e.target.placeholder = "")}
              type="date"
              value={dateInputToValue}
              min={minToDate}
              max="2999-01-01"
              placeholder={isSafari || isMobile ? 'dd.mm.yyyy' : ''}
              lightColor={theme.palette.primary.light}
              mainColor={theme.palette.text.primary}
              inactiveColor={theme.palette.text.secondary}
              borderColor={palette.primaryInputBorder}
              focusColor={theme.palette.primary.main}
              required
            />
          </DateContainerNative>
        </DatePickerContainer>
      }
      {
        <div style={{ display: notSure ? '' : 'none', minHeight: '15.1rem' }}>
          <InfoContainer color={ theme.palette.text.primary }>
            Bitte geben Sie ungefähr das Start-Datum ein
          </InfoContainer>
          <DatePickerContainer
                lightColor={theme.palette.primary.light}
                primaryColor={theme.palette.primary.main}
              >
            <DropDownLabel color={theme.palette.text.primary}>{timeframeCaption}</DropDownLabel>
            <UnitLabel color={theme.palette.text.primary}>Zahl</UnitLabel>
          </DatePickerContainer>
          <DatePickerContainer
            lightColor={theme.palette.primary.light}
            primaryColor={theme.palette.primary.main}
          >
            <DropDownMenu
              anchorEl={timeEl}
              buttonId='time-btn'
              defaultName={ defaultNonOption }
              dropdownList={timeList}
              isJson={false}
              itemName={timeElName}
              menuId='time-menu'
              onHandleClose={onSimpleTimeUnitClose}
              onOpen={onSimpleTimeUnitOpen}
              hasError={hasActiveValidation && timeElName === defaultNonOption}
            />
            <SingleDateInput
              id={'single-date-input'}
              onBlur={onSimpleTimeUnitBlur}
              onChange={onSimpleTimeUnitChange}
              placeholder=""
              type="number"
              value={timePeriodInputValue}
              lightColor={theme.palette.primary.light}
              mainColor={theme.palette.text.primary}
              borderColor={palette.primaryInputBorder}
              focusColor={theme.palette.primary.main}
              errorColor={theme.palette.error.main}
              className={hasActiveValidation && !timePeriodInputValue ? 'error' : null}
              pattern="[0-9]*"
              min="1"
              max="99"
              inputmode="numeric"
            />
          </DatePickerContainer>
          { hasActiveValidation && hasTimeConflictError && timeConflictErrorText  && <ErrorMessage>{timeConflictErrorText}</ErrorMessage> }
          { hasActiveValidation && !hasTimeConflictError && timeElName === defaultNonOption && <ErrorMessage><Trans>Please select a timeframe.</Trans></ErrorMessage> }
          { hasActiveValidation && !hasTimeConflictError && timeElName !== defaultNonOption && !timePeriodInputValue && <ErrorMessage><Trans>Please select an amount.</Trans></ErrorMessage> }
        </div>
      }
    </Container>
  )
}

NativePicker.propTypes = {
  fromDateMoment: PropTypes.any,
  toDateMoment: PropTypes.any,
  notSure: PropTypes.bool.isRequired,
  onSetFromDate: PropTypes.func.isRequired,
  onSetToDate: PropTypes.func.isRequired,
  onCheckBox: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  fromCaption: PropTypes.string.isRequired,
  toCaption: PropTypes.string.isRequired,
  timeframeCaption: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
}

export default withTheme(translate('translations')(NativePicker))
