import styled from 'styled-components'

export const MedTextAreaContainerFollowup = styled.textarea`
  border-radius: 0.9rem;
  padding: .4rem 0.6rem;
  height: 7rem;
  background-color: transparent;
  border: 1px solid ${props => props.borderColor};
  color: ${props => props.mainColor};
  ::placeholder {
    color: ${props => props.borderColor};
  }
  &:focus, &:active {
    outline: none;
    color: ${props => props.focusColor};
    border: 2px solid ${props => props.focusColor};
    padding-left: calc(.6rem - 1px);
  }
  margin-top: .4rem;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.5rem;
`
