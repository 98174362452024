import { createMuiTheme } from '@material-ui/core/styles'
import * as palette from 'styles/palette'

const theme = (customProps = {}) => {
  const colors = {
    DARK: customProps && customProps.palette && customProps.palette.primary && customProps.palette.primary.dark ? customProps.palette.primary.dark : '#117982',
    LIGHT: customProps && customProps.palette && customProps.palette.primary && customProps.palette.primary.light ? customProps.palette.primary.light : '#59a8ae',
    MAIN: customProps && customProps.palette && customProps.palette.primary && customProps.palette.primary.main ? customProps.palette.primary.main : '#8e1441'
  }

  return createMuiTheme({
    typography: {
      fontFamily: 'Nunito, sans-serif',
      fontSize: 16,
      lineHeight: 18,
      headline: {
        color: colors.DARK,
        fontSize: 'auto',
        fontWeight: 'bold'
      },
      title: {
        color: colors.DARK,
        fontSize: 'auto',
        fontWeight: 'bold'
      },
      subheading: {
        color: colors.DARK,
        fontWeight: 'bold'
      }
    },
    palette: {
      background: {
        cookieBanner: '#f5f5f5',
        chip: colors.LIGHT
      },
      text: {
        primary: palette.primaryGrey,
        secondary: palette.primaryInputBorder
      },
      primary: {
        light: palette.lightGreen,
        main: palette.lightGreen,
        dark: palette.darkGreen,
        contrastText: '#fff'
      },
      secondary: {
        main: palette.primaryDarkRed
      },
      error: {
        main: palette.errorField,
        contrastText: '#fff'
      }
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'normal',
          margin: '3px'
        }
      },
      MuiFormLabel: {
        root: {
          marginBottom: '1rem'
        }
      },
      MuiChip: {
        root: {
          // Remove grey color
          color: '#fff',
          backgroundColor: colors.LIGHT,
          borderRadius: '2px'
        },
        clickable: {
          // Remove grey highlight
          WebkitTapHighlightColor: colors.MAIN,
          cursor: 'pointer',
          '&:hover, &:focus': {
            backgroundColor: colors.MAIN
          },
          '&:active': {
            backgroundColor: colors.MAIN
          }
        },
        deleteIcon: {
          // Remove grey highlight
          WebkitTapHighlightColor: colors.MAIN,
          color: colors.DARK,
          '&:hover': {
            color: colors.LIGHT
          }
        }
      },
      MuiAutocomplete: {
        option: {
          minHeight: '2rem',
          color: palette.primaryGrey,
          '&:hover, &[data-focus="true"]': {
            backgroundColor: colors.LIGHT,
            color: palette.white
          }
        },
        input: {
          fontSize: '1.6rem',
          lineHeight: '2rem',
          padding: '5.5px 4px !important',
          marginRight: 5
        },
        inputRoot: {
          paddingRight: '40px !important'
        },
        clearIndicator: {
          paddingTop: 8
        }
      }
    },
    ...customProps
  })
}

export default theme
