import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { makeStyles } from '@material-ui/core/styles'

import CountryFlag from '../CountryFlag'

const useStyles = makeStyles(theme => ({
    button: {
        margin: 0,
        paddingTop: theme.spacing.unit * 0.5,
        paddingBottom: theme.spacing.unit * 0.5,
    }
}))

const CountrySelect = (props) => {
    const classes = useStyles();
    const [country, setCountry] = React.useState(props.defaultSelected || 'de');
    
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
  
    const handleToggle = () => {
      setOpen(prevOpen => !prevOpen);
    };

    const changeCountry = event => {
        if(event.currentTarget.hasAttribute('value')) {
            setCountry(event.currentTarget.getAttribute('value'))
            props.onCountryChange(event.currentTarget.getAttribute('value'))
        }
        setOpen(false);
    }

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    
    const handleListKeyDown = event => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div>
            <Button
            ref={anchorRef}
            aria-controls={open ? 'country-list-grow' : undefined}
            aria-haspopup="true"
            disableRipple
            disabled={props.disableSelection}
            className={classes.button}
            onClick={handleToggle}
            endIcon={<ArrowDropDownIcon />}
            >
                <CountryFlag code={country.toUpperCase()} />
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                in={open}
                timeout={1000}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                <Paper elevation={10} >
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                        autoFocusItem={open}
                        id="country-list-grow"
                        onKeyDown={handleListKeyDown}
                        >
                        {["de", "ch", "at"].filter(code => code.toLowerCase() !== country.toLowerCase()).map(code => {
                            return (
                                <MenuItem
                                key={code}
                                value={code.toLowerCase()}
                                onClick={changeCountry}
                                >
                                    <CountryFlag code={code.toUpperCase()} />
                                </MenuItem>
                            )}
                        )}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Popper>
        </div>
    )
}

CountrySelect.propTypes = {
    defaultSelected: PropTypes.string,
    onCountryChange: PropTypes.func.isRequired,
    disableSelection: PropTypes.bool
}
  
export default CountrySelect