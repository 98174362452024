import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withTheme } from '@material-ui/core/styles'
import { translate, Trans } from 'react-i18next'


import Title from 'lib/elements/Title'
import MedTextArea from 'components/MedTextArea'
import lodash, { debounce } from 'lodash'
import _ from 'lodash'

import { translateCapitalizeFormat } from 'lib/functions'
import { trackGoogleAnalyticsEvent } from 'service/tracker'

import {
  errorMessages,
  validationRegex
} from '../../../constants'

import {
  BottomBtnGroup,
  ButtonItem,
  FlexButtons,
  BackAnchor,
  StepWrapper
} from '../styles'

import {
  InputContainer
} from './styles'

const hasPriority = true

class CommentStep extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activePriority: '',
      nameTxt:  _.get(props.formData, 'contacts.patient.identity.name.lastName', ''),
      birthDayTxt: _.get(props.formData, 'contacts.patient.identity.dateOfBirth', ''),
      emailTxt: _.get(props.formData, 'contacts.patient.identity.email', ''),
      currentStep: 0,
      oldEL: null,
      oldName: _.get(props.formData, 'contacts.patient.dateOfBirthYear', ''),
      oldEmailTxt: _.get(props.formData, 'contacts.reporter.identity.email', ''),
      messageTxt: _.get(props.formData, 'description', ''),
      doctor: _.get(props.formData, 'contacts.healthProfessional.identity.name.lastName', ''),
      doctors: [],
      isSetName: false,
      isYes: _.get(props.formData, 'extras.isYes', false),
      isNo: _.get(props.formData, 'extras.isNo', false),
      isChecked: _.get(props.formData, 'extras.isChecked', false),
      // isFilledNext: false,
      isFilledMessage: false,
      // isFilledInputs: false,
      isFilledInputsOnAddDoctorScreen: false,
      isFilledInputsOnNoDoctorScreen: false,
      isFilledInputsOnAddExtraNoteScreen: false,
      isFilledInputsOnEditScreen: false,
      isGotoNextBigStep: false,
      hasOldEmailError: false,
      hasBirthYearError: false,
      hasEmailError: false,
      hasFullBirthDateError: false,
      isFilledInputsOnDoctorScreen: false,
      loading: false
    }
  }

  handleChangeEmail = emailTxt => {
    this.setState({ emailTxt }, () => {
      if (this.state.hasEmailError) {
        this.handleChangeEmailBlur(emailTxt)
      }
    })
  }

  handleChangeEmailBlur = emailTxt => {
    if (emailTxt === 'hasPriority') {
      this.setState({ activePriority: '' })
      return
    }
    let emailRegex = validationRegex.email
    this.setState({ hasEmailError: !emailRegex.test(String(emailTxt).toLowerCase()) })
  }

  handleSetMessageTxt = event => {
    this.setState({ messageTxt: event.target.value }, () => {
      // this.props.onSetNextStep({description: 'please change this to something dynamic this.state.description'})

      if (this.state.messageTxt !== '' && !this.state.isSetMessage) {
        this.setState({ isSetMessage: true })
      }

      if (this.state.messageTxt === '' && this.state.isSetMessage) {
        this.setState({ isSetMessage: false })
      }
    })
  }

  // This function will be depricated
  componentWillReceiveProps (newProps) {
    if (!newProps.currentHandleState) {
      this.setState({ activePriority: '' })
    }
  }

  /* global */

  handleActivePriority = (e, activePriority) => {
    e.stopPropagation()
    if (this.state.activePriority === '') {
      this.setState({ activePriority })
    } else {
      this.setState({ activePriority: '' })
    }
  }

  handleBlur = () => {
    this.setState({ activePriority: '' })
  }

  handleNext = () => {
    trackGoogleAnalyticsEvent('Submit - CommentStep', true)
    this.props.onSetNextStep({
      'description': this.state.messageTxt
    })
  }

  handleBack = () => {
    this.props.isBackToEditReset()
    this.props.onSetBackStep()
  }

  componentDidMount () {
    trackGoogleAnalyticsEvent('PageView - CommentStep', true)
  }

  render () {
    const {
      doctor,
      isYes,
      isNo,
      activePriority,
      oldEL,
      oldName,
      messageTxt,
      oldEmailTxt,
      doctors,
      isFilledInputs,
      isFilledNext,
      isFilledInputsOnAddDoctorScreen,
      isFilledInputsOnNoDoctorScreen,
      isFilledInputsOnAddExtraNoteScreen,
      isFilledInputsOnEditScreen,
      isChecked,
      emailTxt,
      birthDayTxt,
      nameTxt,
      isSetMessage,
      loading,
      theme
    } = this.state

    const {
      gender,
      weight,
      height
    } = this.props.formData.contacts.patient

    // transform gender in the format desired in our translations keys
    let caseGender = translateCapitalizeFormat(gender)

    let disableNextOnDoctorScreen =
       (this.state.hasEmailError === false &&
        this.state.hasFullBirthDateError === false &&
        this.state.emailTxt.length > 0 &&
        this.state.birthDayTxt.length > 0 &&
        this.state.nameTxt.length > 0 &&
        this.state.doctor.length > 0 &&
        this.state.isChecked)

    let disableNextNoDoctorScreen =
       (this.state.hasOldEmailError === false &&
        this.state.hasBirthYearError === false &&
        this.state.oldEmailTxt.length > 0 &&
        this.state.oldName > 0)

    return (
      <div>
        <StepWrapper>
          <InputContainer>
            <Title>Hier können Sie einen Kommentar hinzufügen: (optional)</Title>
            <MedTextArea
              onSetText={this.handleSetMessageTxt}
              opinionTxt={messageTxt}
              placeholder={'z. B. Die Beschwerden machen sich besonders am Abend bemerkbar ...'}
              textareaId={'extraComment'}
            />
          </InputContainer>
        </StepWrapper>
        <BottomBtnGroup>
          <FlexButtons>
            <BackAnchor
              onClick={this.handleBack}
              title='back'
              variant='contained'
              themecolor={this.props.theme.palette.primary.main}
            >
              ◄ Zurück
            </BackAnchor>
            <ButtonItem
              color="primary"
              onClick={this.handleNext}
              title='next'
              variant='contained'
              id="next"
            >
              Weiter
            </ButtonItem>
          </FlexButtons>
        </BottomBtnGroup>
      </div>
    )
  }
}

CommentStep.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onSetBackStep: PropTypes.func.isRequired,
  onSetNextStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSetCurrentStep: PropTypes.func.isRequired
}

export default withTheme(translate('translations')(CommentStep))
