import styled from 'styled-components'

export const MedTextInputContainerFollowup = styled.input`  
  border-radius: 0.9rem;
  height: 4rem;
  background-color: transparent;
  border: 1px solid ${props => props.borderColor};
  color: ${props => props.mainColor};
  ::placeholder {
    color: ${props => props.borderColor};
  }
  &:focus, &:active {
    outline: none;
    color: ${props => props.focusColor};
    border: 2px solid ${props => props.focusColor};
  }
  margin-top: .4rem;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.5rem;
`
