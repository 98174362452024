import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withTheme } from '@material-ui/core/styles'
import { translate, Trans } from 'react-i18next'

import ReactLoading from 'react-loading'

import lodash, { debounce } from 'lodash'
import _ from 'lodash'

import { trackGoogleAnalyticsEvent } from 'service/tracker'

import SubmitConsentDialog from 'components/SubmitConsentDialog'
import { translateCapitalizeFormat } from 'lib/functions'
import Spinner from 'components/Spinner'

import {
  GENDER,
  errorMessages,
  validationRegex
} from '../../../constants'

import {
  BottomBtnGroup,
  ButtonItem,
  FlexButtons,
  BackAnchor,
  StepWrapper
} from '../styles'

import {
  HelpIcon,
  ReactTooltipStyled
} from '../../../components/DoctorInput/styles'

import {
  StepContainer,
  StepTitle,
  StepDesc,
  EditIconItem,
  SpinnerContainer,
  Description
} from './styles'

const hasPriority = true

class OverviewStep extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activePriority: '',
      nameTxt:  _.get(props.formData, 'contacts.patient.identity.name.lastName', ''),
      birthDayTxt: _.get(props.formData, 'contacts.patient.identity.dateOfBirth', ''),
      currentStep: 0,
      oldEL: null,
      oldName: _.get(props.formData, 'contacts.patient.dateOfBirthYear', ''),
      oldEmailTxt: _.get(props.formData, 'contacts.reporter.identity.email', ''),
      messageTxt: _.get(props.formData, 'description', ''),
      doctor: _.get(props.formData, 'contacts.healthProfessional.identity.name.lastName', ''),
      doctors: [],
      isSetName: false,
      isYes: _.get(props.formData, 'extras.isYes', false),
      isNo: _.get(props.formData, 'extras.isNo', false),
      isChecked: _.get(props.formData, 'extras.isChecked', false),
      // isFilledNext: false,
      isFilledMessage: false,
      // isFilledInputs: false,
      isFilledInputsOnAddDoctorScreen: false,
      isFilledInputsOnNoDoctorScreen: false,
      isFilledInputsOnAddExtraNoteScreen: false,
      isFilledInputsOnEditScreen: false,
      isGotoNextBigStep: false,
      hasOldEmailError: false,
      hasBirthYearError: false,
      hasEmailError: false,
      hasFullBirthDateError: false,
      isFilledInputsOnDoctorScreen: false,
      loading: false
    }
  }

  handleSendMessage = () => {
    trackGoogleAnalyticsEvent('Submit - OverviewStep', true)
    this.props.onSubmit()
  }

  // This function will be depricated
  componentWillReceiveProps (newProps) {
    if (!newProps.currentHandleState) {
      this.setState({ activePriority: '' })
    }
  }

  // onSetBackToEdit is missing on props validation
  handleEdit = (step) => {
    this.props.onSetBackToEdit()
    switch (step) {
      case 'SideEffectsStep':
        this.handleGoToStep(0)
        break
      case 'MedicationsStep':
        this.handleGoToStep(1)
        break
      case 'BodyStep':
        this.handleGoToStep(2)
        break
      case 'EmailStep':
        this.handleGoToStep(6)
        break
      default:
        this.handleGoToStep(0)
    }
  }
  /* global */

  handleActivePriority = (e, activePriority) => {
    e.stopPropagation()
    if (this.state.activePriority === '') {
      this.setState({ activePriority })
    } else {
      this.setState({ activePriority: '' })
    }
  }

  handleBlur = () => {
    this.setState({ activePriority: '' })
  }

  handleBack = () => {
    this.props.isBackToEditReset()
    this.props.onSetBackStep()
  }

  handleGoToStep = index => {
    this.props.onSetCurrentStep(index + 1)
  }

  getSideEffectOrDrugLine(sideEffectOrDrug) {
    let line = sideEffectOrDrug['name']
    if (sideEffectOrDrug.notSure) {
      line += ', seit ca. ' + sideEffectOrDrug['timePeriodInputValue']  + ' ' + sideEffectOrDrug['timeElName']
    } else if (sideEffectOrDrug.startDate && sideEffectOrDrug.endDate) {
      line += ', ' + moment(sideEffectOrDrug.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY') + ' - ' + moment(sideEffectOrDrug.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
    } else if (sideEffectOrDrug.startDate) {
      line += ', seit ' + moment(sideEffectOrDrug.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }
    return line
  }

  formatSideEffectsOrDrugs(sideEffectOrDrugData) {
    let formattedLines = [this.getSideEffectOrDrugLine(sideEffectOrDrugData[0])]
    if (sideEffectOrDrugData.length > 1) {
      formattedLines.push(this.getSideEffectOrDrugLine(sideEffectOrDrugData[1]))
    }
    if (sideEffectOrDrugData.length > 2) {
      formattedLines.push('... und ' + parseInt(sideEffectOrDrugData.length - 2) + ' weitere')
    }
    return formattedLines
  }

  componentDidMount () {
    trackGoogleAnalyticsEvent('PageView - OverviewStep', true)
  }

  render () {
    const {
      gender,
      weight,
      height,
      dateOfBirthYear
    } = this.props.formData.contacts.patient
    const {
      oldEmailTxt
    } = this.state

    return (
      <div>
        <StepWrapper>
          <div>
            <div>
              <StepContainer>
                <div>
                  <StepTitle>Medikamente</StepTitle>
                  <StepDesc color={this.props.theme.palette.text.primary}>
                    {this.formatSideEffectsOrDrugs(this.props.formData.drugs).map(function(line) {
                      return (
                        <div>{line}</div>
                      )})}
                  </StepDesc>
                </div>
              </StepContainer>
            </div>
            <div>
              <StepContainer>
                <div>
                  <StepTitle>Nebenwirkungen</StepTitle>
                  <StepDesc color={this.props.theme.palette.text.primary}>
                    {this.formatSideEffectsOrDrugs(this.props.formData.effects).map(function(line) {
                      return (
                        <div>{line}</div>
                      )})}
                  </StepDesc>
                </div>
              </StepContainer>
            </div>
            <div>
              <StepContainer>
                <div>
                  <StepTitle>Angaben zum Körper</StepTitle>
                  <StepDesc color={this.props.theme.palette.text.primary}>
                      {gender === GENDER.MALE ? 'm' : gender === GENDER.FEMALE ? 'w' : 'd' },
                      {dateOfBirthYear}
                      {height ? `, ${height} cm` : ''}
                      {weight ? `, ${weight} kg` : ''}
                  </StepDesc>
                </div>
              </StepContainer>
            </div>
            <div>
              <StepContainer>
                <div>
                  <StepTitle>E-Mail</StepTitle>
                  <StepDesc color={this.props.theme.palette.text.primary}>{oldEmailTxt}</StepDesc>
                </div>
              </StepContainer>
            </div>
            <div>
              <Description>
                <SubmitConsentDialog theme={this.props.theme} />
              </Description>
              
            </div>
          </div>
        </StepWrapper>
        <BottomBtnGroup style={{ marginTop: '-5.5rem' }}>
          <FlexButtons style={{ position: 'relative', paddingBottom: '4rem '}}>
            <BackAnchor
              onClick={this.handleBack}
              title='back'
              variant='contained'
              themecolor={this.props.theme.palette.primary.main}
            >
              ◄ Zurück
            </BackAnchor>
          </FlexButtons>
          <div>
            {this.props.isSubmitting &&
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            }
            {!this.props.isSubmitting &&
              <ButtonItem
                width='100%'
                onClick={this.handleSendMessage}
                step={4}
                variant="contained"
                color="primary"
                id="submitForm"
                style={{ padding: '1.5rem 0 '}}
              >
              Bestätigen und Meldung abschicken
                {this.props.isSubmitting && <ReactLoading height={20} style={{ marginLeft: 8 }} type='spin' width={20} />}
              </ButtonItem>
            }
          </div>
        </BottomBtnGroup>
      </div>
    )
  }
}

OverviewStep.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onSetBackStep: PropTypes.func.isRequired,
  onSetNextStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSetCurrentStep: PropTypes.func.isRequired
}

export default withTheme(translate('translations')(OverviewStep))
