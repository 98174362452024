import React from 'react'
import App from '../App'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import theme from 'configs/config-theme'
import queryString from 'query-string'
import { GoogleAnalytics } from 'service/googleanalitics'
import { Hotjar } from 'service/hotjar'
import GlobalStyle from '../../styles/global'

const getThemeFromURL = (url) => {
  const parsed = queryString.parse(url)
  return parsed.theme ? createMuiTheme(JSON.parse(parsed.theme)) : theme()
}

const Root = () => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <GoogleAnalytics />
      <Hotjar />
      <MuiThemeProvider theme={getThemeFromURL(window.location.search)}>
        <App />
      </MuiThemeProvider>
    </React.Fragment>
  )
}

export default Root
