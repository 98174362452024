import React from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '../../components/DialogTitle'

import {
  Secrecy
} from './styles'


class ConfidentialityDialog extends React.Component {

  state = {
    open: false
  }

  handleOpen = () => {
    this.setState({open: true});
  }

  handleClose = () => {
    this.setState({open: false});
  }

  render () {
    return (
      <React.Fragment>
        <Dialog onClose={this.handleClose} open={this.state.open} onRequestClose={this.handleClose}>
          <DialogTitle theme={this.props.theme} onClose={this.handleClose}>Schweigepflichts​entbindung</DialogTitle>
          <DialogContent style={{ color: this.props.theme.palette.text.primary }} >
            <p>Ich entbinde hiermit meinen auf der Website angegebenen Arzt oder Apotheker von der Schweigepflicht und willige ein, 
            dass dieser Informationen über mich, einschließlich Gesundheitsdaten und bezüglich der angegebenen Nebenwirkungen und eingenommenen 
            Medikamente an Nebenwirkungen.de oder, sofern erforderlich oder gesetzlich vorgeschrieben, 
            an den Arzneimittelhersteller übermitteln darf. Die Weitergabe der Informationen an den Hersteller erfolgt dabei pseudonymisiert, 
            d.h. ohne personenbezogene Daten. Ich willige ferner ein, dass die XO Life GmbH, Agnes-Pockels-Bogen 1, 80992 München, 
            diese Daten speichert, damit mein Arzt oder Apotheker jederzeit darauf zugreifen kann. Mir ist bekannt, 
            dass ich diese Erklärungen jederzeit mit Wirkung für die Zukunft an <a style={{ color : this.props.theme.palette.primary.main}} href="mailto:datenschutz@nebenwirkungen.de">datenschutz@nebenwirkungen.de</a> widerrufen kann.</p>
          </DialogContent>
        </Dialog>
        <Secrecy theme={this.props.theme} onClick={this.handleOpen}>Schweigepflicht</Secrecy>
      </React.Fragment>
    )
  }

}

export default ConfidentialityDialog

