
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'

export const Container = styled.div`
  text-align: center;
  position: relative;
`

export const CheckIconItem = styled(CheckIcon)`
  background-color: ${props => props.theme.palette.primary.main};
  fill: ${props => props.theme.palette.primary.main};
  color: #FFF;
  border-radius: 50%;
  padding: 1.2rem;
  font-size: 4rem !important;
`

export const ResultHeadline = styled(Typography)`
  &&& {
    color: ${props => props.theme.palette.primary.main};
    font-size: 2.6rem;
    line-height: 2.6rem;
    font-weight: 700;
    margin: 2rem 0;
  }
`

export const Description = styled(Typography)`
  &&& {
    color: ${props => props.color};
    font-size: 1.6rem;
    font-weight: ${(props) => props.fontWeight || 400 };
    line-height: 2rem;
    text-align: center;
    margin-bottom 1.6rem;
  }
`

