import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import * as palette from 'styles/palette'

const OverviewButton = styled(Button)`
  &&& {
    text-transform: none;
    width: 100%;
    color: ${palette.white};
    background-color: ${props => props.themeColor.dark};
    margin: 0;
    margin-top:  ${props => props.step === 1 ? '2.5rem' : props.step === 2 ? '2.5rem' : props.step === 3 ? '2.5rem' : props.step === 3.5 ? '2.5rem' : props.step === 4 ? '0.8rem' : '7.5rem'};
    &:hover {
      background-color: ${props => props.themeColor.light};
    }
    @media (max-width: 600px) {
      width: calc(100vw - 5.6rem);
    }
    @media (max-width: 360px) {
      width: calc(100vw - 3.6rem);
    }
  }
`

export default OverviewButton
