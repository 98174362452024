import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import { translate, Trans } from 'react-i18next'

import { trackGoogleAnalyticsEvent } from 'service/tracker'

import GenderPicker from 'components/GenderPicker'
import DropDownMenu from 'components/DropDownMenu'
import ErrorMessage from 'components/ErrorMessage'
import Title from 'lib/elements/Title'
import OverviewButton from 'lib/elements/OverviewButton'

import _ from 'lodash'

import {
  BottomBtnGroup,
  ButtonItem,
  FlexButtons,
  BackAnchor,
  StepWrapper,
  PregnancyPlaceholder
} from '../styles'

import {
  defaultNonOption,
  birthyearList,
  pregnantList,
  tallList,
  weightList,
  GENDER
} from '../../../constants'

class BodyStep extends React.Component {
  constructor (props) {
    super(props)
    let pregnantWeek
    if(
      props.formData.contacts.patient &&
      props.formData.contacts.patient.pregnancy !== undefined &&
      props.formData.contacts.patient.pregnancy.week !== null)
    {
      pregnantWeek = props.formData.contacts.patient.pregnancy.week
    } else {
      pregnantWeek = defaultNonOption
    }

    let birthyear = _.get(props.formData, 'contacts.patient.dateOfBirthYear', defaultNonOption)
    let tall = _.get(props.formData, 'contacts.patient.height', defaultNonOption)
    let weight = _.get(props.formData, 'contacts.patient.weight', defaultNonOption)

    this.state = {
      isBackToEdit: props.isBackToEdit ? props.isBackToEdit : '',
      gender: _.get(props.formData, 'contacts.patient.gender', ''),
      birthyearEl: null,
      pregnantEl: null,
      tallEl: null,
      weightEL: null,
      pregnantName: pregnantWeek,
      pregnant: pregnantWeek,
      birthyear: (birthyear > 0) ? birthyear: defaultNonOption,
      tall: (tall > 0) ? tall + ' cm': defaultNonOption,
      weight: (weight > 0) ? weight + ' kg': defaultNonOption,
      selectedIndexBirthyear: props.formData.contacts.patient ? birthyearList.indexOf(props.formData.contacts.patient.dateOfBirthYear) : birthyearList.indexOf('1970'),
      selectedIndexTall: props.formData.contacts.patient ? tallList.indexOf(props.formData.contacts.patient.height) : tallList.indexOf('170 cm'),
      selectedIndexWeight: props.formData.contacts.patient ? weightList.indexOf(props.formData.contacts.patient.weight) : weightList.indexOf('70 kg'),
      isSetGender: false,
      isSetPregnant: false
    }
  }

  handleGender = (gender) => {
    this.setState({ gender }, () => {
      if (!this.state.isSetGender && gender !== '') {
        this.setState({ isSetGender: true })
      }
    })
  }

  handlePregnantClose = item => {
    this.setState({ pregnantEl: null, pregnantName: item !== 'close' ? item : this.state.pregnantName, pregnant: item !== 'close' ? item : this.state.pregnantName }, () => {
      if (item !== 'close' && !this.state.isSetPregnant) {
        this.setState({ isSetPregnant: true })
      }
    })
  }

  handleTallClose = item => {
    item = (item !== 'close') ? `${item}` : this.state.tall
    this.setState({ selectedIndexTall: parseInt(item), tallEl: null, tall: item })
  }

  handleBirthyearClose = item => {
    item = (item !== 'close') ? `${item}` : this.state.birthyear
    this.setState({ selectedIndexBirthyear: parseInt(item), birthyearEl: null, birthyear: item })
  }

  handleBirthyearOpen = event => {
    let selectedValue = event.currentTarget
    if(selectedValue !== 'Bitte wählen') {
      this.setState({ birthyearEl: selectedValue })
    }
  }

  handleTallOpen = event => {
    let selectedValue = event.currentTarget
    if(selectedValue !== defaultNonOption) {
      this.setState({ tallEl: selectedValue })
    }
  }

  handlePregnantOpen = event => {
    let selectedValue = event.currentTarget
    if(selectedValue !== defaultNonOption){
      this.setState({ pregnantEl: selectedValue })
    }
  }

  handleWeightOpen = event => {
    let selectedValue = event.currentTarget
    if(selectedValue !== defaultNonOption) {
      this.setState({ weightEL: selectedValue})
    }
  }

  handleWeightClose = item => {
    item = (item !== 'close') ? `${item}` : this.state.weight
    this.setState({ selectedIndexWeight: parseInt(item, 10), weightEL: null, weight: item })
  }
  validate = () => {
    this.setState({
      hasActiveValidation: true
    })

    const { birthyear, gender } = this.state

    let success = true

    if (gender.length === 0) {
      trackGoogleAnalyticsEvent('ErrorNoGender - BodyStep', true)
      success = false
    }

    if (birthyear === defaultNonOption) {
      trackGoogleAnalyticsEvent('ErrorNoBirthyear - BodyStep', true)
      success = false
    }

    if (success) {
    this.setState({
      hasActiveValidation: false
    })}
    
    return success
  }

  handleNext = () => {
    if (!this.validate()) {
      return
    }

    const { birthyear, tall, weight, gender, pregnant } = this.state

    const patient = {
      height: parseInt(tall, 10) > 0 ? parseInt(tall, 10) : 0,
      weight: parseInt(weight, 10) > 0 ? parseInt(weight, 10) : 0,
      gender: _.findKey(GENDER, function (item) { return item === gender }).toLowerCase(),
      dateOfBirthYear: parseInt(birthyear, 10) > 0 ? parseInt(birthyear, 10) : 0
    }

    if (this.state.isSetPregnant) {
      patient.pregnancy = {'pregnant': true, week: pregnant}
    }
    trackGoogleAnalyticsEvent('Submit - BodyStep', true)
    this.props.onSetNextStep(patient)
    // clear state in case you came back from edit
    this.props.isBackToEditReset()
  }

  handleBack = () => {
    this.props.isBackToEditReset()
    this.props.onSetBackStep()
  }

  handleBackToOverview = () => {
    if (!this.validate()) {
      return
    }

    this.props.onSetCurrentStep(1)
    this.handleNext()
    this.props.onSetCurrentStep(8)
    this.props.onSetBackToEdit()
  }

  componentDidMount () {
    trackGoogleAnalyticsEvent('PageView - BodyStep', true)
  }

  render () {
    const {
      gender,
      birthyearEl,
      pregnantEl,
      tallEl,
      weightEL,
      pregnantName,
      birthyear,
      tall,
      weight,
      isBackToEdit,
      selectedIndexBirthyear,
      selectedIndexTall,
      selectedIndexWeight,
      hasActiveValidation
    } = this.state

    return (
      <div>
        <StepWrapper>
          <GenderPicker
            gender={gender}
            onSetGender={this.handleGender}
            hasActiveValidation={hasActiveValidation}
          />
          {
            (gender === GENDER.FEMALE || gender === GENDER.DIVERS) &&
            <div>
              <Title>Schwangerschaft (optional)</Title>
              <DropDownMenu
                anchorEl={pregnantEl}
                buttonId='pregnant-btn'
                defaultName={defaultNonOption}
                dropdownList={pregnantList}
                isJson={false}
                itemName={pregnantName}
                menuId='pregnant-menu'
                onHandleClose={this.handlePregnantClose}
                onOpen={this.handlePregnantOpen}
              />
            </div>
          }
          {
            (gender !== GENDER.FEMALE && gender !== GENDER.DIVERS) &&
            <PregnancyPlaceholder />
          }
          <Title>Geburtsjahr</Title>
          <DropDownMenu
            anchorEl={birthyearEl}
            buttonId='birthyear-btn'
            defaultName={defaultNonOption}
            dropdownList={birthyearList}
            isJson={false}
            itemName={birthyear}
            menuId='birthyear-menu'
            onHandleClose={this.handleBirthyearClose}
            onOpen={this.handleBirthyearOpen}
            selectedIndex={selectedIndexBirthyear}
            hasError={hasActiveValidation && birthyear === defaultNonOption}
          />
          {hasActiveValidation && birthyear === defaultNonOption && <ErrorMessage><Trans>Please select a birthyear.</Trans></ErrorMessage>}

          <Title>Körpergröße (optional)</Title>
          <DropDownMenu
            anchorEl={tallEl}
            buttonId='tall-btn'
            defaultName={defaultNonOption}
            dropdownList={tallList}
            isJson={false}
            itemName={tall}
            menuId='tall-menu'
            onHandleClose={this.handleTallClose}
            onOpen={this.handleTallOpen}
            selectedIndex={selectedIndexTall}
          />
          <Title>Körpergewicht (optional)</Title>
          <DropDownMenu
            anchorEl={weightEL}
            buttonId='weight-btn'
            defaultName={defaultNonOption}
            dropdownList={weightList}
            isJson={false}
            itemName={weight}
            menuId='weight-menu'
            onHandleClose={this.handleWeightClose}
            onOpen={this.handleWeightOpen}
            selectedIndex={selectedIndexWeight}
          />
          {
            isBackToEdit !== '' &&
            <OverviewButton
              onClick={this.handleBackToOverview}
              step={3}
              themeColor={this.props.theme.palette.primary}
              variant="contained"
            >
              Zurück zur Übersicht
            </OverviewButton>
          }
        </StepWrapper>
        <BottomBtnGroup>
          <FlexButtons>
            <BackAnchor
              onClick={this.handleBack}
              title='back'
              variant='contained'
              themecolor={this.props.theme.palette.primary.main}
            >
              ◄ Zurück
            </BackAnchor>
            <ButtonItem
              color="primary"
              id='next'
              onClick={this.handleNext}
              title='next'
              variant='contained'
            >
              Weiter
            </ButtonItem>
          </FlexButtons>
        </BottomBtnGroup>
      </div>
    )
  }
}

BodyStep.propTypes = {
  onSetBackStep: PropTypes.func.isRequired,
  onSetNextStep: PropTypes.func.isRequired
}

export default withTheme(BodyStep)
