/**
 * Push event to GTM data layer.
 *
 * @param {string} event
 * @param {object} eventData
 */
 
import { getStringParamFromURL, isPatientServiceEnabled } from 'lib/functions'

const isTrackingActivated = getStringParamFromURL(window.location.search, 'trackingEnabled')

const trackGTMEvent = (event, eventData = {}) => {
  if (!event || typeof window === 'undefined' || typeof window.dataLayer === 'undefined') {
    return
  }

  window.dataLayer.push({ event, ...eventData })
}

const trackedFields = []

const trackGoogleAnalyticsEvent = (action = '', repeated = false, value = 1) => {
  if(isTrackingActivated) {
    if (repeated || !trackedFields.includes(action)) {
      if (!trackedFields.includes(action)) {
        trackedFields.push(action)
      }

      return trackGTMEvent('ga-event', {
        'ga-event-category': isPatientServiceEnabled() ? 'PatientService' : 'Widget',
        'ga-event-action': action,
        'ga-event-value': value
      })
    }
  }
  return null
}

export { trackGoogleAnalyticsEvent }
