import { DrugActionElement } from '../../../constants'
import React, { Component } from 'react'
import { BackAnchor, BottomBtnGroup, ButtonItem, FlexButtons, MainContainer, StepWrapper } from '../../MainPage/styles'
import { ProgressContainer, StyledHeader, StyledTitle, StyledWord } from '../styles'
import Container from 'lib/elements/Container'
import Title from 'lib/elements/Title'
import * as lodash from 'lodash'
import { withTheme } from '@material-ui/core'
import DropDownMenuNew from '../../../components/DropDownMenu-new'
import PropTypes from 'prop-types'

const divStyle = {
  marginLeft: '-42px'
}

export class Step6 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tallEl: null
    }
  }

  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  };

  back = e => {
    e.preventDefault()
    this.props.prevStep()
  };

  handleActionChange = (index) => {
    return (item) => {
      if (item) {
        this.props.onHandleActionAnswer(index, item)
      }
    }
  };

  // render
  render () {
    const { drugs, answerDrugs } = this.props
    return (
      <Container>
        <MainContainer>
          <StyledHeader><StyledTitle>Der Arzneimittelhersteller hat folgende wichtige Rückfragen zu Ihrer Meldung:</StyledTitle></StyledHeader>
          <ProgressContainer>
            <div>
              <StepWrapper>
                <Title><h3> Getroffene Maßnahmen </h3></Title>
                <div>{
                  <ul style={divStyle}>
                    {drugs && drugs.map((drug, index) => {
                      let defaultValue = 'unknown';
                      let value = lodash.get(answerDrugs, `[${index}].action`, drug.action || 'unknown')
                      return [
                        <React.Fragment key={index}>
                          <Title>Welche Maßnahmen haben Sie in Bezug auf <StyledWord>{drug.name}</StyledWord> getroffen?</Title>
                          <DropDownMenuNew
                            dropdownList={DrugActionElement}
                            onChange={this.handleActionChange(index)}
                            selectedValue={value}
                            color={ value === defaultValue ? this.props.theme.palette.primaryGrey : this.props.theme.palette.primary.main }
                            key={index}
                          />
                        </React.Fragment>
                      ]
                    })}
                  </ul>
                }</div>
              </StepWrapper>
              <BottomBtnGroup>
                <FlexButtons>
                  <BackAnchor
                    onClick={this.back}
                    title='back'
                    variant='contained'
                    themecolor={this.props.theme.palette.primary.main}
                  >
                    ◄ Zurück
                  </BackAnchor>
                  <ButtonItem
                    color="primary"
                    id='next'
                    onClick={this.continue}
                    title='next'
                    variant='contained'
                  >
                    Weiter
                  </ButtonItem>
                </FlexButtons>
              </BottomBtnGroup>
            </div></ProgressContainer></MainContainer>
      </Container>
    )
  }
}

Step6.propTypes = {
  drugs: PropTypes.any,
  answerDrugs: PropTypes.any,
  onHandleActionAnswer: PropTypes.any
}

export default withTheme(Step6)
