import styled, { css } from 'styled-components'
import * as palette from 'styles/palette'
import Typography from '@material-ui/core/Typography'

const Title = styled(Typography)`
  &&& {
    font-size: 1.6rem;
    line-height: 1.8rem;
    padding-bottom: 0.9rem;
    margin: 0;
    position: relative;
    padding-top: 1rem;
    color: ${palette.primaryGrey};
    ${props => props.isTitleTxt && css`letter-spacing: -0.3px`};
    @media (max-width: 600px) {
      padding-top: 1.5rem;
    }
  }
`
export default Title
