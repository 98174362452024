import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import FileDownload from 'components/FileDownload'
import FileUpload from 'components/FileUpload'

const useStyles = makeStyles(theme => ({
    fileTemplateContainer: {
        marginBottom: '4rem'
    }
}))

function FileTemplate({ questionId, fileId, templateId, reportId, onHandleFileAnswer, answerData }) {
    const classes = useStyles();

    return (
        <div className={classes.fileTemplateContainer}>
            {(fileId !== undefined && fileId !== '') &&
                <>
                    <div>Bitte laden Sie die Datei herunter und füllen Sie diese aus.</div>
                    <div>
                        <FileDownload
                            fileId={fileId}
                            templateId={templateId}
                            reportId={reportId}
                        />
                    </div>
                </>
            }
            <div>Bitte laden Sie die Datei hoch.</div>
            <div>
                <FileUpload
                    questionId={questionId}
                    templateId={templateId}
                    reportId={reportId}
                    onHandleFileAnswer={onHandleFileAnswer}
                    answerData={answerData}
                />
            </div>
        </div>
    )
}

export default FileTemplate
