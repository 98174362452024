import React, { Component } from 'react'
import axios from 'axios'
import lodash from 'lodash'
import { Icon } from '@iconify/react'
import barcodeIcon from '@iconify/icons-mdi/barcode'
import { withTheme } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  BackAnchor,
  BottomBtnGroup,
  ButtonItemNext,
  FlexButtons,
  MainContainer,
  ProgressContainer,
  StepWrapper
} from '../../MainPage/styles'
import { StyledHeader, StyledTitle, SubmitSpinner } from '../styles'
import Container from 'lib/elements/Container'
import Title from 'lib/elements/Title'
import { hasDose } from 'lib/functions'
import config from '../../../config'
import PropTypes from 'prop-types'
import {
  StyledAccessibility,
  StyledFavoriteBorder,
  StyledCheckCircle,
  StyledWarning,
  StyledInvertColors,
  StyledChat,
  StyledHowToReg,
  StyledPhone
} from '../overview/styles'
import { DrugDoseUnit, DrugDoseInterval, SideEffectStatus, DrugAction } from '../../../constants'

const divStyle = {
  marginTop: '9rem',
  marginBottom: '0rem',
  minHeight: '35rem'
}

const buttonStyle = {
  marginTop: '0rem',
  marginBottom: '0rem',
  padding: '0rem'
}

const hrStyle = {
  border: '0.6px #d2cdcd solid'
}

const svgStyle = {
  verticalAlign: 'middle',
  marginLeft: '-3px',
  marginRight: 33 + 'px'
}

const backButtonStyle = {
  marginTop: '4.5rem'
}

let HEADERS = {
  'Content-Type': 'application/json',
  'X-API-Version': '3.0.0',
  'X-Event-Version': '3.0.0',
  'X-Event-App-Created': 'med-app-widget',
  'X-Event-Role': 'patient'
}

export class Confirm extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  state = {
    loading: false
  }

  back = e => {
    e.preventDefault()
    this.props.prevStep()
  };

  answerData = async () => {
    let answeredDrugs = this.props.answerData.drugs
    let answeredEffects = this.props.answerData.effects
    let answerDataDrugs = (answeredDrugs || []).filter((el) => el != null)
    let answerDataEffects = (answeredEffects || []).filter((el) => el != null)
    let answeredContactDetails = this.props.answerData.contact

    let payloadToSend = {}

    if (answerDataDrugs.length) {
      payloadToSend.drugs = answerDataDrugs.map((answerObj) => {
        if (answerObj.action === 'unknown') {
          delete answerObj.action
        }
        if (answerObj.dose) {
          if (isNaN(answerObj.dose.amount)) {
            delete answerObj.dose.amount
          }
          if (answerObj.dose.interval === 'unknown') {
            delete answerObj.dose.interval
          }
          if (answerObj.dose.unit === 'unknown') {
            delete answerObj.dose.unit
          }
          if (!answerObj.dose.amount || !answerObj.dose.interval || !answerObj.dose.unit) {
            delete answerObj.dose
          }
        }
        return answerObj
      })
        .filter(answerObj => Object.keys(answerObj).length)
    }

    if (answerDataEffects.length) {
      payloadToSend.effects = answerDataEffects.map((answerObj) => {
        if (answerObj.status === 'unknown') {
          delete answerObj.status
        }
        return answerObj
      })
    }

    let answeredData = payloadToSend

    let headers = {
      ...HEADERS,
      'Authorization': `Bearer ${this.props.token}`
    }
    if (!lodash.isEmpty(answeredData)) {
      return axios.patch(
        `${config.EVENTSTORE_BASE_URL}/streams/report/${this.props.reportId}/update-report-data`, answeredData, { headers: headers }
      )
    }
  }

  getDrugName = (drugId) => {
    // TODO: this fixes part of MED-1363, but is hacky and should be done better in the new version
    const originalDrug = this.props.data.drugs.filter(drug => drug._id === drugId).pop()
    if (!originalDrug) {
      throw new Error('Unexpected error - could not find drug ' + drugId)
    }
    return originalDrug.name
  }

  getDrugAnswerForId = (drugId) => {
    // TODO: this fixes part of MED-1363, but is hacky and should be done better in the new version
    return this.props.answerData.drugs?.filter(drug => drug && drug._id === drugId).pop()
  }

  getEffectAnswerForId = (effectId) => {
    // TODO: this fixes part of MED-1363, but is hacky and should be done better in the new version
    return this.props.answerData.effects?.filter(effect => effect._id === effectId).pop()
  }

  getDosageAnswer = () => {
    let drugs = lodash.get(this.props, 'answerData.drugs', [])
    let arrayDoses = drugs.filter(drug => lodash.has(drug, 'dose'))
      .map((drug) => {
        if (isNaN(drug.dose.amount)) {
          delete drug.dose.amount
        }
        if (drug.dose.interval === 'unknown') {
          delete drug.dose.interval
        }
        if (drug.dose.unit === 'unknown') {
          delete drug.dose.unit
        }
        if (Object.keys(drug.dose).length === 0) {
          delete drug.dose
        }
        return { dose: drug.dose }
      })

    if (this.props.dosageQuestion) {
      let answerText
      if (arrayDoses.length > 0) {
        answerText = this.props.answerData.drugs.filter(hasDose)
          .map((drugAnswerObject) => {
            let unitAnswer = drugAnswerObject.dose.unit ? DrugDoseUnit[drugAnswerObject.dose.unit] : ' '
            let intervalAnswer = drugAnswerObject.dose.interval ? DrugDoseInterval[drugAnswerObject.dose.interval] : ' '
            let amountAnswer = drugAnswerObject.dose.amount ? drugAnswerObject.dose.amount : ' '
            let drugName = this.getDrugName(drugAnswerObject._id)
            return `${drugName}: ${amountAnswer} ${unitAnswer} ${intervalAnswer}`
          })
          .join('\n')
      }
      let questionId = this.props.dosageQuestion._id
      return {
        _id: questionId,
        answer: { text: answerText || 'K. A.' }
      }
    }
  }

  getBatchNumberAnswer = () => {
    let answers = this.props.data.drugs
      .map((drug) => {
        let name = drug.name
        let answer = lodash.get(this.getDrugAnswerForId(drug._id), 'codes.batchNumber')
        if (!answer) {
          answer = lodash.get(drug, `codes.batchNumber`)
        }
        return { name, answer }
      })
      .filter(({ answer }) => answer)

    if (this.props.batchNumberQuestion) {
      let answerText
      if (answers.length > 0) {
        answerText = answers.map(({ name, answer }) => `${name}: ${answer}`)
          .join('\n')
      }
      let questionId = this.props.batchNumberQuestion._id
      return {
        _id: questionId,
        answer: { text: answerText || 'K. A.' }
      }
    }
  }

  getIndicationAnswer = () => {
    let answers = this.props.data.drugs
      .map((drug) => {
        let name = drug.name
        let answer = lodash.get(this.getDrugAnswerForId(drug._id), 'indications[0].name')
        if (!answer) {
          answer = lodash.get(drug, `indications[0].name`)
        }
        return { name, answer }
      })
      .filter(({ answer }) => answer)

    if (this.props.indicationQuestion) {
      let answerText
      if (answers.length > 0) {
        answerText = answers.map(({ name, answer }) => `${name}: ${answer}`)
          .join('\n')
      }
      let questionId = this.props.indicationQuestion._id
      return {
        _id: questionId,
        answer: { text: answerText || 'K. A.' }
      }
    }

  }

  getDrugActionAnswer = () => {
    let answers = this.props.data.drugs.map((drug) => {
      let name = drug.name
      let action = lodash.get(this.getDrugAnswerForId(drug._id), 'action')
      if (!action) {
        action = lodash.get(drug, `action`)
      }
      return { name, action }
    })
      .filter((answerObj) => {
        return answerObj.answer !== 'unknown'
      })
      .map(answerObj => {
        let answer = answerObj.action ? DrugAction[answerObj.action].shortName : ''
        let name = answerObj.name
        return { name, answer }
      })
      .filter(({ answer }) => answer)

    if (this.props.drugActionQuestion) {
      let answerText
      if (answers.length > 0) {
        answerText = answers.map(({ name, answer }) => `${name}: ${answer}`)
          .join('\n')
      }
      let questionId = this.props.drugActionQuestion._id
      return {
        _id: questionId,
        answer: { text: answerText || 'K. A.' }
      }
    }
  }

  getEffectStatusAnswer = () => {
    let answers = this.props.data.effects
      .map((effect, index) => {
        let name = effect.name
        let status = lodash.get(this.getEffectAnswerForId(effect._id), `status`)
        if (!status) {
          status = lodash.get(effect, `status`)
        }
        return { name, status }
      })
      .filter((answerObj) => {
        return answerObj.status !== 'unknown'
      })
      .map(answerObj => {
        let answer = answerObj.status ? SideEffectStatus[answerObj.status] : ''
        let name = answerObj.name
        return { name, answer }
      })
      .filter(({ answer }) => answer)

    if (this.props.effectQuestion) {
      let answerText
      if (answers.length > 0) {
        answerText = answers.map(({ name, answer }) => `${name}: ${answer}`)
          .join('\n')
      }
      let questionId = this.props.effectQuestion._id
      return {
        _id: questionId,
        answer: { text: answerText || 'K. A.' }
      }
    }
  }

  getCustomAnswers = () => {
    let answers = []
    if (this.props.customQuestions) {
      for (const question of this.props.customQuestions) {
        let answerText
        let fileId
        let answer = this.props.customAnswerData && this.props.customAnswerData[question._id]
        if (answer) {
          if (typeof (answer) === 'object') {
            answerText = answer.name
            fileId = answer.id
          } else {
            answerText = answer
          }
        }
        answers.push({
          _id: question._id,
          answer: {
            text: answerText || 'K. A.',
            ...(fileId && { fileId })
          }
        })
      }
    }
    return answers
  }

  getContactDetailsAnswer = () => {
    const answeredContact = lodash.get(this.props, 'answerData.contact')

    if (this.props.contactDetailsQuestion) {
      return {
        _id: this.props.contactDetailsQuestion._id,
        answer: { text: answeredContact || 'K. A.' }
      }
    }
  }

  sendAnswers = async () => {
    let headers = {
      ...HEADERS,
      'Authorization': `Bearer ${this.props.token}`
    }

    let answers = [
      this.getDosageAnswer(),
      this.getBatchNumberAnswer(),
      this.getIndicationAnswer(),
      this.getEffectStatusAnswer(),
      this.getDrugActionAnswer(),
      this.getContactDetailsAnswer()
    ]
    this.getCustomAnswers().forEach(answer => answers.push(answer))

    let nonEmptyAnswers = lodash.compact(answers)
    return axios.post(`${config.EVENTSTORE_BASE_URL}/patient/report/${this.props.reportId}/questions/fromPharma/answerQuestions`, nonEmptyAnswers, { headers: headers })
  }

  handleSubmit = async (e) => {
    this.setState({ loading: true })
    e.preventDefault()
    await this.sendAnswers()
    await this.answerData()
    this.props.nextStep()
  }

  // render method
  render() {
    const { loading } = this.state

    let drugs = lodash.get(this.props, 'answerData.drugs', [])
    let dosage = drugs.filter(drug => lodash.get(drug, 'dose'))
      .map((answerObj) => {
        if (isNaN(answerObj.dose.amount)) {
          delete answerObj.dose.amount
        }
        if (answerObj.dose.interval === 'unknown') {
          delete answerObj.dose.interval
        }
        if (answerObj.dose.unit === 'unknown') {
          delete answerObj.dose.unit
        }
        if (Object.keys(answerObj.dose).length === 0) {
          delete answerObj.dose
          delete answerObj._id
        }
        return answerObj
      })
      .filter(answerObj => Object.keys(answerObj).length)

    let action = drugs.filter(drug => lodash.get(drug, 'action'))
      .map((drug) => `${drug.action}`)
      .filter((answer) => answer !== 'unknown')

    let batchNumber = drugs.filter(drug => lodash.get(drug, 'codes.batchNumber'))
      .map((drug) => {
        return { batchNumber: drug.codes.batchNumber }
      })
    let indications = drugs.filter((drug) => lodash.has(drug, 'indications'))
      .map((drug) => {
        return { name: drug.indications }
      })
    let effects = lodash.get(this.props, 'answerData.effects', [])
    let status = effects.filter(effect => lodash.get(effect, 'status'))
      .map((effect) => `${effect.status}`)
      .filter((answer) => answer !== 'unknown')
    let customData = Object.keys(this.props.customAnswerData)
    const contactDetailsAnswer = lodash.get(this.props, 'answerData.contact')

    // only show the asked questions
    let showDosageStep = this.props.dosageQuestion
    let showIndicationsStep = this.props.indicationQuestion
    let showBatchNumberStep = this.props.batchNumberQuestion
    let showStatusStep = this.props.effectQuestion
    let showCustomStep = this.props.customQuestions
    let showDrugActionQuestion = this.props.drugActionQuestion
    const showContactDetailsQuestion = this.props.contactDetailsQuestion

    return (
      <Container>
        <MainContainer>
          <StyledHeader><StyledTitle>Der Arzneimittelhersteller hat folgende wichtige Rückfragen zu Ihrer Meldung:</StyledTitle></StyledHeader>
          <ProgressContainer>
            <div >
              <StepWrapper style={divStyle}>

                {showDosageStep &&
                  <React.Fragment>
                    <Title color={dosage.length > 0 ? 'primary' : 'gray'} >
                      <StyledInvertColors style={{ marginRight: 38 + 'px' }} /> Dosierung
                    {dosage.length > 0 ? <StyledCheckCircle style={{ marginLeft: 147 + 'px' }} /> : <StyledWarning style={{ marginLeft: 147 + 'px' }} />}
                    </Title>
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                {showIndicationsStep &&
                  <React.Fragment>
                    <Title color={indications.length > 0 ? 'primary' : 'gray'} >
                      <StyledFavoriteBorder style={{ marginRight: 38 + 'px' }} /> Grund der Einnahme
                    {indications.length > 0 ? <StyledCheckCircle style={{ marginLeft: 72 + 'px' }} /> : <StyledWarning style={{ marginLeft: 72 + 'px' }} />}
                    </Title>
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                {showBatchNumberStep &&
                  <React.Fragment>
                    <Title color={batchNumber.length > 0 ? 'primary' : 'gray'} >
                      <Icon style={svgStyle} icon={barcodeIcon} width='2em' height='2em' /> Chargennummer
                    {batchNumber.length > 0 ? <StyledCheckCircle style={{ marginLeft: 92 + 'px' }} /> : <StyledWarning style={{ marginLeft: 92 + 'px' }} />}
                    </Title>
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                {showStatusStep &&
                  <React.Fragment>
                    <Title color={status.length > 0 ? 'primary' : 'gray'} >
                      <StyledAccessibility style={{ marginRight: 38 + 'px' }} />Ausgang der Nebenwirkung
                    {status.length > 0 ? <StyledCheckCircle style={{ marginLeft: 25 + 'px' }} /> : <StyledWarning style={{ marginLeft: 25 + 'px' }} />}
                    </Title>
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                {showCustomStep &&
                  <React.Fragment>
                    <Title color={customData.length > 0 ? 'primary' : 'gray'} >
                      <StyledChat style={{ marginRight: 38 + 'px' }} />Sonstige Rückfrage(n)
                    {customData.length > 0 ? <StyledCheckCircle style={{ marginLeft: 67 + 'px' }} /> : <StyledWarning style={{ marginLeft: 67 + 'px' }} />}
                    </Title>
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                {
                  showDrugActionQuestion &&
                  <React.Fragment>
                    <Title color={action.length > 0 ? 'primary' : 'gray'} >
                      <StyledHowToReg style={{ marginRight: 38 + 'px' }} />Getroffene Maßnahmen
                      {action.length > 0 ? <StyledCheckCircle style={{ marginLeft: 51 + 'px' }} /> : <StyledWarning style={{ marginLeft: 51 + 'px' }} />}
                    </Title>
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

                {showContactDetailsQuestion &&
                  <React.Fragment>
                    <Title color={contactDetailsAnswer ? 'primary' : 'gray'} >
                      <StyledPhone style={{ marginRight: 38 + 'px' }} />Kontaktaufnahme
                      {contactDetailsAnswer ? <StyledCheckCircle style={{ marginLeft: 95 + 'px' }} /> : <StyledWarning style={{ marginLeft: 95 + 'px' }} />}
                    </Title>
                    <div><hr style={hrStyle} /></div>
                  </React.Fragment>
                }

              </StepWrapper>

              {loading && <SubmitSpinner aria-label={'Circular progress spinner'}><CircularProgress /></SubmitSpinner> ||
                <BottomBtnGroup style={buttonStyle}>
                  <FlexButtons>
                    <BackAnchor
                      style={backButtonStyle}
                      onClick={this.back}
                      title='back'
                      variant='contained'
                      themecolor={this.props.theme.palette.primary.main}
                    >
                      ◄ Zurück
                    </BackAnchor>
                    <ButtonItemNext
                      color="primary"
                      id='next'
                      title='next'
                      variant='contained'
                      onClick={this.handleSubmit}
                    >
                      Antworten absenden
                    </ButtonItemNext>
                  </FlexButtons>
                </BottomBtnGroup>}

            </div>
          </ProgressContainer>
        </MainContainer>
      </Container>
    )
  }
}

Confirm.propTypes = {
  answerData: PropTypes.any,
  dosageQuestion: PropTypes.string,
  indicationQuestion: PropTypes.string,
  batchNumberQuestion: PropTypes.any,
  effectQuestion: PropTypes.any
}

export default withTheme(Confirm)
