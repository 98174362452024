import styled, { css } from 'styled-components'
import * as palette from 'styles/palette'
import Checkbox from '@material-ui/core/Checkbox'

export const InputContainer = styled.div`
  position: relative;
`

export const ReleaseDoctorContainer = styled.div`
  padding-top: 1.7rem;
  display: flex;
  svg {
    color: ${props => props.color};
    background: ${palette.white};
    fill: ${props => props.color};
  }
`
export const ReleaseDoctorTxt = styled.div`
  font-size: 1.4rem;
  color: ${palette.darkGrey};
  @media (max-width: 600px) {
    letter-spacing: -1.05px;
  }
`

export const CheckBoxItem = styled(Checkbox)`
  &&& {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
`