import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import * as palette from '../../styles/palette'

const MedTextAreaContainer = styled.textarea`
  padding: .8rem 1.2rem;
  border-radius: .5rem;
  height: 13rem;
  background-color: transparent;
  border: 1px solid ${props => props.borderColor};
  color: ${props => props.mainColor};
  :placeholder {
    color: ${props => props.placeholderColor};
    opacity: 0.4;
  }
  &:focus, &:active {
    outline: none;
    border: 2px solid ${props => props.focusColor};
    padding-left: calc(1.2rem - 1px);
  }
  margin-top: .7rem;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.5rem;
`

const MedTextArea = ({
  opinionTxt,
  placeholder,
  onSetText,
  textareaId,
  theme
}) => {
  return (
    <MedTextAreaContainer
      onChange={onSetText}
      placeholder={placeholder}
      value={opinionTxt}
      id={textareaId}
      mainColor={theme.palette.text.primary}
      placeholderColor={theme.palette.text.secondary}
      focusColor={theme.palette.primary.main}
      borderColor={palette.primaryInputBorder}
      maxLength="1000"
    />
  )
}

MedTextArea.propTypes = {
  onSetText: PropTypes.func.isRequired,
  opinionTxt: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
}

export default withTheme(MedTextArea)
