import React, { Component } from 'react'
import { withTheme } from '@material-ui/core'
import Title from 'lib/elements/Title'
import Container from 'lib/elements/Container'
import {
  BottomBtnGroup,
  ButtonItemNext,
  FlexButtons,
  BackAnchor,
  StepWrapper,
  MainContainer,
  ProgressContainer
} from '../../MainPage/styles'
import { StyledHeader, StyledTitle, StyledWord } from '../styles'
import MedTextAreaFollowup from '../../../components/MedTextAreaFollowup'
import PropTypes from 'prop-types'
import { InputContainer, Step2List } from './styles'
import * as lodash from 'lodash'

const spanStyle = {
  textAlign: 'right',
  marginBottom: '1rem',
  display: 'block',
  fontSize: '1.5rem',
  color: '#6d706e'
}

const backButtonStyle = {
  marginTop: '4.5rem'
}

export class Step2 extends Component {

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  };

  back = e => {
    e.preventDefault()
    this.props.prevStep()
  };

  handleSetMessageTxt = (index) => {
    return (event) => {
      this.props.handleAddText(index, event.target.value)
    }
  }

  render () {
    const { drugs, answerDrugs } = this.props
    return (
      <Container>
        <MainContainer>
          <StyledHeader><StyledTitle>Der Arzneimittelhersteller hat folgende wichtige Rückfragen zu Ihrer Meldung:</StyledTitle></StyledHeader>
          <ProgressContainer>
            <div>
              <StepWrapper>
                <Title><h3> Grund der Einnahme</h3></Title>
                <p>Warum nehmen Sie ...</p>
                {
                  <Step2List>
                    {drugs && drugs.map((drug, index) => {
                      let textInput = lodash.get(answerDrugs, `[${index}].indications[0].name`, lodash.get(drugs, `[${index}].indications[0].name`, ''))
                      let charactersLeft = 200 - textInput.length
                      return [
                        <React.Fragment>
                          <InputContainer>
                            <Title>... <StyledWord>{drug.name}</StyledWord>?</Title>
                            <span style={spanStyle}> { charactersLeft } Zeichen übrig </span>
                            <MedTextAreaFollowup
                              onSetText={this.handleSetMessageTxt(index)}
                              opinionTxt={textInput}
                              placeholder={'Bitte geben Sie hier den Grund für die Einnahme Ihres Medikamentes ein (200 Zeichen übrig)'}
                              textareaId={'extraComment'}
                            />
                          </InputContainer>
                        </React.Fragment>
                      ]
                    })}
                  </Step2List>
                }
              </StepWrapper>
              <BottomBtnGroup>
                <FlexButtons>
                  <BackAnchor
                    style={backButtonStyle}
                    onClick={this.back}
                    title='back'
                    variant='contained'
                    themecolor={this.props.theme.palette.primary.main}
                  >
                      ◄ Zurück
                  </BackAnchor>
                  <ButtonItemNext
                    color="primary"
                    id='next'
                    onClick={this.continue}
                    title='next'
                    variant='contained'
                  >
                      Weiter
                  </ButtonItemNext>
                </FlexButtons>
              </BottomBtnGroup>
            </div>
          </ProgressContainer>
        </MainContainer>
      </Container>
    )
  }
}

Step2.propTypes = {
  drugs: PropTypes.bool.isRequired,
  answerDrugs: PropTypes.bool.isRequired
}

export default withTheme(Step2)
