import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

export const Container = styled.div`
`

export const HowOftenContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width:380px){
    flex-wrap: wrap;
  }
`

export const HowOftenSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: ${props => props.width};
`

export const DropDownLabel = styled.label`
  display: block;
  font-size: 1.3rem;
  color: ${props => props.color};
  padding-left: 0.7rem;
`

export const UnitInput = styled.input`
  background-color: transparent;
  border: 1px solid ${props => props.borderColor};
  color: ${props => props.mainColor};
  ::placeholder {
    color: ${props => props.borderColor};
  }
  &:focus, &:active {
    outline: none;
    border: 2px solid ${props => props.focusColor};
    padding: calc(1.2rem - 1px);
  }
  outline: none;
  width: auto;
  height: 4rem;
  border-radius: 5px;
  padding: 1.2rem;
`

export const UnitLabel = styled.label`
  font-size: 1.3rem;
  color: ${props => props.color};
  padding-left: 0.3rem;
`
