import styled from 'styled-components'
import * as palette from 'styles/palette'
import Menu from '@material-ui/core/Menu'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

export const MenuList = styled(Menu)`
  &&& {
    & > div {
      border-radius: .7rem;
    }
    ul {
      width: ${props => props.width};
      li {
        padding: 10px 12px;
        font-size: 1.4rem;
        font-weight: normal;
        border-bottom: .25px solid;
        line-height: 1.6rem;
        border-color: ${palette.searchBorderColor};
        &:last-child {
          border-bottom: 0;
        }
      }
      & > div > li {
        border-bottom: .25px solid ${palette.searchBorderColor};
      }
    }
  }
`

export const ArrowDropDownIconItem = styled(ArrowDropDownIcon)`
  color: ${props => props.color};
`

export const Item = styled.div`
  outline: none;
`

export const ItemTitle = styled.p`
  font-size: 11px;
  font-weight: bold;
  color: ${palette.darkGreen};
  padding-left: 1.6rem;
  text-transform: uppercase;
`
