import styled from 'styled-components'
import * as palette from 'styles/palette'

export const Secrecy = styled.span`
  color: ${props => props.theme.palette.primary.main};
  font-size: 1.4rem;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`
