import styled from 'styled-components'
import { Select } from '@material-ui/core'

export const StyledSelect = styled(Select)`
  &&& {
    width: 100%;
    border: 1px solid ${props => props.borderColor};
      color: ${props => props.greyColor};
      ::placeholder {
        color: ${props => props.borderColor};
      }
    div > :focus, &:active {
        background-color: #ffff;
        color: ${props => props.mainColor};
      }

    border-radius: .7rem;
    padding: 0 1.5rem;
    padding-right: .5rem;
    min-height: 4rem;
    height: 4rem;
    & > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: none;
    }
  }
`

export const StyledText = styled.p`
    font-weight: normal;
    margin: 1px;
`
