import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'

import { getBooleanParamFromURL } from 'lib/functions'
import Title from 'lib/elements/Title'

import MuiAutoComplete from '../MuiAutoComplete'
import * as palette from '../../styles/palette'
import ErrorMessage from '../ErrorMessage'
import {
  HelpIcon,
  ReactTooltipStyled
} from '../DoctorInput/styles'
import {
  Container,
} from './../MedSearch/styles'

import { SearchContainerSideEffects } from '../SideEffectInput/styles'

class MedicationsInput extends React.Component {
  constructor(props) {
    super(props);

    this.componentRef = React.createRef();
  }

  scrollComponentToTop = () => {
    if(this.componentRef && this.componentRef.current) {
      window.scroll({
        top: this.componentRef.current.offsetHeight, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }

  render () {
    const { 
      value, 
      onSearch, 
      onClear, 
      onSelect, 
      placeholder, 
      title, 
      theme, 
      searchId, 
      errorText, 
      hasActiveValidation, 
      tooltipId, 
      tooltipText, 
      items, 
      addedMedicines, 
      onCountryChange, 
      isInputValid } = this.props

    return (
      <Container
        ref={this.componentRef}
      >
        <Title color='textPrimary'>
          {title}
          {tooltipId && tooltipText && <a data-tip data-for={tooltipId + 'Tooltip'}><HelpIcon /></a> }
          {tooltipId && tooltipText && <ReactTooltipStyled
            id={tooltipId + 'Tooltip'}
            type='dark'
            place='top'
            effect='solid'
            scrollHide={false}
          >
            <span>{tooltipText}</span>
          </ReactTooltipStyled> }
        </Title>
        <SearchContainerSideEffects
          active={value === '' ? null : value}
          lightColor={theme.palette.primary.main}
          mainColor={theme.palette.text.primary}
          borderColor={palette.primaryInputBorder}
          focusColor={theme.palette.primary.main}
          placeholderColor={theme.palette.text.secondary}
          errorColor={theme.palette.error.main}
          className={hasActiveValidation && value === '' ? 'error' : ''}
        >
          <Container>
            <MuiAutoComplete
              inputId={searchId}
              placeholder={placeholder}
              onChange={onSearch}
              sideEffects={items}
              value={value}
              onSelect={onSelect}
              onClear={onClear}
              onInputFocus={this.scrollComponentToTop}
              isAutoFocusEnabled={getBooleanParamFromURL(window.location.search, 'autoFocusSearch')}
              countrySelectProps={{
                enabled: true,
                disableSelection: addedMedicines.length,
                onCountryChange: onCountryChange
              }}
            />
          </Container>
        </SearchContainerSideEffects>
        { hasActiveValidation && (value === '' || !isInputValid) && errorText !== '' && <ErrorMessage><Trans>{ errorText }</Trans></ErrorMessage> }
      </Container>
    )
  }

}

MedicationsInput.propTypes = {
  onClear: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  searchId: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  hasActiveValidation: PropTypes.bool,
  errorText: PropTypes.string.isRequired,
  tooltipId: PropTypes.string,
  tooltipText: PropTypes.string,
  theme: PropTypes.object.isRequired
}

export default withTheme(MedicationsInput)
