import React, { Component } from 'react'
import { withTheme } from '@material-ui/core'
import {
  BackAnchor,
  BottomBtnGroup,
  ButtonItemNext,
  FlexButtons,
  MainContainer,
  ProgressContainer,
  StepWrapper
} from '../../MainPage/styles'
import { StyledHeader, StyledTitle } from '../styles'
import { ListItem } from './styles'
import Title from 'lib/elements/Title'
import Container from 'lib/elements/Container'
import MedTextInputFollowup from '../../../components/MedTextInputFollowup'

const backButtonStyle = {
  marginTop: '4.5rem'
}

export class StepContactDetails extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }

  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  };

  back = e => {
    e.preventDefault()
    this.props.prevStep()
  };

  handleSetContactDetails = () => {
    return (event) => {
      this.props.handleAddContactDetails(event.target.value)
    }
  }

  render () {
    const { answerContact } = this.props

    return (
      <Container>
        <MainContainer>
          <StyledHeader><StyledTitle>Der Arzneimittelhersteller hat folgende wichtige Rückfragen zu Ihrer Meldung:</StyledTitle></StyledHeader>
          <ProgressContainer>
            <div>
              <StepWrapper>
                <Title><h3>Kontaktaufnahme</h3></Title>
                <Title>Dürfen wir Sie direkt für Rückfragen kontaktieren? Bitte geben Sie hierzu eine Telefonnummer an:</Title>
                {
                  <ListItem>
                        <React.Fragment>
                          <MedTextInputFollowup
                            onSetText={this.handleSetContactDetails()}
                            opinionTxt={answerContact}
                            placeholder={'z. B. +49 (0) 999 9999 9999'}
                            textareaId={'contactDetails'}
                          />
                        </React.Fragment>
                  </ListItem>
                }
              </StepWrapper>
              <BottomBtnGroup>
                <FlexButtons>
                  <BackAnchor
                    style={backButtonStyle}
                    onClick={this.back}
                    title='back'
                    variant='contained'
                    themecolor={this.props.theme.palette.primary.main}
                  >◄ Zurück
                  </BackAnchor>
                  <ButtonItemNext
                    color="primary"
                    id='next'
                    onClick={this.continue}
                    title='next'
                    variant='contained'
                  >
                    Weiter
                  </ButtonItemNext>
                </FlexButtons>
              </BottomBtnGroup>
            </div>
          </ProgressContainer>
        </MainContainer>
      </Container>
    )
  }
}

export default withTheme(StepContactDetails)
