import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from 'components/DialogTitle'

import {
  Pop_Up
} from './styles'

class LegalDisclaimerDialog extends React.Component {
  state = {
    open: false
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render () {
    return (
      <React.Fragment >
        <Dialog onClose={this.handleClose} open={this.state.open} onRequestClose={this.handleClose}>
          <DialogTitle theme={this.props.theme} onClose={this.handleClose}>Einordnung einer Nebenwirkung als schwerwiegend oder nicht schwerwiegend:</DialogTitle>
          <DialogContent>
          <p>
            Die Einordnung einer Nebenwirkung stellt keine medizinische Beurteilung der Situation des jeweiligen Patienten im Einzelfall dar und trifft daher auch keine Aussage darüber, welche Bedeutung eine Nebenwirkung im Einzelfall für den jeweiligen Patienten tatsächlich hat. Patienten sollten die Anwendung eines Arzneimittels nicht eigenmächtig beenden oder ändern, sondern Bedenken mit ihrem Arzt oder Apotheker besprechen. Die hier angezeigte Einordnung der jeweiligen Nebenwirkung als schwerwiegend oder nicht schwerwiegend orientiert sich vornehmlich an den IME-Kriterien für Meldungen von Verdachtsfällen der Europäischen Arzneimittelagentur (Europäische Arzneimittelagentur, „Inclusion/exclusion criteria for the ,Important Medical Events‘ list“, Stand: 16. September 2019), anhand deren eine interne Datenbank erstellt wurde. Diese Liste wurden in wenigen Fällen um Daten für die Nebenwirkungen ergänzt, bei denen keine klare Einordnung anhand den IME-Kriterien möglich ist.
          </p>
          </DialogContent>
        </Dialog>
          
            <Pop_Up onClick={this.handleOpen}>Rechtliche Hinweise</Pop_Up> 

      </React.Fragment>
    )
  }
}

export default LegalDisclaimerDialog
