import styled from 'styled-components'
import Button from '@material-ui/core/Button'


const MenuBtn = styled(Button)`
  &&& {
    width: 100%;
    border: 1px solid ${props => props.bordercolor};
    color: ${props => props.greyColor}; 

    &:focus, &:active {
      outline: none;
      border: 2px solid ${props => props.focuscolor};
      padding: 0 calc(0.5rem - 1px) 0 calc(1.5rem - 1px);
    }

    &.error {
      border: 2px solid ${props => props.errorcolor};
      padding: 0 calc(0.5rem - 1px) 0 calc(1.5rem - 1px);
    }

    border-radius: .7rem;
    padding: 1px .5rem 1px 1.5rem;
    min-height: 4rem;
    height: 4rem;
    & > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: none;
    }
  }
`

export default MenuBtn
