
export default {
  IDENTITY_BASE_URL: process.env.REACT_APP_IDENTITY_BASE_URL,
  EVENTSTORE_BASE_URL: process.env.REACT_APP_EVENTSTORE_BASE_URL,
  AUTOCOMPLETE_BASE_URL: process.env.REACT_APP_AUTOCOMPLETE_BASE_URL,
  DOCTOR_AUTOCOMPLETE_BASE_URL: process.env.REACT_APP_DOCTOR_AUTOCOMPLETE_BASE_URL,
  AUTOCOMPLETE_SEARCH_LIST_SIZE: 30,
  GTM_ID: process.env.REACT_APP_GTM_ID,
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID,
  HOTJAR_SV: process.env.REACT_APP_HOTJAR_SV,
  sentryDSN: process.env.REACT_APP_sentryDSN
}
