import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import { trackGoogleAnalyticsEvent } from 'service/tracker'

import {
  BottomBtnGroup,
  ButtonItem
} from '../styles'

import {
  Container,
  CheckIconItem,
  InfoIconItem,
  ResultHeadline,
  Description
} from './styles'

const ThanksStep = (props) => {
    useEffect(() => {
        trackGoogleAnalyticsEvent('PageView - ThanksStep', true)
    }, []);

    const handleNext = () => {
        trackGoogleAnalyticsEvent('Submit - ThanksStep', true)
        props.onSetNextStep({ })
    }

    const trackOnClick = () => {
        trackGoogleAnalyticsEvent('Teleclinic - ThanksStep', true)
    }

    return (
        <Container>
            <CheckIconItem theme={props.theme} />
            <ResultHeadline theme={props.theme}>
                Vielen Dank!
            </ResultHeadline>
            <Description color={props.theme.palette.text.primary}>
                Innerhalb einer Stunde erhalten Sie eine Bestätigungsemail. Mit Ihrer Meldung
                leisten Sie einen wichtigen Beitrag zur Arzneimittelsicherheit!
            </Description>
            <Description color={props.theme.palette.text.primary} fontWeight={700}>
                Wir wünschen Ihnen für Ihre Gesundheit alles Gute!
                Ihr Team von Nebenwirkungen.de 💐
            </Description>

            <Divider
                style={{
                    backgroundColor: props.theme.palette.text.dark,
                    margin: '1.5rem 0'
                }} />

            <InfoIconItem theme={props.theme} />

            <ResultHeadline theme={props.theme}>
              Sprich jetzt kostenfrei mit einem Online-Arzt über deine Symptome
            </ResultHeadline>
            <Description color={props.theme.palette.text.primary}>
              Lass dich direkt online von Fachärzten der Teleclinic zu deinen Symptomen und alternativen Therapien beraten.
              Das eRezept gibt es danach auf’s Handy und das Medikament nach Hause geliefert.
              <br /> <br />
              Für alle Versicherte kostenfrei 🍀.
            </Description>
            <BottomBtnGroup>
                <ButtonItem
                    onClick={trackOnClick}
                    href="https://www.teleclinic.com/lp/nebenwirkungen/?utm_source=Partner&utm_medium=cpl&utm_campaign=1190-nebenwirkungen.de"
                    target="_blank"
                    rel="noopener"
                    width='100%'
                    color="primary"
                    title='next'
                    variant='contained'
                    id='next' >
                    Jetzt mit Online-Arzt sprechen
                </ButtonItem>
            </BottomBtnGroup>
            <Description
                color={props.theme.palette.text.primary}
                inputMarginTop={'1.6rem'}
                inputMarginBottom='0rem' >
                Dies ist ein Angebot unseres Kooperationspartners Teleclinic.
            </Description>
        </Container>
    );
}

ThanksStep.propTypes = {
    onSetNextStep: PropTypes.func.isRequired
}

export default withTheme(ThanksStep)
