import React from 'react'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import ReportIcon from '@material-ui/icons/Report'
import { withStyles } from '@material-ui/core/styles'

import styled from 'styled-components'

const styles = theme => ({
    root: {
      boxShadow: 'none',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      fontSize: '12px',
      lineHeight: '24px',
      verticalAlign: 'middle',
      borderRadius: '5px'
    }
})

const ReportIconEdit = styled(ReportIcon)`
  vertical-align: middle;
`

const ErrorMessage = ({ children, classes }) => {
    return (
        <div className={classes.root}>
        <ReportIconEdit />{children}
        </div>
    )
}

export default withStyles(styles)(ErrorMessage)
