import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import { trackGoogleAnalyticsEvent } from 'service/tracker'

import {
  Container,
  LogoContainer,
  Title,
  Description,
  Content,
  ButtonGroup,
  ButtonItem
} from './styles'

const LegalStep = (props) => {
    useEffect(() => {
        trackGoogleAnalyticsEvent('PageView - Legal', true)
    }, []);

    const handleBtnClick = () => {
        trackGoogleAnalyticsEvent('Submit - Legal', true)
        props.onSetNextStep()
    }
    return (
        <Container>
            <LogoContainer>
                <img
                alt='logo'
                src={require('../../../assets/img/corporate/teal-logo-text.png')}
                className='patient-service__logo'
                />
            </LogoContainer>
            <Content>
                <Title>Bitte beachten!</Title>
                <Description>
                    Die Ergebnisse des Nebenwirkungs-Checkers dürfen nicht als Ersatz für die medizinische Beratung oder Behandlung durch Ärzte oder Apotheker gelten. Die Einordnung der Nebenwirkungsmeldung nach deren Schweregrad erfolgt gemäß der Klassifizierungskriterien der Europäischen Arzneimittelagentur. Dies darf jedoch nicht als medizinische Beurteilung des Einzelfalls verstanden, sondern mit dem Arzt oder Apotheker seines Vertrauens zusammen besprochen werden.
                </Description>
            </Content>
            <ButtonGroup>
                <ButtonItem
                    width='100%'
                    color="primary"
                    onClick={handleBtnClick}
                    title='agree'
                    variant='contained'
                    id='agree' >
                    Verstanden
                </ButtonItem>
            </ButtonGroup>
        </Container>
    );    
}

LegalStep.propTypes = {
    onSetNextStep: PropTypes.func.isRequired
}

export default withTheme(LegalStep)
