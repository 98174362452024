import queryString from 'query-string'
import _ from 'lodash'
import lodash from 'lodash'
import Cookies from 'js-cookie'

const getBooleanParamFromURL = (url, queryParameter) => {
  const parsed = queryString.parse(url)
  return parsed[queryParameter] ? parsed[queryParameter] === 'true' : false
}

const getStringParamFromURL = (url, queryParameter) => {
  const parsed = queryString.parse(url)
  return parsed[queryParameter] ? parsed[queryParameter] : ''
}

const translateCapitalizeFormat = (param) => {
  return _.upperFirst(param.toLowerCase())
}

const isDateInputSupported = () => {
  let input = document.createElement('input'),
    value = 'a'
  input.setAttribute('type', 'date')
  input.setAttribute('value', value)
  return (input.value !== value)
}

const getAb = () => {
  if (getBooleanParamFromURL(window.location.search, 'labz')) {
    let abCookie = Cookies.get('ab')
    if (abCookie === undefined) {
      abCookie = Math.random() < 0.5 ? 'a' : 'b'
      Cookies.set('ab', abCookie)
    }
    return abCookie
  } else {
    return null
  }
}

const isWidget = () => {
  let widgetParameter = getStringParamFromURL(window.location.search, 'widget')
  if (widgetParameter === 'false') {
    return false
  } else {
    return true
  }
}

const getPartner = () => {
  let partnerId = getStringParamFromURL(window.location.search, 'partner')
  if (partnerId && ['apotheken-umschau'].includes(partnerId)) {
    return partnerId
  } else {
    return null
  }
}

const isPatientServiceEnabled = () => {
  return ( getStringParamFromURL(window.location.search, 'patientService') === 'sideEffectChecker' ) ? true : false
}

export const hasDose = drug => lodash.has(drug, 'dose')

export { 
  getStringParamFromURL, 
  getBooleanParamFromURL, 
  translateCapitalizeFormat, 
  isDateInputSupported, 
  getAb,
  isWidget, 
  isPatientServiceEnabled,
  getPartner
}
