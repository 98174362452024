import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { translate, Trans } from 'react-i18next'

import lodash, { debounce } from 'lodash'
import _ from 'lodash'
import { getDoctors } from 'service'
import { trackGoogleAnalyticsEvent } from 'service/tracker'

import MedSearch from 'components/MedSearch'
import DoctorInput from 'components/DoctorInput'
import {
  HelpIcon,
  ReactTooltipStyledName
} from '../../../components/DoctorInput/styles'
import Title from 'lib/elements/Title'
import ConfidentialityDialog from 'components/ConfidentialityDialog'

import { withTheme } from '@material-ui/core/styles'
import * as palette from 'styles/palette'
import ErrorMessage from 'components/ErrorMessage'
import Spinner from 'components/Spinner'

import {
  errorMessages
} from '../../../constants'

import {
  BottomBtnGroup,
  ButtonItem,
  FlexButtons,
  StepWrapper,
  ButtonSpacer
} from '../styles'

import {
  InputContainer,
  ReleaseDoctorContainer,
  ReleaseDoctorTxt,
  CheckBoxItem
} from './styles'

const hasPriority = true

class HcpStep extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activePriority: '',
      nameTxt:  _.get(props.formData, 'contacts.patient.identity.name.lastName', ''),
      birthDayTxt: _.get(props.formData, 'contacts.patient.identity.dateOfBirth', ''),
      oldEL: null,
      oldName: _.get(props.formData, 'contacts.patient.dateOfBirthYear', ''),
      doctor: _.get(props.formData, 'contacts.healthProfessional.identity.name.lastName', ''),
      doctors: [],
      isSetName: false,
      isChecked: _.get(props.formData, 'extras.isChecked', false),
      hasFullBirthDateError: false,
      loading: false
    }
  }

  handleAutocompleteDebounce = debounce(() => {
    if (this.state.doctor !== '') {
    getDoctors(this.state.doctor)
      .then((response) => {
          if (response.data && response.data.length >= 0) {
            const doctors = response.data
              .map((item, index) => ({
                id: String(index),
                label: item.name,
                prefix: item.prefix,
                firstName: item.firstName,
                lastName: item.lastName,
                sec: item.info,
                address: item.address,
                addressCountry: item.address.addressCountry,
                postalCode: item.address.postalCode,
                stiftungGesundheitId: item.id
              }))
            this.setState({ doctors })
          }
        }
      )
      .catch((error) => {
        console.log(error)
      })
  }}, 500)

  handleAddSearchItem = e => {
    let doctor_name = e.target.value
    if(doctor_name .length <= 200){
      this.setState({ doctor: e.target.value }, () => {
      this.handleAutocompleteDebounce()
    })}
  }

  handleSelectItem = (value, { prefix, firstName, lastName, address, addressCountry, postalCode, stiftungGesundheitId }) => {
    this.setState({ doctor: value, prefix, firstName, lastName, address, addressCountry, postalCode, stiftungGesundheitId })
  }

  handleClearSearchItem = () => {
    this.setState({ doctor: '' })
  }

  handleCheck = () => {
    this.setState({ isChecked: !this.state.isChecked })
  }

  /* global */

  handleActivePriority = (e, activePriority) => {
    e.stopPropagation()
    if (this.state.activePriority === '') {
      this.setState({ activePriority })
    } else {
      this.setState({ activePriority: '' })
    }
  }

  handleBlur = () => {
    this.setState({ activePriority: '' })
  }

  validate = () => {
    const {
      doctor,
      isChecked,
      birthDayTxt,
      nameTxt,
      hasFullBirthDateError
    } = this.state

    this.setState({
      hasActiveValidation: true
    })

    if (doctor.length === 0) {
      return false
    }

    if (!isChecked) {
      return false
    }

    if (nameTxt.length === 0) {
      return false
    }

    if (birthDayTxt.length === 0 || hasFullBirthDateError) {
      return false
    }

    this.setState({
      hasActiveValidation: false
    })
    return true
  }


  handleSendMessage = () => {
    if (!this.validate()) {
      return
    }

    let myData = {
      'contacts': {
        'healthProfessional': {
          'profession': 'doctor'
        },
        'patient': {
          'identity': {
            'dateOfBirth': this.state.birthDayTxt,
            'name': {
              'lastName': '',
              'firstName': this.state.nameTxt
            }
          }
        }
      },
      'legal': {
        'checkReleaseMedicalConfidentiality': this.state.isChecked
      },
      'checkInformHealthProfessional': true
    }
    if (this.state.firstName) {
      lodash.set(myData, 'contacts.healthProfessional.identity.name.firstName', this.state.firstName)
      lodash.set(myData, 'contacts.healthProfessional.identity.name.lastName', this.state.lastName)
    } else {
      lodash.set(myData, 'contacts.healthProfessional.identity.name.firstName', '')
      lodash.set(myData, 'contacts.healthProfessional.identity.name.lastName', this.state.doctor)
    }
    if (this.state.prefix) {
      lodash.set(myData, 'contacts.healthProfessional.identity.name.prefix', this.state.prefix)
    }
    if (this.state.address) {
      lodash.set(myData, 'contacts.healthProfessional.identity.address', this.state.address)
    }
    if (this.state.stiftungGesundheitId) {
      lodash.set(myData, 'contacts.healthProfessional.identity.publicProfiles.stiftungGesundheitId', this.state.stiftungGesundheitId)
    }
    this.props.onSetNextStep(myData)
    trackGoogleAnalyticsEvent('Submit - HcpStep', true)
    this.props.onSubmitHcp(myData)
  }

  handleChangename = nameTxt => {
    this.setState({ nameTxt }, () => {
      if (this.state.nameTxt !== '' && !this.state.isSetName) {
        this.setState({ isSetName: true })
      }

      if (this.state.nameTxt === '' && this.state.isSetName) {
        this.setState({ isSetName: false })
      }
    })
  }

  handleChangeFullBirthDate = birthDayTxt => {
    this.setState({ birthDayTxt }, () => {
      if (this.state.hasFullBirthDateError) {
        this.handleChangeFullBirthDateBlur(birthDayTxt)
      }
    })
  }

  handleChangeFullBirthDateBlur = birthDayTxt => {
    let validateFullDate = moment(birthDayTxt, 'DD.MM.YYYY', true).isValid()
    this.setState({ activePriority: '', hasFullBirthDateError: !validateFullDate })
  }

  componentDidMount () {
    trackGoogleAnalyticsEvent('PageView - HcpStep', true)
  }

  render () {
    const {
      doctor,
      activePriority,
      doctors,
      isChecked,
      birthDayTxt,
      nameTxt,
      hasFullBirthDateError,
      hasActiveValidation
    } = this.state

    let themingColor = {
        lightColor: this.props.theme.palette.primary.light,
        mainColor: this.props.theme.palette.primary.main,
        borderColor: palette.primaryInputBorder,
        focusColor: palette.primaryGrey
    }

    return (
      <div>
        <StepWrapper>
          <div>
            <InputContainer>
              <MedSearch
                items={doctors}
                onClear={this.handleClearSearchItem}
                onSearch={this.handleAddSearchItem}
                onSelect={this.handleSelectItem}
                placeholder={'z. B. Dr. Müller, Jens Bielefeld'}
                title={'Wie heißt Ihr Arzt oder Apotheker?'}
                value={doctor}
                searchId={'searchDoctor'}
                hasActiveValidation={hasActiveValidation}
                errorText={'Please enter the name of your doctor or pharmacist.'}
                hasActiveValidation={hasActiveValidation}
              />
              <ReleaseDoctorContainer color={hasActiveValidation && !isChecked ? this.props.theme.palette.error.main : this.props.theme.palette.primary.main}>
                <label style={{ display: 'contents', cursor: 'pointer', color: this.props.theme.palette.text.primary }}>
                <CheckBoxItem checked={isChecked} onChange={this.handleCheck} id="doctor-release"/>
                <ReleaseDoctorTxt>
                  Hiermit entbinde ich meinen Arzt oder Apotheker von der <ConfidentialityDialog theme={this.props.theme} /> für die Meldung
                </ReleaseDoctorTxt>
                </label>
              </ReleaseDoctorContainer>
              {hasActiveValidation && !isChecked && <ErrorMessage><Trans>Please free your doctor/pharmacist from their duty of doctor-patient confidentiality.</Trans></ErrorMessage>}
    
              <Title>Wie ist Ihr vollständiger Name?
                <a data-tip data-for='apothekerTooltip'><HelpIcon /></a>
                <ReactTooltipStyledName
                  id='apothekerTooltip'
                  type='dark'
                  place='top'
                  effect='solid'
                  scrollHide={false}
                >
                  <span>Ihr Arzt oder Apotheker benötigt Ihren vollständigen Namen und Ihr Geburtsdatum, um Sie als seinen Patienten identifizieren zu können. Diese Infromationen erhält nur der Arzt oder Apotheker. </span>
                </ReactTooltipStyledName>
              </Title>
              <DoctorInput
                activeItem={activePriority}
                themeColor={this.props.theme.palette.primary.main}
                hasPriority={hasPriority}
                itemName={'example'}
                onActivePriority={this.handleActivePriority}
                onBlur={this.handleBlur}
                onChangeTxt={this.handleChangename}
                placeholderTxt={'z. B. Sabrina Schustermann'}
                errorText={errorMessages.missingName}
                hasError={nameTxt.length === 0}
                opinionTxt={nameTxt}
                inputId={'fullName'}
                maxLength="100"
                {...themingColor}
                hasActiveValidation={hasActiveValidation}
              />
              <Title>Wann sind Sie geboren?
              </Title>
              <DoctorInput
                activeItem={activePriority}
                themeColor={this.props.theme.palette.primary.main}
                hasPriority={!hasPriority}
                itemName={''}
                onActivePriority={this.handleActivePriority}
                onBlur={this.handleChangeFullBirthDateBlur}
                onChangeTxt={this.handleChangeFullBirthDate}
                placeholderTxt={'z. B. 25.04.1980'}
                opinionTxt={birthDayTxt}
                errorText={errorMessages.validFullBirthDay}
                hasError={birthDayTxt.length === 0 || hasFullBirthDateError}
                inputId={'fullBirthdayDate'}
                {...themingColor}
                hasActiveValidation={hasActiveValidation}
              />
            </InputContainer>
          </div>
        </StepWrapper>
        <BottomBtnGroup>
          <FlexButtons>
            <ButtonSpacer />
              {this.props.isSubmitting &&
                <Spinner />
              }
              {!this.props.isSubmitting &&
                <ButtonItem
                color="primary"
                onClick={this.handleSendMessage}
                title='next'
                variant='contained'
                id="next">
                  Ergänzung abschicken
                </ButtonItem>
              }
          </FlexButtons>
        </BottomBtnGroup>
      </div>
    )
  }
}

HcpStep.propTypes = {
  onSetBackStep: PropTypes.func.isRequired,
  onSetNextStep: PropTypes.func.isRequired,
  onSubmitHcp: PropTypes.func.isRequired,
}

export default withTheme(HcpStep)
