import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'

import DropDownMenu from 'components/DropDownMenu'
import MedTextArea from 'components/MedTextArea'
import Title from 'lib/elements/Title'
import MedButton from 'lib/elements/Button'
import AddMoreButton from 'lib/elements/AddMoreButton'

import _ from 'lodash'

import { trackGoogleAnalyticsEvent } from 'service/tracker'

import {
  BottomBtnGroup,
  ButtonItem,
  FlexButtons,
  BackAnchor,
  ItemsList,
  StepWrapper
} from '../styles'

import {
  defaultNonOption,
  erkrankungenList,
  sicknessMap,
  erkrankungenAddMore,
  erkrankungenQuestion,
  VorerkrankungenPlaceholder
} from '../../../constants'
import * as palette from '../../../styles/palette'

const isJson = false

class SicknessesStep extends React.Component {
  constructor (props) {
    super(props)
    let storedDiagnoses = _.get(props.formData, 'diagnoses', [])
    this.state = {
      diagnosesEL: null,
      diagnosesName: defaultNonOption,
      opinionTxt: '',
      diagnoses: _.get(props.formData, 'diagnoses', []),
      isSetDiagnoses: false,
      currentSubstepIndex: storedDiagnoses.length ? storedDiagnoses.length: 0
    }
  }

  handlediagnosesClose = item => {
    this.setState({
      diagnosesEL: null,
      diagnosesName: item !== 'close' ? item : this.state.diagnosesName,
      isSetDiagnoses: item !== 'close' && item !== defaultNonOption
    })

    for (var key in sicknessMap) {
      if (sicknessMap[key] === item) {
        this.setState({ class: key })
      }
    }
  }

  handleDiagnosesOpen = event => {
    this.setState({ diagnosesEL: event.currentTarget })
  }

  handleSetOpinionTxt = event => {
    this.setState({ opinionTxt: event.target.value })
  }

  handleCreateNewDiagnose = () => {
    trackGoogleAnalyticsEvent('AddButton - SicknessesStep', true)
    this.setState({ currentSubstepIndex: this.state.currentSubstepIndex + 1, color: '' })
    const { diagnoses, diagnosesName, opinionTxt} = this.state
    console.log('optionTxt *** description', opinionTxt)
    const newDiagnose = {
      name: diagnosesName,
      description: opinionTxt,
      class: this.state.class
    }

      if(newDiagnose.name !== defaultNonOption){
        diagnoses.push(newDiagnose)
      }

    if(diagnoses !== defaultNonOption){
      this.setState({ opinionTxt: '', diagnosesName: '', isSetDiagnoses: false, diagnoses: diagnoses })
    }
  }

  handleNext = () => {
    const { diagnoses, isSetDiagnoses, diagnosesName, opinionTxt } = this.state
    console.log('optionTxt *** description', opinionTxt)
    const newDiagnose = {
      name: diagnosesName,
      description: opinionTxt,
      class: this.state.class
    }

    if (isSetDiagnoses && newDiagnose.name !== defaultNonOption) { diagnoses.push(newDiagnose) }

    this.setState({ diagnoses: diagnoses, isSetDiagnoses: false }, () => {
      trackGoogleAnalyticsEvent('Submit - SicknessesStep', true)
      this.props.onSetNextStep({'others': diagnoses})
    })
  }

  handleSkip = () => {
    trackGoogleAnalyticsEvent('Submit - SicknessesStep', true)
    this.props.onSetNextStep({others: this.state.diagnoses})
  }

  handleBack = () => {
    this.props.isBackToEditReset()
    this.props.onSetBackStep()
  }

  componentDidMount () {
    trackGoogleAnalyticsEvent('PageView - SicknessesStep', true)
  }

  render () {
    const {
      diagnosesEL,
      diagnosesName,
      opinionTxt,
      isSetDiagnoses,
      diagnoses,
      currentSubstepIndex
    } = this.state

    let disabled = !(((diagnoses && diagnoses.length === 0) || diagnoses.length === currentSubstepIndex) && isSetDiagnoses)
    return (
      <div>
        <StepWrapper>
          {
            diagnoses && diagnoses.length > 0 &&
            <ItemsList
              color={this.props.theme.palette.primary.main}
              textColor={this.props.theme.palette.text.primary}>
              <p>Bisher eingetragen</p>
              <div>{
                <ul>
                  {diagnoses.map(function(el, index){
                    return <li key={index}>{el.name}</li>;
                  })}
                </ul>
              }</div>
            </ItemsList>
          }
          <div>
            <Title>{erkrankungenAddMore}</Title>
            <DropDownMenu
              anchorEl={diagnosesEL}
              buttonId='diagnosesEL-btn'
              defaultName={defaultNonOption}
              dropdownList={erkrankungenList}
              isJson={isJson}
              itemName={diagnosesName}
              menuId='diagnosesEL-menu'
              onHandleClose={this.handlediagnosesClose}
              onOpen={this.handleDiagnosesOpen}
            />
          </div>
          <div>
            <div>
              <div>
                <Title isTitleTxt={diagnosesName !== ''}>{erkrankungenQuestion}</Title>
                <MedTextArea
                  onSetText={this.handleSetOpinionTxt}
                  opinionTxt={opinionTxt}
                  placeholder={VorerkrankungenPlaceholder}
                  textareaId={'diagnosesQuestion'}
                />
              </div>
              <div onClick={this.handleCreateNewDiagnose}>
                <MedButton
                  color="darkGreen"
                  step={4}
                  variant='contained'
                  themeColor={this.props.theme.palette.primary.main}
                  disabled={disabled}
                ></MedButton>
                <AddMoreButton
                  item="Ich habe weitere Vorerkrankungen"
                  themeColor={this.props.theme.palette.primary.main}
                  disabled={disabled}
                ></AddMoreButton>
              </div>
            </div>
          </div>
        </StepWrapper>
        <BottomBtnGroup>
          <FlexButtons>
            <BackAnchor
              onClick={this.handleBack}
              title='back'
              variant='contained'
              themecolor={this.props.theme.palette.primary.main}
            >
              ◄ Zurück
            </BackAnchor>
            {!isSetDiagnoses && diagnoses.length === 0 ? <ButtonItem
              color="primary"
              onClick={this.handleSkip}
              title='skip'
              variant='contained'
              id='skip'
            >
              Überspringen
            </ButtonItem> : <ButtonItem
              color="primary"
              onClick={this.handleNext}
              title='next'
              variant='contained'
              id="next"
            >
              Weiter
            </ButtonItem>}
          </FlexButtons>
        </BottomBtnGroup>
      </div>
    )
  }
}

SicknessesStep.propTypes = {
  onSetBackStep: PropTypes.func.isRequired,
  onSetNextStep: PropTypes.func.isRequired
}

export default withTheme(SicknessesStep)
