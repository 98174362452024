import styled from 'styled-components'
import * as palette from 'styles/palette'

const Input = styled.input`
    border-radius: .5rem;
    border: 1px solid ${palette.primaryGreen};
    box-sizing: border-box;
    width: 100%;
    color: ${palette.primaryDarkGrey};
    background: ${palette.superLightGreen};
    padding: .7rem 2rem;
    outline: none;
    &::placeholder {
        color: ${palette.searchTxtColor};
    }
`

export default Input
