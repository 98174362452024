import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

export const Container = styled.div`
`

export const HowOftenContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ProTxt = styled(Typography)`
  &&& {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.8rem;
    padding: 0 1rem;
  }
`

export const UnitInput = styled.input`
  background-color: transparent;
  border: 1px solid ${props => props.borderColor};
  color: ${props => props.mainColor};
  ::placeholder {
    color: ${props => props.borderColor};
  }
  &:focus, &:active {
    outline: none;
    border: 2px solid ${props => props.focusColor};
    padding: calc(1.2rem - 1px);
  }
  outline: none;
  width: 6rem;
  height: 4rem;
  border-radius: 5px;
  margin-right: 1rem;
  padding: 1.2rem;
`
