import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { MainContainer } from '../../MainPage/styles'
import { MainWrapper, StyledHeader, StyledTitle } from '../styles'
import { Container, ManyThanks, Description, ThankYouWishes } from './styles'
import PropTypes from 'prop-types'

import { CheckCircle } from '@material-ui/icons'
import styled from 'styled-components'
import Title from 'lib/elements/Title'

export const StyledCheckCircle = styled(CheckCircle)`
  &&& {
  vertical-align: middle;
  font-size: 40px !important;
  }
`

const Success = ({ theme }) => (
  <MainContainer>
    <StyledHeader><StyledTitle></StyledTitle></StyledHeader>
    <Container>
      <Title color="primary"><StyledCheckCircle/></Title>
      <ManyThanks
        lightColor={theme.palette.primary.light}
        primaryColor={theme.palette.primary.main}
      >
        <span id="thankYou">Vielen Dank!</span>
      </ManyThanks>
      <MainWrapper>
        <Description>
          <p style={{ color: theme.palette.text.primary }}>
              Mit Ihrer Meldung leisten Sie einen wichtigen Beitrag zur Arzneimittelsicherheit - Ihre Mitmenschen danken es Ihnen!
          </p>
          <ThankYouWishes
            lightColor={theme.palette.primary.light}
            primaryColor={theme.palette.primary.main}
          >
            <span>
          Wir wünschen Ihnen für Ihre Gesundheit alles Gute!
          Ihr Team von Nebenwirkungen.de
            </span>
          </ThankYouWishes>
        </Description>
      </MainWrapper>
    </Container>
  </MainContainer>
)

Success.propTypes = {
  theme: PropTypes.any
}

export default withTheme(Success)
