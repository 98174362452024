import styled from 'styled-components'

import Input from 'lib/elements/Input'
import Help from '@material-ui/icons/Help'
import ReactTooltip from 'react-tooltip'

export const DoctorInputContainer = styled.div`
  position: relative;
`

export const DoctorInputItem = styled(Input)`
  height: 40px;
  padding: .7rem 1.4rem;
  background-color: transparent;
  border: 1px solid ${props => props.borderColor};
  color: ${props => props.mainColor};
  ::placeholder {
    color: ${props => props.placeholderColor};
  }
  &:focus, &:active {
    outline: none;
    border: 2px solid ${props => props.focusColor};
    padding-left: calc(1.4rem - 1px);
  }
  &.error {
    outline: none;
    border: 2px solid ${props => props.errorColor};
    padding-left: calc(1.4rem - 1px);
  }
`

export const HelpIcon = styled(Help)`
  &&& {
  vertical-align: sub;
  width: 1.2em;
  font-size: 2rem;
  }
  &:hover {
    cursor: pointer;
    z-index: 21;
  }
`

export const ReactTooltipStyled = styled(ReactTooltip)`
  &.type-dark.place-top {
    index-z: 500;
    width: 400px;
    @media only screen and (max-width: 830px) {
      index-z: 1001;
      width: 243px;
    }
  }
    &.type-dark {
    index-z: 500;
    width: 400px;
    @media only screen and (max-width: 830px) and (min-height: 400px) {
      index-z: 1001;
      width: 243px;
    }
  }
`
export const ReactTooltipStyledName = styled(ReactTooltip)`
  &.type-dark.place-top {
    z-index: 1001 !important;
    width: 430px;
    @media only screen and (max-width: 830px) and (min-height: 400px) {
      index-z: 1001 !important;
      width: 249px;
    }
  }
`