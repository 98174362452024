import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2
  }
})

class Spinner extends React.Component {
  render () {
    return (
      <div>
        <CircularProgress />
      </div>
    )
  }
}

export default withStyles(styles)(Spinner)
