import styled from 'styled-components'

export const Info = styled.div`
      font-family: Nunito, sans-serif;
      font-size: 13px;
      font-style: oblique;
      border: 1px solid;
      margin: 10px 0px;
      padding: 15px 10px 15px 50px;
      background-repeat: no-repeat;
      background-position: 10px center;
      color: #6d706e;
      background-color: #ebeeec;
      border-radius: 5px;
     background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>');
`

export const Chargennummer = styled.ul`
      padding-left: 0px;
`
