import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { get } from 'lodash'
import * as Sentry from '@sentry/browser'
import SideEffectsStep from './SideEffectsStep'
import MedicationsStep from './MedicationsStep'
import BodyStep from './BodyStep'
import SicknessesStep from './SicknessesStep'
import RiskfactorsStep from './RiskfactorsStep'
import EmailStep from './EmailStep'
import CommentStep from './CommentStep'
import OverviewStep from './OverviewStep'
import ThanksStep from './ThanksStep'
import HcpStep from './HcpStep'
import ThanksHcpStep from './ThanksHcpStep'
import ProgressBar from 'components/ProgressBar'
import ProgressTitle from 'components/ProgressTitle'
import ChangeLanguage from 'components/ChangeLanguage'
import { translate, Trans } from 'react-i18next'
import i18next from 'i18next'
import { //getAb, 
  getStringParamFromURL, getBooleanParamFromURL, isWidget, getPartner, isPatientServiceEnabled } from 'lib/functions'
import { trackGoogleAnalyticsEvent } from 'service/tracker'
import lodash from 'lodash'
import config from '../../config'
import LegalDisclaimerDialog from 'components/LegalDisclaimerDialog'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { withTheme } from '@material-ui/core/styles'

import {
  HeaderContainer,
  Title,
  SubTitle,
  MainContainer,
  ProgressContainer,
  FooterContainer
} from './styles'

import Container from 'lib/elements/Container'

import { createReport, updateReport } from 'service'

import { stepInfos, reportData, localeTranslations, LEGAL_STEP, SEVERITY_STEP } from '../../constants'
import LegalStep from './LegalStep'
import SeverityStep from './SeverityStep'

class MainPage extends React.Component {

  constructor (props) {
    super(props)

    console.info(`Pipeline id: ${process.env.REACT_APP_CI_PIPELINE_ID}`);

    let language = getStringParamFromURL(window.location.search, 'lang')

    // if no language is passed to us via url fallback on browser locale
    if (!language.length) {

      // set deafult to de
      language = localeTranslations.mainLanguageFallback

      // use browser locale
      const browserLocale = window.navigator.language // "en-US" for example
      for (let prop in localeTranslations.languageFormat) {
        if (localeTranslations.languageFormat[prop].indexOf(browserLocale) > -1) {
          // set widget language if is defined in the languageFormat
          language = prop
        }
      }
    }

    i18next.changeLanguage(language)

    this.handleOnLeavePage = this.handleOnLeavePage.bind(this);

    // Initialize Sentry
    if (config.sentryDSN) {
      Sentry.init({
        dsn: config.sentryDSN,
        environment: process.env.NODE_ENV
      })
    }
  }

  state = {
    currentStep: isPatientServiceEnabled() ? LEGAL_STEP : 1,
    forms: reportData,
    isSubmitting: false,
    isBackToEdit: false,
    inInputToggled: false,
    createdReportId: null,
    partnerId: null
  };

  getYear() {
    return new Date().getFullYear();
  }

  getStepComponent = (stepNumber) => {
    switch (stepNumber) {
      case 0:
        return LegalStep
      case 1:
        return SideEffectsStep
      case 2:
        return MedicationsStep
      case 3:
        return BodyStep
      case 4:
        return SicknessesStep
      case 5:
        return RiskfactorsStep
      case 6:
        return SeverityStep
      case 7:
        return EmailStep
      case 8:
        return CommentStep
      case 9:
        return OverviewStep
      case 10:
        return ThanksStep
      case 11:
        return HcpStep
      case 12:
        return ThanksHcpStep
      default:
        console.log('Invalid step #' + stepNumber)
        return SideEffectsStep
    }
  }

  handleNext = (formData) => {
    const { currentStep, forms } = this.state
    const newForms = forms
    switch (currentStep) {
      case 1:
        // SideEffectsStep
        newForms['effects'] = formData
        break

      case 2:
        // MedicationsStep
        newForms['drugs'] = formData.drugs
        if (formData.country){
          newForms['contacts']['responsibleAuthority']['country'] = formData.country        
        }
        break

      case 3:
        // BodyStep
        newForms['contacts']['patient'] = _.merge({}, newForms['contacts']['patient'], formData)
        break

      case 4:
        // SicknessesStep
        if (typeof formData.others !== 'undefined') { newForms['diagnoses'] = formData.others }
        break

      case 5:
        // RiskfactorsStep
        if (typeof formData.others !== 'undefined') { newForms['habits'] = {'others': formData.others} }
        break

      case 6:
        // SeverityStep
        newForms['services'] = {
          'feedbackOnSeriousnessRequested' : formData['feedbackOnSeriousnessRequested']
        }
        break

      case 7:
        // EmailStep
        newForms['contacts']['patient'] = _.merge({}, newForms['contacts']['patient'], formData.isNoDoctor['patient'])
        newForms['contacts']['reporter'] = _.merge({}, newForms['contacts']['reporter'], formData.isNoDoctor['reporter'])
        break

      case 8:
        // CommentStep
        if (typeof formData.description !== 'undefined') {
          newForms['description'] = formData.description
        }
        break

      case 11:
        // HcpStep
        if(formData.healthProfessional) {
          newForms['contacts']['healthProfessional'] = formData['healthProfessional']
          newForms['contacts']['patient'] = _.merge({}, newForms['contacts']['patient'], formData['patient'])
          newForms['contacts']['reporter'] = _.merge({}, newForms['contacts']['reporter'], formData['reporter'])
        }
        if(formData.legal) {
          newForms['legal']['checkReleaseMedicalConfidentiality'] = formData['legal']['checkReleaseMedicalConfidentiality']
        }
        if(formData.checkInformHealthProfessional) {
          newForms['checkInformHealthProfessional'] = formData['checkInformHealthProfessional']
        }
        break
    }
    let nextStep = currentStep + 1
    if(currentStep === 5 && !isPatientServiceEnabled()){
      nextStep++
    }
    this.setState({ forms: newForms, currentStep: nextStep })

  }

  handleBack = () => {
    const { currentStep } = this.state
    if (currentStep > 1) {
      let step = currentStep - 1
      if(currentStep === 7 && !isPatientServiceEnabled()){
        step--
      }
      this.setState({ currentStep: step })
    }
  }

  handleSubmit = () => {
    this.setState({ isSubmitting: true })
    const that = this
    const data = _.cloneDeep(this.state.forms)
    createReport(this.handleValidateData(data))
      .then(function (response) {
        that.setState({ createdReportId: response.data.id })
        trackGoogleAnalyticsEvent('Report Created', true)
        that.setState({ currentStep: 10, isSubmitting: false })
      })
      .catch(function (error) {
        that.setState({ isSubmitting: false })
        console.log(error)
      })
  }

  handleSubmitHcp = (formData) => {
    this.setState({ isSubmitting: true })
    const that = this
    let updateData = lodash.omit(formData, 'extras')
    if (_.get(updateData, 'contacts.patient.identity.dateOfBirth', '').length) {
      let birthDate = moment(updateData.contacts.patient.identity.dateOfBirth, 'DD.MM.YYYY').format('YYYY-MM-DD')
      updateData['contacts']['patient']['identity']['dateOfBirth'] = birthDate
    }
    updateReport(this.state.createdReportId, updateData)
      .then(function (response) {
        trackGoogleAnalyticsEvent('Report Updated - HCP Details', true)
        that.setState({ currentStep: 12, isSubmitting: false })
      })
      .catch(function (error) {
        that.setState({ isSubmitting: false })
        console.log(error)
      })
  }

  handleValidateData = (formData) => {

    // strip notSure item that should not be submitted
    const stripNotSure = data => _.omit(data, ['notSure']);

    // strip dateInputValue item that should not be submitted
    const stripInputValue = data => _.omit(data, ['timePeriodInputValue']);

    // strip timeElName item that should not be submitted
    const stripElName = data => _.omit(data, ['timeElName']);

    // remove date when is not set
    const stripFalseEmptyNullorUndefinedKeys = data =>  _.pickBy(data, _.identity);

    // replace default interval with unknown
    const stripInterval = data => {
      if(data['dose']['interval'] === 'Please choose') {
        data['dose']['interval'] = 'unknown'
        return data
      }
      return data
    }

    // replace default unit with unknown
    const stripUnit = data => {
      if(data['dose']['unit'] === 'Please choose') {
        data['dose']['unit'] = 'unknown'
        return data
      }
      return data
    }

    const stripAmount = data => {
      if(isNaN(parseInt(data['dose']['amount'], 10))) {
        return _.omit(data,'dose');
      }
      return data;
    }

    // replace default weight 0 with unknown
    const stripWeight = data => {
      if(data['weight'] === 0) {
        return _.omit(data,'weight');
      }
      return data;
    }

    // replace default height 0 with unknown
    const stripHeight = data => {
      if(data['height'] === 0) {
        return _.omit(data,'height');
      }
      return data;
    }

    // remove class from habits and rebuiild the json to be backend compliant
    const rebuildHabits = data => {

      let habits = { 'others': [] }

      if (typeof data !== 'undefined'){
        for (let prop in data['others']) {
          let othersProp = data['others'][prop]
          switch(data['others'][prop]['class']) {
            case "smoking":
              habits['smoking'] = {
                description: othersProp.description
              }
              break;
            case "drinking":
              habits['drinking'] = {
                description: othersProp.description
              }
              break;
            case "sport":
              habits['sport'] = {
                description: othersProp.description
              }
              break;
            default:
              habits['others'].push({'description': othersProp['description'], 'name': othersProp['name']})
          }
        }
      }

      return habits;
    }

    const pregnancyValidation = data => {

      let pregnantWeek = get(data, 'week', null)
      if(!isNaN(parseInt(pregnantWeek))) {
        data = {
          pregnant: true,
          week: parseInt(pregnantWeek)
        }
      } else {
        data = {
          pregnant: false
        }
      }

      return data;

    }

    formData['effects'] = formData.effects.map(stripNotSure).map(stripInputValue).map(stripElName).map(stripFalseEmptyNullorUndefinedKeys)
    formData['drugs'] = formData.drugs.map(stripNotSure).map(stripInputValue).map(stripElName).map(stripFalseEmptyNullorUndefinedKeys).map(stripInterval).map(stripUnit).map(stripAmount)
    formData['diagnoses'] = formData.diagnoses.map(stripNotSure).map(stripFalseEmptyNullorUndefinedKeys)
    formData['contacts']['patient'] = stripWeight(formData.contacts.patient)
    formData['contacts']['patient'] = stripHeight(formData.contacts.patient)
    formData['habits'] = rebuildHabits(formData.habits)


    if(typeof formData['contacts']['patient']['pregnancy'] !== 'undefined') {
      formData['contacts']['patient']['pregnancy'] = pregnancyValidation(formData.contacts.patient.pregnancy)
    }

    if (!formData['description'].length){
      formData = _.omit(formData, ['description'])
    }

    return formData;

  }

  handleSetCurrentStep = (currentStep) => {
    this.setState({ currentStep })
  }

  isBackToEdit = () => {
    this.setState({ isBackToEdit: true })
  }

  isBackToEditReset = () => {
    this.setState({ isBackToEdit: false })
  }

  handleOnLeavePage (event) {
    if (this.state.currentStep > 1  && this.state.currentStep < 5) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  componentDidMount() {
    //TODO: Reenable for next AB-Test
    /*let abCookie = getAb()
    if (abCookie == 'a') {
      trackGoogleAnalyticsEvent('Widget A')
    } else if (abCookie == 'b') {
      trackGoogleAnalyticsEvent('Widget B')
    }*/

    this.setState({ 
      //ab: abCookie, 
      isWidget: isWidget(),
      partnerId: getPartner() 
    })

    window.addEventListener('beforeunload', this.handleOnLeavePage);
  }

  handleBodyClick = () => {
    this.setState({ inInputToggled: false })
  }

  handleOnCloseToggleBody = () => {
    this.setState({ inInputToggled: false })
  }

  componentDidCatch (error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render () {
    const {
      currentStep,
      currentSubStep,
      isSubmitting,
      isBackToEdit,
      hasfilledmessage,
      //ab,
      isWidget,
      partnerId
    } = this.state

    const { t } = this.props
    const stepInfo = _.find(stepInfos, { index: currentStep })
    const StepComponent = this.getStepComponent(currentStep)
    const showIntro = getBooleanParamFromURL(window.location.search, 'showIntro')
    return (
      <Container className={isWidget ? null : 'noWidget'} onClick={this.handleBodyClick} style={{ background: '#dcdcdd' }}>
        {
          isWidget === false && <HeaderContainer><div className={ 'logo ' + partnerId }></div></HeaderContainer>
        }
        <div>
          {
            showIntro !== false &&
            <div>
              <ChangeLanguage />
              <Title color="primary"><Trans>title</Trans></Title>
              <SubTitle color="primary" hasfilledmessage={hasfilledmessage}>{stepInfo.title}</SubTitle>
            </div>
          }
          <MainContainer className={isWidget ? null : 'noWidget'}>
            { currentStep === LEGAL_STEP && <LegalStep onSetNextStep={this.handleNext} />}

            { currentStep === SEVERITY_STEP &&
              <SeverityStep 
                onSetNextStep={this.handleNext}
                formData={this.state.forms}
                handleBack={this.handleBack}
              />
            }

            { (currentStep > LEGAL_STEP && currentStep < 10 && currentStep !== SEVERITY_STEP) && <ProgressBar steps={currentStep} /> }

            { (currentStep !== LEGAL_STEP && currentStep !== SEVERITY_STEP) && 
            <ProgressContainer>
              <ProgressTitle step={currentStep} substep={currentSubStep} />
              <StepComponent
                //ab={ab}
                currentStep={currentStep}
                currentHandleState={this.state.inInputToggled}
                formData={this.state.forms}
                isBackToEdit={isBackToEdit}
                isBackToEditReset={this.isBackToEditReset}
                isSubmitting={isSubmitting}
                onCloseToggleBody={this.handleOnCloseToggleBody}
                onSetBackStep={this.handleBack}
                onSetBackToEdit={this.isBackToEdit}
                onSetCurrentStep={this.handleSetCurrentStep}
                onSetNextStep={this.handleNext}
                onSubmit={this.handleSubmit}
                onSubmitHcp={this.handleSubmitHcp}
              />
            </ProgressContainer>
          }
          </MainContainer>
        </div>
        {
          isWidget === false && <FooterContainer>
            <div className="footer-container">
              <div className='footer-image-container'>
                {/**/}
                {/*<img*/}
                {/*  alt='bundestag'*/}
                {/*  src={require('../../assets/img/corporate/Bund-logo.png')}*/}
                {/*  className='footer-image footer-image--bundes'*/}
                {/*/>*/}
                {/*<img*/}
                {/*  alt='eu'*/}
                {/*  src={require('../../assets/img/corporate/EU-flag.png')}*/}
                {/*  className='footer-image footer-image--eu'*/}
                {/*/>*/}
              </div>
              <div className='footer-section'>
                <h4>Nebenwirkungen.de</h4>
                <ul class="horizontal">
                  <li>
                    <a href='https://www.nebenwirkungen.de/ueber-uns'>
                      Über uns
                    </a>
                  </li>
                  <li>
                    <a href='https://www.nebenwirkungen.de/datenschutz'>
                      Datenschutz
                    </a>
                  </li>
                  <li>
                    <a href='https://www.nebenwirkungen.de/impressum'>
                      Impressum
                    </a>
                  </li>
                  <li>
                  <LegalDisclaimerDialog theme={this.props.theme} />
                  </li>
                </ul>
              </div>
            </div>
            <div className='footer-bottom'>
              <p>© {this.getYear()} nebenwirkungen.de | XO Life GmbH</p>
            </div>
          </FooterContainer>
        }
        <CookieConsent
          cookieName="cookie_notice_accepted"
          buttonText="OK"
          expires={24855}
          style={{
            background: this.props.theme.palette.background.cookieBanner,
            boxShadow: '0px -1px 2px 0px rgba(0,0,0,.2)',
            color: this.props.theme.palette.text.primary,
            justifyContent: 'center'
          }}
          contentStyle={{
            flex: '',
            margin: '10px 5px'
          }}
          buttonStyle={{
            background: this.props.theme.palette.background.cookieBanner,
            border: '1px solid ' + this.props.theme.palette.primary.main,
            borderRadius: '0.2em',
            margin: '5px 0 5px 5px',
            color: this.props.theme.palette.primary.main,
          }}

        ><span style={{ fontSize: '13px' }}>
          Durch die Nutzung dieser Website stimmen Sie der Verwendung von <a style={{fontSize: '13px', color: this.props.theme.palette.primary.main}} href="https://www.nebenwirkungen.de/datenschutz#cookies" target="_blank">Cookies</a> zu.
        </span></CookieConsent>
      </Container>
    )
  }
}

export default translate('translations')(withTheme(MainPage))
