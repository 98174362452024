/**
 * Push event to GTM data layer.
 *
 * @param {string} event
 * @param {object} eventData
 */

import React from 'react'
import { getStringParamFromURL } from 'lib/functions'
import config from '../config'

const isTrackingActivated = getStringParamFromURL(window.location.search, 'trackingEnabled')

class GoogleAnalytics extends React.Component {

  componentDidMount() {

    if (isTrackingActivated === 'true' && typeof config.GTM_ID !== 'undefined') {
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName('head')[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.appendChild(j);
      })(window,document,'script','dataLayer',config.GTM_ID);
    }

  }

  render () { return null; }

}

export { GoogleAnalytics }
