import styled from 'styled-components'
import * as palette from 'styles/palette'

export const SearchContainerSideEffects = styled.div`
  &&& {
    position: relative;
    display: flex;

    &.small {
      max-width: 300px;
    }

    & > div {
      z-index: 1000 !important;
    }
    & > div {
      width: 100%;
      & > input {
        width: 100%;
        height: 4rem;
        border-radius: .5rem;
        padding: 1rem;
        font-size: 1.5rem !important;
        line-height: 2rem !important;
        box-sizing: border-box;
        background: transparent;
        border: 1px solid ${props => props.borderColor};
        color: ${props => props.mainColor};
        ::placeholder {
          color: ${props => props.placeholderColor};
        }
        &:focus, &:active {
          outline: none;
          color: ${props => props.mainColor};
          border: 2px solid ${props => props.focusColor};
          padding: calc(1rem - 1px);
        }
      }
    }

    &.error fieldset {
        border: 2px solid ${props => props.errorColor};
        padding: calc(1rem - 1px);
    }
  }
`
