import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'

import {
  Container,
  HowOftenContainer,
  DropDownLabel,
  UnitInput,
  UnitLabel,
  HowOftenSubContainer
} from './styles'

import DropDownMenu from '../DropDownMenu'

import Title from 'lib/elements/Title'
import * as palette from '../../styles/palette'
import {
  defaultNonOption
} from 'constants.js'

const HowOften = ({
                    toolEL,
                    timeUnitEL,
                    toolList,
                    timeUnitList,
                    onToolClose,
                    onTimeUnitClose,
                    toolName,
                    howOftenId,
                    timeUnitName,
                    onToolOpen,
                    onTimeUnitOpen,
                    unitCount,
                    onChangeUnitCount,
                    theme
                  }) => {
  return (
    <Container>
      <Title color='textPrimary'>
        Wie häufig nehmen Sie das Medikament? (optional)
      </Title>
      <HowOftenContainer>
        <HowOftenSubContainer width='75px' >
          <UnitLabel color={theme.palette.text.primary}>Anzahl</UnitLabel>
          <UnitInput
            onChange={onChangeUnitCount}
            placeholder=""
            value={unitCount}
            lightColor={theme.palette.primary.light}
            mainColor={theme.palette.text.primary}
            borderColor={palette.primaryInputBorder}
            focusColor={theme.palette.primary.main}
            id={howOftenId}
            type="number"
            max="9999"
            min="1"
            step="1"
            width='auto'
          />
        </HowOftenSubContainer>   
        <HowOftenSubContainer>
          <DropDownLabel color={theme.palette.text.primary}>Dosierung</DropDownLabel>
          <DropDownMenu
            anchorEl={toolEL}
            buttonId='tool-btn'
            defaultName={ defaultNonOption }
            dropdownList={toolList}
            isJson={false}
            itemName={toolName}
            menuId='tool-menu'
            onHandleClose={onToolClose}
            onOpen={onToolOpen}
            marginRight='0.5rem'
            width='auto'
          />
        </HowOftenSubContainer>   
        <HowOftenSubContainer>
          <DropDownLabel color={theme.palette.text.primary}>Häufigkeit</DropDownLabel>
          <DropDownMenu
            anchorEl={timeUnitEL}
            buttonId='unit-btn'
            defaultName={ defaultNonOption }
            dropdownList={timeUnitList}
            isJson={false}
            itemName={timeUnitName}
            menuId='unit-menu'
            onHandleClose={onTimeUnitClose}
            onOpen={onTimeUnitOpen}
            width='auto'
          />        
        </HowOftenSubContainer>   
      </HowOftenContainer>
    </Container>
  )
}

HowOften.propTypes = {
  toolEL: PropTypes.objectOf(PropTypes.any),
  toolList: PropTypes.arrayOf(PropTypes.string),
  onChangeUnitCount: PropTypes.func.isRequired,
  onToolClose: PropTypes.func.isRequired,
  onToolOpen: PropTypes.func.isRequired,
  toolName: PropTypes.string,
  timeUnitEL: PropTypes.objectOf(PropTypes.any),
  timeUnitList: PropTypes.arrayOf(PropTypes.string),
  onTimeUnitClose: PropTypes.func.isRequired,
  onTimeUnitOpen: PropTypes.func.isRequired,
  timeUnitName: PropTypes.string,
  unitCount: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
}

HowOften.defaultProps = {
  toolList: [],
  toolName: '',
  timeUnitList: [],
  timeUnitName: ''
}

export default withTheme(HowOften)
