import styled, { css } from 'styled-components'
import * as palette from 'styles/palette'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Logo from '../../assets/img/corporate/teal-logo-text.png'
import ApoLogo from '../../assets/img/corporate/teal-logo-text.svg'

export const MainContainer = styled.div`
  box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.25);
  background-color: ${palette.white};
  width: 600px;
  min-height: 71.8rem;
  position: relative;
  margin: 0 auto;
  &.noWidget {
    @media (max-width: 480px) {
      height: auto;
    }
  }

  @media (max-width: 600px) {
    box-shadow: none;
    border-radius: 0;
    width: 100vw;
    min-height: 100vh;
  }
`

export const ProgressContainer = styled.div`
  padding: 0 5rem 5rem;
  position: relative;
  @media (max-width: 600px) {
    padding: 0 1.8rem 1.8rem;
    box-shadow: none;
  }
`

export const Title = styled(Typography)`
 &&& {
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 6.1rem;
  text-align: center;
  margin: 0;
  @media (max-width: 600px) {
    display: none;
  }
 }
`

export const SubTitle = styled(Title)`
  &&& {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.8rem;
    padding-bottom: 3rem;
    max-width: 73rem;
    ${props => props.hasfilledmessage && css`opacity: 0; height: 40px;`}
  }
`

export const BottomBtnGroup = styled.div`
  width: 100%;
  margin-top: 2rem;
  padding: 0;
  left: 0;
  @media (max-width: 600px) {
    bottom: 3.6rem;
  }
`

export const ButtonItem = styled(Button)`
  &&& {
    text-transform: none;
    margin: 0;
    color: ${palette.white};
    ${props => (props.title === 'next' || props.title === 'skip') && css`margin-left: 1rem`};
    ${props => props.width ? css`width: ${props.width}` : css`width: 50%`};
  }
`


export const ButtonLink = styled.div`
  text-align: center;
  color: #8E1441;
  cursor: pointer;
  margin-top: 20px;
`

export const ButtonItemNext = styled(Button)`
  &&& {
    text-transform: none;
    margin: 0;
    margin-top: 2rem;
    color: ${props => props.title === 'skip' ? props.themeColor : palette.white};
    ${props => props.title === 'skip' && css`border: 1px solid ${props.themeColor}`};
    background-color: ${props => props.title === 'skip' && palette.white};
    ${props => (props.title === 'next' || props.title === 'skip') && css`margin-left: 1rem`};
    ${props => props.disabled ? css`opacity: 0.1` : css`opacity: 1`};
    ${props => props.width ? css`width: ${props.width}` : css`width: 50%`};
    ${props => props.currentStep === 4 && css`
      background-color: ${props => props.themeColor};
      ${props => props.btnTxt === 'yes' && css`margin-left: 1rem`};
    `}
    &:hover {
      background-color: ${props => props.title === 'skip' && props.themeColor};
      color: ${props => props.title === 'skip' && palette.white};
      ${props => props.currentStep === 4 && css`
        background-color: ${props => props.themeColor};
      `}
    }
    &:disabled {
      opacity: 0.25;
      color: #fff;
      background-color: ${props => props.themeColor};
    }
  }
`

export const FlexButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1.1rem;
`

export const ItemsList = styled.div`
  ul {
    color: ${props => props.color};
    padding-left: 0px;
    list-style-type: none;
    font-size: 1.5rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  p {
    color: ${props => props.textColor};
    font-size: 1.6rem;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`
export const EmailText = styled.div`
  color: ${props => props.color};
  margin: 3rem 0 0.5rem;
  font-size: 1.5rem;
  line-height: 1.6em;
  font-weight: bold;
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 2rem;
    line-height: 1.2em;
  }
`
export const IdentText = styled.div`
   color: ${props => props.color};
   font-size: 1.5rem;
   text-align: left;
   line-height: 1.6em;
   margin: 0 0 3rem;

   strong {
     font-weight: bold;
   }
`
export const BackAnchor = styled.span`
  color: ${props => props.themecolor};
  min-width: 140px;
  cursor: pointer;
  margin-top: 10px;
`
export const ButtonSpacer = styled.span`
  width: 50%;
`
export const StepWrapper = styled.div`
  margin-top: 0.7rem;
  min-height: 45rem;
  position: relative;
  @media (max-width: 640px) {
    min-height: 42rem;
  }
`

export const PregnancyPlaceholder = styled.div`
  width: 378px;
  height: 82.74px;
  @media (max-width: 640px) {
    height: 106px;
  }
`
export const HeaderContainer = styled.header`
  display: block;
  padding: 10px;

  .logo {
    width: 550px;
    max-width: 100%;
    height: 75px;
    background: url(${Logo}) center left no-repeat;

    &.apotheken-umschau {
      background-image: url(${ApoLogo});
    }
  }

  @media (max-width: 600px) {
    padding: 0;

    .logo {
      display: none;
    }
  }
`


export const FooterContainer = styled.footer`
  margin: 1rem 0 0;

  .footer-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 25px;
    display: border-box;
    padding: 0 1.5rem;

    @media only screen and (max-width: 769px) {
      flex-direction: column;
    }
  }

  .footer-section {    
    h4 {
      text-align: left;

      @media only screen and (max-width: 769px) {
        text-align: center;
      }
    }
    
    ul {
      list-style-type: none;
      margin-top: 15px;
      font-family: "Nunito";
      padding: 0;
      &.horizontal { 
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 0;

        li + li {
          margin-left: 2rem;
        }
      }
    }
    
    li {
      margin-top: 10px;
      font-size: 0.85rem;
    }

    a {
      color: #007a82;
      text-decoration: none;
  
      &:hover {
          text-decoration: underline;
      }
    }
  }

  .footer-image-container {
    width: auto;
    margin: 0 0 0.8rem;
  }

  .footer-image {
    max-width: 100%;
    max-height: 80px;
    &--bundes {
      margin-right: 30px;
    }
    @media only screen and (max-width: 321px) {
      max-height: 50px;
    }
  }

  .footer-bottom {
    background-color: #4a4a4a;
    width: 100%;
    height: 50px;
    display: flex;
    box-sizing: border-box;
    padding: 0 7%;
    justify-content: space-between;
    align-items: center;
    p {
      color: white;
      padding: 0;
    }
    img {
      max-height: 20px;
    }
    img:first-child {
      margin-right: 10px;
    }
  }
`