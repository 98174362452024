import styled from 'styled-components'
import * as palette from 'styles/palette'

const Container = styled.div`
  background-color: ${palette.superLightGreen};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  &.noWidget {
    justify-content: space-between;

    header,
    footer {
      box-sizing: border-box;
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    height: 100%;
  }
`
export default Container
