import styled from 'styled-components'
import * as palette from 'styles/palette'

export const Pop_Up = styled.span`

  color: #007a82;
  text-decoration: none;
  font-size: 1.6rem;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
