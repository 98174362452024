import React, { useState, useEffect } from 'react'

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'

import {
  CloudUpload,
  Error,
  Done,
  DeleteOutline
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import * as palette from 'styles/palette'
import { uploadAnswerFile, deleteAnswerFile } from 'service/index'

import LinearProgressBar from '../LinearProgressBar'

const FILE_TYPES_ALLOWED = ['application/pdf', 'image/jpeg', 'image/png']
const MAX_FILE_SIZE_ALLOWED = 3e7

const useStyles = makeStyles(theme => ({
  fileUploadcontainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2rem 0 1rem 0',

    '@media (max-width:600px)': {
      flexDirection: 'column'
    },        
  },
  uploadBtnContainer: {
    width: '50%',

    '@media (max-width:600px)': {
      width: '60%',
    },        
    '@media (max-width:520px)': {
        width: '70%',
    },        
    '@media (max-width:360px)': {
        width: '80%',
    },        
    '@media (max-width:300px)': {
        width: '100%',
    },        
  },
  uploadBtn: {
    margin: 0,
    fontWeight: 600
  },
  uploadBtnError: {
    margin: 0,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  uploadStatusContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',

    '@media (max-width:600px)': {
      width: '60%',
      margin: '1rem 0'
    },        
    '@media (max-width:520px)': {
        width: '70%',
        margin: '1rem 0'
    },        
    '@media (max-width:360px)': {
        width: '80%',
        margin: '1rem 0'
    },        
    '@media (max-width:300px)': {
        width: '100%',
        margin: '1rem 0'
    },
  },
  progressBarWrapper: {
    width: '84%'
  },
  errorInfoWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  errorIcon: {
    fontSize: '2rem',
    margin: '0 2rem'
  },
  errorText: {
    fontWeight: 600
  },
  successInfoWrapper: {
    width: '94%',
    display: 'flex',
    alignItems: 'center',

    '@media (max-width:600px)': {
      width: '98%',
    }
  },
  successText: {
    maxWidth: '80%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  doneIcon: {
    fontSize: '2.4rem',
    marginLeft: '6px'
  },
  deleteIcon: {
    fontSize: '2rem',
    cursor: 'pointer',
    marginLeft: '6px'
  },
  subTextContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '& div': {
      width: '50%',
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      fontWeight: 'normal',
      color: palette.searchTxtColor,

      '@media (max-width:600px)': {
        width: '75%',
        paddingLeft: 0,
      }
    },
    '& div:last-child': {
      paddingLeft: '2rem',

      '@media (max-width:600px)': {
        width: '25%',
        paddingLeft: '0.2rem',
      }
    }
  }
}))

function FileUpload({ questionId, templateId, reportId, onHandleFileAnswer, answerData }) {
  const classes = useStyles();
  const [uploadStatus, setUploadStatus] = useState(null);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [uploadedFileData, setUploadedFileData] = useState(null)

  useEffect(() => {
    if(answerData){
      setUploadedFileData(answerData)
      setUploadStatus('success')
    }
  }, [answerData])
  
  const handleUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 95) / progressEvent.total
    )
    setPercentUploaded(percentCompleted)
  }

  const handleUploadFile = event => {
    const fileToUpload = event.target.files[0]
    
    // Reset the value of file input, (which also removes it from browser cache), 
    // otherwise uploading same file again (in same session) doesn't work
    event.target.value = ''
    
    if (fileToUpload) {
      // if file size is > then 30mb or file is of type not supported, reject it
      if (
        fileToUpload.size > MAX_FILE_SIZE_ALLOWED ||
        !FILE_TYPES_ALLOWED.includes(fileToUpload.type)
      ) {
        setUploadStatus('error')
        return
      }

      setUploadStatus('in-progress')

      let data = new window.FormData()
      data.append('followup_questions', fileToUpload)

      uploadAnswerFile(
        data,
        reportId,
        templateId,
        handleUploadProgress
      )
        .then(response => {
          const fileData = {
            id: response.data._id,
            name: fileToUpload.name
          }
          setUploadedFileData(fileData)
          setPercentUploaded(100)
          setUploadStatus('success')
          onHandleFileAnswer(questionId, fileData)
        })
        .catch(function (error) {
          console.log(error);
          setUploadStatus('error')
        })

    }
  }

  const handleDeleteFile = event => {
    if (uploadedFileData && uploadedFileData.id) {
      deleteAnswerFile(uploadedFileData.id, reportId, templateId)
        .then(response => {
          setUploadStatus(null)
          setUploadedFileData(null)
          onHandleFileAnswer(questionId, null)
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  }

  return (
    <>
      <div className={classes.fileUploadcontainer}>
        <div className={classes.uploadBtnContainer}>
          <input
            style={{ display: 'none' }}
            id={`followup__file_upload_btn_${questionId}`}
            type='file'
            onChange={handleUploadFile}
            accept={FILE_TYPES_ALLOWED}
          />
          <Button
            htmlFor={`followup__file_upload_btn_${questionId}`}
            component='label'
            variant={(uploadStatus === 'error') ? 'outlined' : 'contained'}
            color={(uploadStatus === 'error') ? 'error' : 'primary'}
            fullWidth
            className={(uploadStatus === 'error') ? classes.uploadBtnError : classes.uploadBtn}
            startIcon={<CloudUpload />}
            disabled={(uploadStatus === 'in-progress') || (uploadStatus === 'success' && uploadedFileData !== null && uploadedFileData.id !== undefined)}
          >
            Datei hochladen
          </Button>
        </div>
        <div className={classes.uploadStatusContainer}>
          {(uploadStatus === 'in-progress') &&
            <div className={classes.progressBarWrapper}>
              <LinearProgressBar value={percentUploaded} />
            </div>
          }
          {(uploadStatus === 'error') &&
            <div className={classes.errorInfoWrapper}>
              <Error color='error' className={classes.errorIcon} />
              <Typography variant='body1' color='error' className={classes.errorText}>Die Datei hat ein falsches Format oder übersteigt die max. Größe</Typography>
            </div>
          }
          {(uploadStatus === 'success') &&
            <div className={classes.successInfoWrapper}>
              <Typography variant='body1' className={classes.successText}>{uploadedFileData.name}</Typography>
              <Done color='primary' className={classes.doneIcon} />
              <DeleteOutline className={classes.deleteIcon} onClick={handleDeleteFile} />
            </div>
          }
        </div>
      </div>
      <div className={classes.subTextContainer}>
        <div>Erlaubte Dateiformate: PDF, JPEG, JPG, PNG;</div>
        <div>max. 30 MB</div>
      </div>
    </>
  )
}


export default FileUpload
