import React, { Component } from 'react'
// material ui imports
import { withTheme } from '@material-ui/core'
// styles, components, constants imports
import Title from 'lib/elements/Title'
import Container from 'lib/elements/Container'
import { StyledHeader, StyledTitle, StyledWord } from '../styles'
import {
  BottomBtnGroup,
  ButtonItemNext,
  FlexButtons,
  BackAnchor,
  ItemsList,
  StepWrapper,
  MainContainer,
  ProgressContainer
} from '../../MainPage/styles'
import HowOftenFollowup from '../../../components/HowOftenFollowup'
import { DrugDoseIntervalElements, DrugDoseUnitElements } from '../../../constants'
import * as lodash from 'lodash'
import PropTypes from 'prop-types'

const backButtonStyle = {
  marginTop: '4.5rem'
}

export class SideEffectsStep extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sideEffect: '',
      drugs: [],
      toolEL: null,
      timeUnitEL: null,
      questions: {}
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  // Next Step & Previous Step Handlers
  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  };
  back = e => {
    e.preventDefault()
    this.props.prevStep()
  };
  // end of next previous step handlers

  // dose amount
  handleChangeDoseAmount = (index) => {
    const pattern = /^[0-9]{0,4}$/
    return (event) => {
      if (event.target.value === '' || pattern.test(event.target.value)) {
        this.setState([{ amount: event.target.value }])
      }
      this.props.handleAddDoseAmount(index, event.target.value)
    }
  }

  // dose interval
  handleDoseTimeChange = (index) => {
    return (item) => {
      if (item) {
        this.setState({ doseTimeName: item || this.state.doseTimeName })
        this.props.handleAddDoseInterval(index, item)
      }
    }
  }

  // dose unit
  handleDoseUnitChange = (index) => {
    return (item) => {
      if (item) {
        this.setState({ doseUnitName: item || this.state.doseUnitName })
        this.props.handleAddDoseUnit(index, item)
      }
    }
  }

  // render
  render () {
    const { drugs, answerDrugs } = this.props
    const {
      toolEL,
      amount
    } = this.state

    return (
      <Container>
        <MainContainer>
          <StyledHeader><StyledTitle>Der Arzneimittelhersteller hat folgende wichtige Rückfragen zu Ihrer Meldung:</StyledTitle></StyledHeader>
          <ProgressContainer><div><StepWrapper>
            <Title><h3> Dosierung der Medikation</h3></Title>
            <ItemsList>
              <div>{
                <ul>
                  {drugs.drugs && drugs.drugs.map((drug, index) => {
                    let defaultValue = 'unknown'
                    let doseTimeName = lodash.get(answerDrugs, `[${index}].dose.interval`, 'unknown')
                    let doseUnitName = lodash.get(answerDrugs, `[${index}].dose.unit`, 'unknown')
                    return [
                      <Title>Wie häufig nehmen Sie <StyledWord>{drug.name}</StyledWord>?</Title>,
                      <br />,
                      <HowOftenFollowup
                        doseTimeList={DrugDoseIntervalElements}
                        doseTimeName={doseTimeName}
                        doseTimeNameColor={doseTimeName === defaultValue ? this.props.theme.palette.primaryGrey : this.props.theme.palette.primary.main}
                        doseUnitList={DrugDoseUnitElements}
                        doseUnitName={doseUnitName}
                        doseUnitNameColor={doseUnitName === defaultValue ? this.props.theme.palette.primaryGrey : this.props.theme.palette.primary.main}
                        howOftenId={'how-often-id'}
                        onChangeUnitCount={this.handleChangeDoseAmount(index)}
                        onDoseTimeChange={this.handleDoseTimeChange(index)}
                        onDoseUnitChange={this.handleDoseUnitChange(index)}
                        toolEL={toolEL}
                        unitCount={amount}
                      />]
                  })}
                </ul>
              }</div>
            </ItemsList></StepWrapper>
          <BottomBtnGroup>
            <FlexButtons>
              <BackAnchor
                style={backButtonStyle}
                onClick={this.back}
                title='back'
                variant='contained'
                themecolor={this.props.theme.palette.primary.main}
              >
                  ◄ Zurück
              </BackAnchor>
              <ButtonItemNext
                color='primary'
                id='next'
                onClick={this.continue}
                title='next'
                variant='contained'
              >
                  Weiter
              </ButtonItemNext >
            </FlexButtons>
          </BottomBtnGroup>
          </div></ProgressContainer></MainContainer>
      </Container>
    )
  }
}

SideEffectsStep.propTypes = {
  drugs: PropTypes.bool.isRequired,
  answerDrugs: PropTypes.bool.isRequired
}

export default withTheme(SideEffectsStep)
