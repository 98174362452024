import styled from 'styled-components'
import * as palette from 'styles/palette'
import hexToRgba from 'hex-to-rgba'

export const Container = styled.div`
  padding-top: 0.5rem;

  &.high {
    padding-top: 0;
  }
`

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .react-datepicker-popper {
    .react-datepicker {
      border: 1px solid ${props => props.primaryColor};
      background-color: ${palette.superLightGreen};
      min-width: 34rem;
      min-height: 34rem;
      margin: 0 auto;
      @media (max-width: 600px) {
        margin-left: 125px;
        &::first-child {
          top: -10rem;
          left: -4rem;
        }
        &::last-child {
          top: -10rem;
          left: -19rem;
        }
      }
      &__triangle {
        display: none;
      }
      &__header {
        background-color: ${palette.superLightGreen};
        padding-top: 2.5rem;
        border: none;
      }
      &__navigation {
        &--previous {
          border-right-color: ${props => props.lightColor};
          left: 7rem;
          top: 3rem;
        }
        &--next {
          border-left-color: ${props => props.lightColor};
          right: 7rem;
          top: 3rem;
        }
      }
      &__month-container {
        width: 100%;
        height: 100%;
      }
      &__current-month {
        font-size: 1.5rem;
        line-height: 2rem;
        color: ${props => props.lightColor};
        padding-bottom: 1.5rem;
      }
      &__day-name, &__day {
        color: ${palette.dateColor};
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: bold;
        width: 3.2rem;
        height: 3.2rem;
        line-height: 3.2rem;
        &--selected {
          background-color: ${props => props.lightColor};
          color: ${palette.white} !important;
        }
      }
      &__day {
        color: ${palette.darkGrey};
        font-weight: 400;
        &--today {
          color: ${palette.darkGreen};
        }
        &--keyboard-selected {
          background-color: ${palette.superLightGreen};
        }
      }
    }
  }
`

export const DateContainerNative = styled.div`
  margin-bottom: 1rem;
`

export const NativeInput = styled.input`
  width: 160px;
  height: 4rem;
  padding-left: 7px;
  line-height: 2rem;
  border-radius: 5px;
  background: transparent;
  border: 1px solid ${props => props.borderColor};
  color: ${props => hexToRgba(props.inactiveColor, 0.4)};
  &:valid {
    color: ${props => props.mainColor};
  }
  &:invalid {
    box-shadow: none;
  } 
  &:focus, &:active {
    outline: none;
    border: 2px solid ${props => props.focusColor};
    padding-left: 6px;
  } 
  &.error {
    outline: none;
    border: 2px solid ${props => props.errorColor};
    padding-left: 6px;
    box-shadow: none;
  }

  @media (max-width: 640px) {
    width: 157px;
    background-color: transparent;
  }
`
