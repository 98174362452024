import styled from 'styled-components'
import * as palette from 'styles/palette'
import Typography from '@material-ui/core/Typography'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const GenderBtn = styled(Typography)`
  &&& {
    display: flex;
    align-items: center;
    color: ${props => props.clicked ? palette.white : props.color};
    background-color: ${props => props.clicked ? props.color : palette.white};
    border: 1px solid ${props => props.color};
    border-radius: .5rem;
    padding: .7rem 0;
    margin-right: .5rem;
    text-align: center;
    justify-content: center;
    font-size: 1.4rem;
    line-height: 18px;
    height: 40px;
    width: 100%;
  
    &:last-child {
      magin-right: 0;
    }
    &:hover {
      cursor: pointer;
    }
    svg {
      padding-left: .5rem;
      & > path {
        fill: ${props => props.clicked ? palette.white : props.color};
      }    
    }  
  }  
`
