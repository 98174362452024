import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as palette from 'styles/palette'
import { translate, Trans } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

const Container = styled(Typography)`
  &&& {
    font-family: Bitter;
    padding: 1.8rem 0;
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.6rem;
  }
`

const ProgressTitle = ({ step, substep }) => {
  let titles = [
    'Ihre vermuteten Nebenwirkungen eingeben',
    'Ihre Medikamente eingeben',
    'Angaben zum Körper',
    'Angaben zu Vorerkrankungen',
    'Angaben zu Risikofaktoren',
    '',
    'Ihre Meldung',
    'Ihr Kommentar',
    'Ihre Übersicht',
    '',
    'Ihr Arzt oder Apotheker',
    ''
  ]
  return (
    <Container color="textPrimary">
      { titles[step - 1] }
    </Container>
  )
}

ProgressTitle.propTypes = {
  step: PropTypes.number.isRequired
}

export default translate('translations')(ProgressTitle)
