import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import * as palette from 'styles/palette'

export const StepContainer = styled.div`
padding-top: 1.7rem;
justify-content: space-between;
align-items: center;
display: flex;
`

export const StepTitle = styled(Typography)`
&&& {
  font-size: 1.7rem;
  line-height: 2.4rem;
  font-weight: 700;
  margin: 0;
  color: ${palette.primaryGrey};
}
`

export const StepDesc = styled.p`
margin: 0;
color: ${props => props.color};
&, div {
  font-size: 1.3rem;
  line-height: 2.1rem;
}
`

export const EditIconItem = styled(EditIcon)`
background-color: ${props => props.themeColor};
color: ${palette.white};
border-radius: .5rem;
padding: .5rem;
&:hover {
  opacity: 0.8;
  cursor: pointer;
}
`

export const SpinnerContainer = styled.div`
width: 100%;
text-align: center;
`

export const Description = styled.p`
font-size: 1.6rem;
line-height: 2.2rem;
color: ${palette.darkGrey};
@media (max-width: 360px) {
  line-height: 1.8rem;
}
`
