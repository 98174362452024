
import styled from 'styled-components'
import * as palette from 'styles/palette'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

export const Container = styled.div`
  padding: 4rem 5rem 5rem;
  position: relative;
  @media (max-width: 600px) {
    padding: 1rem 1.8rem 1.8rem;
    box-shadow: none;
  }
`

export const LogoContainer = styled.div`
  text-align: center;
  padding-top: 0.3rem;
  padding-bottom: 6.5rem;
  @media (max-width: 600px) {
    padding-bottom: 1.5rem;
  }

  .patient-service__logo {
    width: 15.2rem;
    max-width: 100%;
    height: 2.4rem;
  }
`

export const Content = styled.div`
  min-height: 41rem;
`

export const Title = styled(Typography)`
  &&& {
    font-family: Bitter, sans-serif;
    font-weight: 400;
    margin: 2.7rem 0;
    font-size: 2.8rem;

    @media (max-width: 600px) {
      font-size: 1.8rem;
    }
  }
`

export const Description = styled(Typography)`
  &&& {
    font-weight: 400;
    font-size: 2.1rem;

    @media (max-width: 600px) {
      font-size: 1.6rem;
    }
  }
`

export const ButtonGroup = styled.div`
  width: 100%;
  margin-top: 2rem;
  padding: 0;
  left: 0;
`

export const ButtonItem = styled(Button)`
  &&& {
    text-transform: none;
    margin: 0;
    color: ${palette.white};
    width: 100%;
    padding: 1.6rem 0;
  }
`
