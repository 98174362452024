import styled from 'styled-components'
import * as palette from 'styles/palette'
import DatePicker from 'react-datepicker'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import hexToRgba from 'hex-to-rgba';

export const Container = styled.div`
  padding-top: 0.5rem;

  &.high {
    padding-top: 0;
  }
`

export const DateCustomInputContainer = styled.div`
  &&& {
    border: 1px solid ${props => props.value ? props.darkColor : props.primaryColor};
    color: ${props => props.lightColor};
    background-color: ${palette.superLightGreen};
    border-radius: 5px;
    padding: .7rem;
    height: 4rem;
    width: 14rem;
    display: flex;
    align-items: center;
    &:focus, &:active {
      outline: none;
      border: 2px solid ${props => props.lightColor};
      padding: calc(.7rem - 1px);
    }
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 600px) {
      width: 11.5rem;
    }

    @media (max-width: 360px) {
      width: 10.5rem;
    }
  }
`

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .react-datepicker-popper {
    .react-datepicker {
      border: 1px solid ${props => props.primaryColor};
      background-color: ${palette.superLightGreen};
      min-width: 34rem;
      min-height: 34rem;
      margin: 0 auto;
      @media (max-width: 600px) {
        margin-left: 125px;
        &::first-child {
          top: -10rem;
          left: -4rem;
        }
        &::last-child {
          top: -10rem;
          left: -19rem;
        }
      }
      &__triangle {
        display: none;
      }
      &__header {
        background-color: ${palette.superLightGreen};
        padding-top: 2.5rem;
        border: none;
      }
      &__navigation {
        &--previous {
          border-right-color: ${props => props.lightColor};
          left: 7rem;
          top: 3rem;
        }
        &--next {
          border-left-color: ${props => props.lightColor};
          right: 7rem;
          top: 3rem;
        }
      }
      &__month-container {
        width: 100%;
        height: 100%;
      }
      &__current-month {
        font-size: 1.5rem;
        line-height: 2rem;
        color: ${props => props.lightColor};
        padding-bottom: 1.5rem;
      }
      &__day-name, &__day {
        color: ${palette.dateColor};
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: bold;
        width: 3.2rem;
        height: 3.2rem;
        line-height: 3.2rem;
        &--selected {
          background-color: ${props => props.lightColor};
          color: ${palette.white} !important;
        }
      }
      &__day {
        color: ${palette.darkGrey};
        font-weight: 400;
        &--today {
          color: ${palette.darkGreen};
        }
        &--keyboard-selected {
          background-color: ${palette.superLightGreen};
        }
      }
    }
  }
`

export const FromToText = styled.label`
  display: block;
  color: ${props => props.color};
  margin: ${props => props.type === 'to'  ? '1.5rem 0 0.5rem' : '0 0 0.5rem'};

  &.high {
    margin-top:2rem;
  }
`

export const Label = styled.label`
  font-size: 1.6rem;
  color: ${props => props.color};
`

export const DateContainerNative = styled.div`
  margin-bottom: 1rem;
`

export const Spacer = styled.span`
  display: block;
  margin-top: 14px;
  @media (min-width: 767px) {
    display: none;
  }
`

export const MobileInput = styled.span`
  @media (max-width: 490px) {
    width: 50px;
  }
`

export const NativeInput = styled.input`
  width: 160px;
  height: 4rem;
  padding-left: 7px;
  line-height: 2rem;
  border-radius: 5px;
  background: transparent;
  border: 1px solid ${props => props.borderColor};
  color: ${props => hexToRgba(props.inactiveColor, 0.4)};
  &:valid {
    color: ${props => props.mainColor};
  }
  &:invalid {
    box-shadow: none;
  }
  &:focus, &:active {
    outline: none;
    border: 2px solid ${props => props.focusColor};
    padding-left: 6px;
  }  
  &.error {
    outline: none;
    border: 2px solid ${props => props.errorColor};
    padding-left: 6px;
    box-shadow: none;
  }

  @media (max-width: 640px) {
    width: 157px;
    background-color: transparent;
  }
  
  &::before {
    content: attr(placeholder) !important;
    margin-right: 0.5em;
  }
`

export const DatePickerBlock = styled.div`
  position: relative;
  width: 14rem;
  display: inline-block;
  vertical-align: middle;
`

export const MedWidgetDatePicker = styled(DatePicker)`
  border: 1px solid ${props => props.primaryColor};
  color: ${props => props.lightColor};
  background-color: ${palette.superLightGreen};
  border-radius: 5px;
  padding: .7rem;
  height: 4rem;
  width: 14rem;
  display: flex;
  &:focus, &:active {
    outline: none;
    border: 2px solid ${props => props.lightColor};
    padding: calc(.7rem - 1px);
  }
  @media (max-width: 490px) {
    width: 11.5rem;
  }
  @media (max-width: 370px) {
    width: 10.5rem;
  }
`

export const CalendarIcon = styled(CalendarTodayIcon)`
  &&& {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    color: ${props => props.lightColor};
  }
`

export const DateCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -1.5rem;
  svg {
    color: ${props => props.primaryColor};
    background: ${palette.white};
    fill: ${props => props.primaryColor};
  }
  p {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`
export const InfoContainer = styled.div`
  color: ${props => props.color};
  padding: 1rem 0 0.9rem;
}
`

export const DropDownLabel = styled.label`
  display: block;
  font-size: 1.3rem;
  color: ${props => props.color};
  width: calc(125px + 2.3rem);
  padding-left: 0.7rem;
`

export const UnitLabel = styled.label`
  font-size: 1.3rem;
  color: ${props => props.color};
`

export const SingleDateInput = styled.input`
  background-color: transparent;
  border: 1px solid ${props => props.borderColor};
  color: ${props => props.mainColor};
  ::placeholder {
    color: ${props => props.borderColor};
  }
  &:focus, &:active {
    outline: none;
    border: 2px solid ${props => props.focusColor};
    padding: calc(1.2rem - 1px);
  }
  &.error {
    border: 2px solid ${props => props.errorColor};
    padding: calc(1.2rem - 1px);
  }
  outline: none;
  width: 6.5rem;
  height: 4rem;
  border-radius: 5px;
  margin-left: 1rem;
  padding: 1.2rem;
`
