
import styled from 'styled-components'
import * as palette from 'styles/palette'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

export const Container = styled.div`
  position: relative;
  padding: 2rem 0 4.1rem 0rem;
`

export const LogoContainer = styled.div`
  text-align: center;
  padding-top: 0.3rem;
  padding-bottom: 2.4rem;
  @media (max-width: 600px) {
    padding-bottom: 1.5rem;
  }

  .patient-service__logo {
    width: 15.2rem;
    max-width: 100%;
    height: 2.4rem;
  }
`

export const StatusImage = styled.div`
  padding-top: 1.3rem;
  padding-bottom: 1.7rem;
  display: flex;
  justify-content: center;
  
  .patient-service__status-image {

    max-width: 500px;
    @media (max-width: 500px) {
      max-width: 100%;
    }
  }
`

export const Content = styled.div`
  min-height: 41rem;
`

export const Title = styled(Typography)`
  &&& {
    padding-left: 3.2rem;
    padding-right: 1.9rem;
    color: ${props => props.color};
    font-family: Bitter, sans-serif;
    font-weight: 400;
    font-size: 1.8rem;

    @media (max-width: 600px) {
      font-size: 1.8rem;
    }
  }
`

export const Description = styled(Typography)`
  &&& {
    padding-left: 3.2rem;
    padding-right: 1.9rem;
    color: ${props => props.color};
    font-weight: 400;
    font-size: 1.6rem;

    @media (max-width: 600px) {
      font-size: 1.6rem;
    }
  }
`

export const ButtonGroup = styled.div`
  width: 100%;
  margin-top: 2rem;
  padding: 0 1.9rem;
  left: 0;
`

export const ButtonItem = styled(Button)`
  &&& {
    text-transform: none;
    margin: 0;
    color: ${palette.white};
    width: 100%;
    padding: 1.6rem 0;
  }
`

export const BackAnchor = styled.span`
  color: ${palette.lightGreen};
  min-width: 140px;
  cursor: pointer;
  margin-top: 10px;
  padding: 0 0 0 50px;
`

export const BottomBtnGroup = styled.div`
  width: 100%;
  margin-top: 2rem;
  padding: 0;
  left: 0;
  @media (max-width: 600px) {
    bottom: 3.6rem;
  }
`

export const FlexButtons = styled.div`
  display: flex;
  justify-content: space-between;
`