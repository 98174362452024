import React from 'react'
import FollowupPage from 'containers/FollowupPage/index'
import MainPage from 'containers/MainPage'
import { I18nextProvider } from 'react-i18next'
import i18n from '../../i18n'

const App = () => {
  if (process.env.CI_PIPELINE_ID) {
    console.info(`[INFO] Running build ${process.env.CI_PIPELINE_ID}`)
  }

  if (window.location.pathname.match(/^\/follow-ups\/report\/([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9Aa-f]{3}-[0-9a-f]{12})/)){
    const user = {
      app_id: process.env.REACT_APP_INTERCOM_ID,
      isPatient: true
    }

    user.app_id && window.Intercom('boot', user)
  }

  return (
    <I18nextProvider i18n={i18n}>
      {window.location.pathname.match(/^\/follow-ups\/report\/([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9Aa-f]{3}-[0-9a-f]{12})/) ? <FollowupPage /> : <MainPage />}
    </I18nextProvider>
  )
}

export default App
