import React, { useEffect } from 'react'
import { withTheme } from '@material-ui/core/styles'
import { trackGoogleAnalyticsEvent } from 'service/tracker'

import {
  Container,
  CheckIconItem,
  ResultHeadline,
  Description
} from './styles'

const ThanksHcpStep = ({theme}) => {
  useEffect(() => {
    trackGoogleAnalyticsEvent('PageView - ThanksHcpStep', true)
  }, []);

  return (
    <Container>
      <CheckIconItem theme={theme} />
      <ResultHeadline theme={theme}>
          Vielen Dank!
      </ResultHeadline>
      <Description color={theme.palette.text.primary}>
          Ihr Arzt oder Apotheker wird informiert und kann die Meldung für den Hersteller zusätzlich ergänzen.
          Unabhängig davon ist Ihre Meldung bereits wirksam und hilft die Arzneimittelsicherheit zu verbessern.
      </Description>
      <Description color={theme.palette.text.primary}>
        💞 Ihre Mitmenschen danken es Ihnen! 💞
      </Description>
      <Description color={theme.palette.text.primary} fontWeight={700}>
          Weiterhin alles Gute für Ihre Gesundheit!
          Ihr Team von Nebenwirkungen.de 💐
      </Description>
    </Container>
  )
}

export default withTheme(ThanksHcpStep)
