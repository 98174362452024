import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        'title': 'Your side effect message',
        'side effects': 'side effects',
        'What side effect do you have?': 'What side effect do you have?',
        'In which period?': 'In which period?',
        'How long have you been taking this?': 'How long have you been taking this?',
        'e.g. Nausea vomiting': 'e.g. Nausea, vomiting',
        'From': 'From',
        'to': 'to',
        'I do not know the exact dates': 'I do not know the exact dates',
        'Please select an amount.' : 'Please select an amount.',
        'Please select a timeframe.' : 'Please select a timeframe.',
        'Male': 'Male',
        'Female': 'Female',
        'Please enter the name of your doctor or pharmacist.': 'Please enter the name of your doctor or pharmacist.',
        'Please enter the name of your medication. You can add more side effects by pressing the plus below.': 'Please enter the name of your medication. You can add more side effects by pressing the plus below.',
        'Please enter the name of your medication.': 'Please enter the name of your medication.',
        'Please enter the side effect you are experiencing. You can add more side effects by pressing the plus below.': 'Please enter the side effect you are experiencing. You can add more side effects by pressing the plus below.',
        'Please enter the side effect you are experiencing.': 'Please enter the side effect you are experiencing.',
        'Please enter only one side effect you are experiencing. You can add more side effects by pressing the plus below.': 'Please enter only one side effect you are experiencing. You can add more side effects by pressing the plus below.',
        'Please select a gender.': 'Please select a gender.',
        'Please select a birthyear.': 'Please select a birthyear.',
        'Please free your doctor/pharmacist from their duty of doctor-patient confidentiality.' : 'Please free your doctor/pharmacist from their duty of doctor-patient confidentiality.'
      }
    },
    de: {
      translations: {
        'title': 'Ihre Nebenwirkungs­meldung',
        'side effects': 'Melden Sie hier Ihre Nebenwirkung',
        'What side effect do you have?': 'Bitte geben Sie eine Nebenwirkung ein:',
        'In which period?': 'Wann ist diese aufgetreten?',
        'e.g. Nausea vomiting': 'z. B. Übelkeit',
        'From': 'Von',
        'How long have you been taking this?': 'Seit wann nehmen Sie dieses?',
        'to': 'bis',
        'I do not know the exact dates': 'Ich kenne den genauen Zeitraum nicht',
        'Please select an amount.' : 'Bitte wählen Sie eine Anzahl.',
        'Please select a timeframe.' : 'Bitte wählen Sie einen Zeitraum aus.',
        'Male': 'Männlich',
        'Female': 'Weiblich',
        'Please enter the name of your doctor or pharmacist.': 'Bitte geben Sie den Namen Ihres Arztes oder Apothekers ein.',
        'Please enter the name of your medication. You can add more side effects by pressing the plus below.': 'Bitte geben Sie nur ein Medikament ein, weitere können mittels des unteren "+Buttons" hinzugefügt werden.',
        'Please enter the name of your medication.': 'Bitte tragen Sie den Namen des Medikamentes ein.',
        'Please enter the side effect you are experiencing. You can add more side effects by pressing the plus below.': 'Bitte tragen Sie genau eine Nebenwirkung ein.',
        'Please enter the side effect you are experiencing.': 'Bitte tragen Sie Ihre Nebenwirkung ein.',
        'Please enter only one side effect you are experiencing. You can add more side effects by pressing the plus below.': 'Bitte geben Sie nur eine Nebenwirkung ein, weitere können mittels des unteren "+Buttons" hinzugefügt werden.',
        'Please select a gender.': 'Bitte wählen Sie Ihr Geschlecht aus.',
        'Please select a birthyear.': 'Bitte tragen Sie Ihr Geburtsjahr ein, z. B. 1987.',
        'Please free your doctor/pharmacist from their duty of doctor-patient confidentiality.' : 'Bitte der Schweigepflichtsentbindung zustimmen, damit Ihr Arzt oder Apotheker eingebunden werden kann.'
      }
    }
  },
  fallbackLng: 'de',
  debug: true,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  }
})

export default i18n
