import { createGlobalStyle } from 'styled-components'
import * as palette from './palette'
import 'react-datepicker/dist/react-datepicker.css'

// Global styled
const GlobalStyle = createGlobalStyle`
  html {
    overflow-x: hidden;
    font-size: 10px!important;
  }

  body {
    margin: 0;
    font-family: Nunito, sans-serif;
    font-size: 1rem!important;
    font-weight: 300!important;
    font-style: normal!important;
    background: ${palette.primaryBackground}!important;
  }

  input[type="password" i] {
    -webkit-text-security: disc !important;
  }
  a,
  p,
  input,
  span,
  div {
    font-family: Nunito, sans-serif;
    font-size: 1.6rem;
    line-height: 1.8rem;
    box-sizing: border-box;
  }

  h1,
  textarea {
    font-family: Nunito, sans-serif;
  }

  ::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    color: ${palette.charcoal};
  }
  .react-datepicker-popper {
    z-index: 1000;
  }
  input::placeholder {
    color: ${palette.placeHolderTextColor} !important;
  }
  input:-ms-input-placeholder {
    color: ${palette.placeHolderTextColor} !important;
  }
  input::-ms-input-placeholder {
    color: ${palette.placeHolderTextColor} !important;
  }
  textarea::placeholder {
    color: ${palette.placeHolderTextColor} !important;
  }
  textarea:-ms-input-placeholder {
    color: ${palette.placeHolderTextColor} !important;
  }
  textarea::-ms-input-placeholder {
    color: ${palette.placeHolderTextColor} !important;
  }
`
export default GlobalStyle
