import React from 'react'

import Button from '@material-ui/core/Button';

import { CloudDownload } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import FileSaver from 'file-saver'

import { downloadQuestionFile } from 'service/index'

const useStyles = makeStyles(theme => ({
    fileDownloadContainer: {
        display: 'flex',
        margin: '2rem 0'
    },
    downloadBtn: {
        width: '50%',
        margin: 0,
        fontWeight: 600,

        '@media (max-width:600px)': {
            width: '60%',
        },
        '@media (max-width:520px)': {
            width: '70%',
        },
        '@media (max-width:360px)': {
            width: '80%',
        },
        '@media (max-width:300px)': {
            width: '100%',
        },
    }
}))

function FileDownload({ fileId, templateId, reportId }) {
    const classes = useStyles();

    const handleFileDownload = () => {
        downloadQuestionFile(fileId, templateId, reportId)
            .then(response => {
                FileSaver.saveAs(
                    response.data,
                    response.headers["content-disposition"].split("filename=")[1],
                    { type: `${response.headers["content-type"]};charset=utf-8` }
                )
            })
            .catch(function (error) {
                // TODO :: Discuss on how to handle the UX in case of error
                console.log(error);
            })
    }

    return (
        <>
            <div className={classes.fileDownloadContainer}>
                <Button
                    variant='contained'
                    color='primary'
                    className={classes.downloadBtn}
                    startIcon={<CloudDownload />}
                    onClick={handleFileDownload}
                >
                    Datei herunterladen
              </Button>
            </div>
        </>
    )
}

export default FileDownload
