import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTheme } from '@material-ui/core/styles';

import CountrySelect from '../CountrySelect';

const MuiAutoComplete = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(props.sideEffects);
  const [loading, setLoading] = React.useState(true);
  const [currentValue, setCurrentValue] = React.useState(null);

  React.useEffect(() => {
    setOptions(props.sideEffects)
    setLoading(false)
  }, [props.sideEffects]);

  React.useEffect(() => {
    if(props.defaultValue){
      setCurrentValue(props.defaultValue)
      props.onChange(props.defaultValue)
    }
  }, [props.defaultValue]);

  const handleOnChange = (event, value) => {
    setCurrentValue(value)
    if (typeof (value) === 'object' && value === null) {
      props.onClear()
    } else if (typeof (value) === 'object' && value !== null) {
      props.onSelect(value.label, value)
    } else {
      props.onSelect(value)
    }
    setOpen(false);
  }

  const handleOnInputChange = (event, value) => {
    if (event && event.target.id === props.inputId && value) {
      setLoading(true)
      setCurrentValue(value)
      props.onChange(value)
    }
  }

  const handleOnInputFocus = (event) => {
    props.onInputFocus(event)
  }

  const handleOnBlur = () => {
    handleOnChange(null, currentValue)
  }

  return (
    <Autocomplete
      id={props.inputId}
      freeSolo={true}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option, state) => {
        return option.label ? option.label : option;
      }}

      options={options}
      renderOption={option => {
        return (
          <div style={{
            cursor: 'pointer',
            fontSize: '1.4rem',
          }} >
            {option.label}
          </div>
        )
      }}
      onInputChange={handleOnInputChange}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      value={props.value}
      defaultValue={props.defaultValue}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={props.placeholder}
          fullWidth
          autoComplete='off'
          variant="outlined"
          onFocus={handleOnInputFocus}
          autoFocus={props.isAutoFocusEnabled}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            ...(props.countrySelectProps && props.countrySelectProps.enabled &&            
            {startAdornment: (
              <React.Fragment>
                <CountrySelect
                  defaultSelected='de'
                  {...props.countrySelectProps}
                />
              </React.Fragment>
            )
            })
          }}
        />
      )}
    />
  );
}

export default withTheme(MuiAutoComplete)