import React from 'react';
import { withTheme } from '@material-ui/core/styles'

import {
  ProTxt
} from './styles'

const AddMoreButton = ({item, disabled, theme}) => (
  <ProTxt lightColor={theme.palette.primary.light}
          primaryColor={theme.palette.primary.main}
          disabled={disabled}
  >
    {`${item}`}
  </ProTxt>
);

export default withTheme(AddMoreButton)
