import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import ErrorMessage from '../ErrorMessage'

import {
  DoctorInputContainer,
  DoctorInputItem
} from './styles'
import * as palette from '../../styles/palette'

const DoctorInput = ({
  opinionTxt,
  placeholderTxt,
  type,
  min,
  max,
  onBlur,
  onChangeTxt,
  errorText,
  hasError,
  inputId,
  hasActiveValidation,
  theme
}) => {

  return (
    <DoctorInputContainer>
      <DoctorInputItem
        onBlur={(e) => onBlur(e.target.value)}
        onChange={(e) => onChangeTxt(e.target.value)}
        placeholder={placeholderTxt}
        value={opinionTxt}
        type={type}
        min={min}
        max={max}
        maxLength="200"
        mainColor={theme.palette.text.primary}
        placeholderColor={theme.palette.text.secondary}
        focusColor={theme.palette.primary.main}
        borderColor={palette.primaryInputBorder}
        errorColor={theme.palette.error.main}
        className={hasActiveValidation && hasError ? 'error' : null}
        id={inputId}
      />
      {hasActiveValidation && hasError && <ErrorMessage theme={theme}>{errorText}</ErrorMessage>}
    </DoctorInputContainer>
  )
}

DoctorInput.propTypes = {
  activeItem: PropTypes.bool.isRequired,
  hasPriority: PropTypes.bool.isRequired,
  itemName: PropTypes.string,
  placeholderTxt: PropTypes.string.isRequired,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onActivePriority: PropTypes.func.isRequired,
  onBlurInput: PropTypes.func.isRequired,
  onChangeTxt: PropTypes.func.isRequired
}

export default withTheme(DoctorInput)
