export const MED_APP_WIDGET_TOKEN = 'med_app_widget_token'
export const CLIENT_LOGOUT = 'RESET_STATE'
export const PERSIST_KEY = 'state'

export const questionDrugDosage = 'question-drug-dosage'
export const questionDrugIndication = 'question-drug-indication'
export const questionDrugBatchNumber = 'question-drug-batch-number'
export const questionDrugEffectStatus = 'question-effect-status'
export const questionContactDetails = 'question-contact-details'
export const questionCustom = 'custom'
export const questionTemplate = 'template'
export const questionDrugAction = 'question-drug-action'

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
  DIVERS: 'divers'
}

export const defaultNonOption = 'Bitte wählen'

export const toolList = [
  defaultNonOption,
  'Stück',
  'ml',
  'mg',
  'Tropfe(n)',
  'Spritze(n)',
  'Zäpfche(n)',
  'Tablette(n)',
  'Kapsel(n)'
]

export const timeUnitList = [
  defaultNonOption,
  'täglich',
  'wöchentlich',
  'monatlich',
  'bei Bedarf',
  'ein einziges Mal',
  'stündlich'
]

export const SideEffectStatus = {
  unknown: defaultNonOption,
  recovering: 'verbessert',
  recovered: 'wiederhergestellt',
  ongoing: 'andauernd',
  worsened: 'verschlechtert',
  recovered_with_sequelae: 'wiederhergestellt mit bleibenden Schäden',
  fatal: 'tödlich'
}

export let SideEffectStatusElements = Object.keys(SideEffectStatus)
  .map(key => ({ value: key, label: SideEffectStatus[key].charAt(0).toUpperCase() + SideEffectStatus[key].slice(1) }))

export const DrugAction = {
  unknown: {
    longName: 'Bitte wählen',
    shortName: 'Unbekannt'
  },
  not_changed: {
    longName: 'Keine, nehme das Medikament unverändert ein',
    shortName: 'Unverändert'
  },
  reduced: {
    longName: 'Habe die Dosis reduziert',
    shortName: 'Reduziert'
  },
  increased: {
    longName: 'Habe die Dosis erhöht',
    shortName: 'Erhöht'
  },
  withdrawn: {
    longName: 'Habe das Medikament abgesetzt',
    shortName: 'Abgesetzt'
  }
}

export let DrugActionElement = Object.keys(DrugAction)
  .map(key => ({ value: key, label: DrugAction[key].longName.charAt(0).toUpperCase() + DrugAction[key].longName.slice(1) }))

export const DrugDoseInterval = {
  unknown: defaultNonOption,

  daily: 'täglich',
  weekly: 'wöchentlich',
  monthly: 'monatlich',
  when_needed: 'bei Bedarf',
  once: 'ein einziges Mal',
  hourly: 'stündlich'
}

export let DrugDoseIntervalElements = Object.keys(DrugDoseInterval)
  .map(key => ({ value: key, label: DrugDoseInterval[key] }))

export const DrugDoseUnit = {
  unknown: defaultNonOption,
  piece: 'Stück',
  tablet: 'Tablette(n)',
  ml: 'ml',
  mg: 'mg',
  injection: 'Injektion',
  suppository: 'Zäpfchen',
  capsule: 'Kapsel(n)',
  drop: 'Tropfen',
  puff: "Hübe/Sprühstöße",
  ug: "Mikrogramm",
  units: "Einheit(en)"
}

export let DrugDoseUnitElements = Object.keys(DrugDoseUnit)
  .map(key => ({ value: key, label: DrugDoseUnit[key] }))

export const Interval = [
  'hourly',
  'daily',
  'weekly',
  'monthly',
  'once',
  'when_needed'
]

export const erkrankungenList = [
  defaultNonOption,
  'Allergie',
  'Atemwege',
  'Bluthochdruck',
  'Diabetes',
  'Fettstoffwechselstörung',
  'Gicht',
  'Haut',
  'Herzkreislauf',
  'Immunsystem',
  'Krebs',
  'Leber/Galle',
  'Magen-Darm-Trakt',
  'Nervensystem/Psyche',
  'Niere/Harnwege',
  'Rheuma',
  'Schilddrüse'
]

export const gewohnheitenList = [
  defaultNonOption,
  'Fettreiche Ernährung',
  'Hoher Alkoholkonsum',
  'Hohes Stresslevel',
  'Starker Raucher',
  'Wenig Bewegung'
]

export const gewohnheitenMap = {
  smoking: 'Starker Raucher',
  stress: 'Hohes Stresslevel',
  diet: 'Fettreiche Ernährung',
  drinking: 'Hoher Alkoholkonsum',
  sport: 'Wenig Bewegung'
}

export const sicknessMap = {
  bloodPressure: 'Bluthochdruck',
  diabetes: 'Diabetes',
  lipopathy: 'Fettstoffwechselstörung',
  cardiac: 'Herzkreislauf',
  gastrointestinal: 'Magen-Darm-Trakt',
  respiratory: 'Atemwege',
  kidney: 'Niere/Harnwege',
  cancer: 'Krebs',
  skin: 'Haut',
  gout: 'Gicht',
  immune: 'Immunsystem',
  rheumatism: 'Rheuma',
  liver: 'Leber/Galle',
  nerve: 'Nervensystem/Psyche',
  thyroid: 'Schilddrüse',
  allergy: 'Allergie'
}

export const errorMessages = {
  'number': 'Number',
  'missingName':'Bitte geben Sie Ihren Vor- und Nachnamen ein.',
  'validFullBirthDay': 'Bitte geben Sie Ihr Geburtsdatum ein, z. B. 15.02.1987 ',
  'validYear': 'Bitte Ihr Geburtsjahr angeben, z.B. 1988',
  'validMinMaxYear': 'Bitte überprüfen Sie Ihr Geburtsjahr',
  'validEmail': 'Bitte tragen Sie Ihre gültige E-Mail-Adresse ein.'
}

export const validationRegex = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  stringWithoutCommaSeparators: /^[^,]*(\d,\d)?[^,]*$/
}

export const erkrankungenAddMore = 'Sind Ihnen Vorerkrankungen bekannt? (optional)'
export const gewohnheitAddMore = 'Treffen folgende Risikofaktoren auf Sie zu? (optional)'
export const gewohnheitQuestion = 'Hier können Sie diese beschreiben: (optional)'
export const erkrankungenQuestion = 'Hier können Sie diese beschreiben: (optional)'

export const VorerkrankungenPlaceholder = 'z. B. Ich habe Diabetes Typ 2 seit 12 Jahren ...'
export const RisikofaktorenPlaceholder = 'z. B. Ich rauche 1 Packung Zigaretten am Tag ...'

export const birthyearList = [
  'Bitte wählen'
]
for (var birthyear = 1901; birthyear <= new Date().getFullYear(); birthyear++) {
  birthyearList.push(birthyear + '');
}

export const pregnantList = [
  defaultNonOption,
  'Nicht schwanger'
];
for (var pregnant = 0; pregnant <= 41; pregnant++) {
  pregnantList.push(pregnant + '. Woche');
}

export const tallList = [
  defaultNonOption
]
for (var tall = 0; tall <= 250; tall++) {
  tallList.push(tall + ' cm');
}

export const weightList = [
  defaultNonOption
]
for (var weight = 0; weight <= 300; weight++) {
  weightList.push(weight + ' kg');
}

export const stepInfos = [
  {
    index: 1,
    title: 'Im ersten Schritt tragen Sie bitte Ihre Nebenwirkungen ein.'
  },
  {
    index: 2,
    title: 'Im zweiten Schritt tragen Sie bitte Ihre eingenommenen Medikamente ein.'
  },
  {
    index: 3,
    title: 'Im dritten Schritt tragen Sie bitte Ihre Angaben zu Ihrem Körper ein ein.'
  },
  {
    index: 4,
    title: 'Wenn Sie an bestimmten Erkrankungen leiden, geben Sie diese bitte an.'
  },
  {
    index: 5,
    title: 'Wenn Sie an bestimmten Gewohnheiten leiden, geben Sie diese bitte an.'
  },
  {
    index: 6,
    title: 'Im letzten Schritt können Sie auf Wunsch Ihren behandelnden Arzt oder Apotheker in die Meldung einbinden.'
  },
  {
    index: 4.1,
    title: 'Im dritten Schritt tragen Sie bitte Ihre Angaben zu Ihrem Körper ein ein.'
  },
  {
    index: 5,
    title: ''
  }
]

export const reportData = {
  checkInformHealthProfessional: false,
  legal: {
    checkReleaseMedicalConfidentiality: false,
    checkTermsAndConditions: true
  },
  contacts: {
    responsibleAuthority: {
      country: 'de'
    }
  }
}

export const timeList = [
  defaultNonOption,
  'Tag(en)',
  'Woche(n)',
  'Monat(en)',
  'Jahr(en)'
]

export const localeTranslations = {
  // the dafault falback language, in case nothing is passed
  // via get parameter and the language code does not exists
  // in the languageFormat
  mainLanguageFallback: 'de',
  // language formats
  languageFormat: {
    'de': [
      'de',
      'de-AT',
      'de-CH',
      'de-DE',
      'de-LI',
      'de-LU'

    ]
  }
}

export const LEGAL_STEP = 0
export const SEVERITY_STEP = 6
