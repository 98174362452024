import styled from 'styled-components'

export const InputContainer = styled.div`
  position: relative;
`

export const Step5List = styled.ul`
      padding-left: 0px;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -1.5rem;
  svg {
    fill: ${props => props.primaryColor};
  }
  p {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -1.5rem;
  svg {
    fill: ${props => props.primaryColor};
  }
  p {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`

export const QuestionContainer = styled.div`
  margin: 4rem 0;
  &:first-child {
    margin-top: 0rem;
  }
`

export const QuestionTitle = styled.p`
  display:inline;
  font-weight: 600;
`
