import React from 'react'
import PropTypes from 'prop-types'

import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    colorPrimary: {
        borderRadius: 2
    },
    barColorPrimary: {
        borderRadius: 2
    }
}))

export default function LinearProgressBar(props) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Box display='flex' alignItems='center' flexDirection='column'>
                <Box width='100%' textAlign='right'>
                    <Typography variant='body2' color='textSecondary'>{`${Math.round(
                        props.value
                    )}%`}</Typography>
                </Box>
                <Box width='100%'>
                    <LinearProgress variant='determinate' value={props.value}
                        classes={{
                            colorPrimary: classes.colorPrimary,
                            barColorPrimary: classes.barColorPrimary
                        }}
                    />
                </Box>
            </Box>
        </div>
    )
}

LinearProgressBar.propTypes = {
    value: PropTypes.number.isRequired
}
